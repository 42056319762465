import { MessagerieService } from 'services';
import {v4} from 'uuid'
import { MessageGroupe } from './MessageGroupe.class';
import { SearchMessageParams } from 'features/messagerie/src/store/types';
import { Personne } from './Personne.class';
import { B64File } from 'classes/B64File.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

/**
 * Groupe
 * Groupe dans la messagerie
 */
export class Groupe
{

    public service = MessagerieService.getInstance();

    public uid: string = v4();

    /**
     * L'identifiant du groupe
     */
    public idGroupe: number;
  
    /**
     * Nom d'affichage du groupe
     */
    public nom: string;

    /**
     * Image de profil du groupe
     */
    public image: B64File;

    /**
     * Membres du groupe
     */
    public members: Personne[];

    /**
     * Dernier message échangé dans le groupe, pour l'affichage
     */
    public lastMessages: MessageGroupe[];

    /**
     * Nombre de messages non lus
     */
    public unreadMessages: number;

    /**
     * Constructeur du Groupe
     */
    constructor(row?: DeepPartial<DeepRawify<Groupe>>) {
        if (row) {
            const { idGroupe,
                nom,
                image,
                lastMessages,
                unreadMessages
            } = row;
            this.idGroupe = idGroupe;
            this.nom = nom;
            this.unreadMessages = unreadMessages;
            this.image = image && B64File.fromDb(image);
            this.lastMessages = lastMessages && lastMessages.map(lastMessage => new MessageGroupe(lastMessage));
            this.sortMessages();
        }
    }

    /**
     * Méthode de renvoi de l'objet en back sous la forme d'un tableau
     * @return array
     */
    public toRaw() {
        return {
            idGroupe: this.idGroupe,
            image: this.image,
            nom: this.nom
        };
    }

    public save(): Promise<number> {
        return this.service.createGroup(this);
    }
    
    /**
     * Méthode de suppression du groupe en BDD
     */
    public delete(): Promise<Boolean> {
        return this.service.deleteGroup(this.idGroupe);
    }

    public getMembers(): Promise<Personne[]> {
        return this.service.loadListePersonnes(this.idGroupe);
    }

    /**
     * Ajout d'utilisateurs dans des groupes
     * @param personnes liste d'identifiants de personnes à ajouter
     * @returns succès de l'évenement
     */
    public addMembers(personnes: string[]): Promise<Boolean> {
        return this.service.addMembers(personnes, this.idGroupe);
    }

    /**
     * Retrait d'utilisateurs dans des groupes
     * @param personnes liste d'identifiants de personnes à ajouter
     * @returns succès de l'évenement
     */
    public kickMember(leMembre: string): Promise<Boolean> {
        return this.service.kickMember(leMembre, this.idGroupe);
    }

    /**
     * Méthode pour charger plus de messages dans la conversation
     */
    public async loadMore() {
        const params = {
            limit: this.lastMessages.length+20,
            offset: this.lastMessages.length,
            recepteur: this.idGroupe,
            onlyGroups: true
        }
        const messages = await this.service.searchMessages(params) as MessageGroupe[]

        for (const message of messages) {
            this.lastMessages.push(message)
        }

        this.sortMessages();
    }

    public sortMessages() {
        this.lastMessages = this.lastMessages.sort((a, b) => 
            b.dateEnvoi - a.dateEnvoi
        )
    }
}
