import { DeepPartial } from "redux";
import { ContenusService } from "services";

/**
 * PageContenu
 * Page statique affichant un bloc Contenu
 * Fait le lien entre une URL (a-propos) et un idContenu
 */
export class PageContenu
{
    /**
     * L'identifiant du contenu
     */
    public idContenu: number;
  
    /**
     * URL de la page (a-propos)
     */
    public url: string;

    /**
     * Identifiant de la page en BDD
     */
    public idPage: number;

    /**
     * Actif ou pas
     */
    public actif: boolean;

    /**
     * Nom de la page
     */
    public libelle: string;

    /**
     * La page est-elle publique : accessible aux non connectés
     */
    public public: boolean;

    /**
     * Icone de la page (choix dans une liste)
     */
    public icon: number;

    private contenuService = ContenusService.getInstance();

    /**
     * Constructeur de la page
     */
    public constructor(row?: DeepPartial<PageContenu>)
    {
        if (row) {
            this.idContenu = row['idContenu'] ?? null;
            this.idPage = row['idPage'] ?? null;
            this.url = row['url'] ?? null;
            this.actif = row['actif'] ?? null;
            this.libelle = row['libelle'] ?? null;
            this.public = row['public'] ?? null;
            this.icon = row['icon'] ?? null;
        }
    }

    /**
     * Sauvegarde le contenu en BDD via le service
     */
    public save()
    {
        return this.contenuService.savePage(this);
    }
    
    /**
     * Suppression du contenu via le service
     */
    public delete()
    {
        return this.contenuService.deletePage(this.idPage);
    }

    public toRaw(): DeepPartial<PageContenu> {
        return {
            idContenu: this.idContenu,
            idPage: this.idPage,
            url: this.url,
            libelle: this.libelle,
            actif: this.actif,
            icon: this.icon,
            public: this.public
        }
    }
}