import { createAction, createAsyncAction } from 'typesafe-actions';
import { Module } from './navigationTypes';


export const navigationPush = createAction('NAVIGATION_PUSH')<string>();

export const navigationReplace = createAction('NAVIGATION_REPLACE')<string>();

export const navigationSetCurrentModule = createAction('SET_CURRENT_MODULE')<Module>();

// Ajouter une URL à l'historique
export const pushHistory = createAction('PUSH_HISTORY')<string>();

// Enlève la dernière url de l'historique
export const popHistory = createAction('POP_HISTORY')<void>();

// Vide l'historique de navigation
export const resetHistory = createAction('RESET_HISTORY')<void>();
