import { CarnetVoyage } from "classes/carnet_voyage/CarnetVoyage.class";
import { Item } from "classes/carnet_voyage/Item.class";
import { CarnetDay } from "classes/carnet_voyage/programme/CarnetDay.class";
import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import {
    loadCarnet,
    loadItems,
    loadItem,
    loadSchedule,
    setCarnet,
    loadInformations,
    saveSchedule,
    loadContacts,
    saveContacts,
    saveEdito,
    loadEdito,
    saveInformations,
    saveHotel,
    loadHotel,
    addFileTelechargements,
    setProgressSaveFiles,
    loadTelechargements,
    resetCarnet,
    loadParticipants,
    setParticipant,
    removeFileTelechargements
} from "./actions";

import { CarnetInformations } from "classes/carnet_voyage/informations/CarnetInformations.class";
import { CarnetInformationsItem } from "classes/carnet_voyage/informations/CarnetInformationsItem.class";
import { CarnetCategory } from "classes/carnet_voyage/programme/CarnetCategory.class";
import { Acteur } from "classes/Acteur.class";
import { Edito } from "classes/carnet_voyage/edito/Edito.class";
import { Hotel } from "classes/carnet_voyage/hotel/Hotel.class";
import { B64File } from "classes/B64File.class";
import { CarnetParticipant } from "classes/carnet_voyage/participants/CarnetParticipant.class";

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadCarnet.request,
    loadItems.request,
    loadSchedule.request,
    loadInformations.request,
    saveInformations.request,
    saveContacts.request,
    saveEdito.request,
    loadEdito.request,
    loadContacts.request,
    saveHotel.request,
    loadHotel.request,
    loadTelechargements.request,
    addFileTelechargements.request,
    resetCarnet.request], (_state, action) => true)
    .handleAction([resetCarnet.success,
    addFileTelechargements.success,
    loadHotel.success,
    saveHotel.success,
    loadContacts.success,
    loadEdito.success,
    saveEdito.success,
    saveContacts.success,
    saveInformations.success,
    loadInformations.success,
    loadSchedule.success,
    loadCarnet.success,
    loadItems.success,
    loadTelechargements.success,
    resetCarnet.failure,
    addFileTelechargements.failure,
    loadHotel.failure,
    saveHotel.failure,
    loadContacts.failure,
    loadEdito.failure,
    saveEdito.failure,
    saveContacts.failure,
    saveInformations.failure,
    loadInformations.failure,
    loadSchedule.failure,
    loadCarnet.failure,
    loadItems.failure,
    loadTelechargements.failure], (_state, action) => false);

const carnet = createReducer<CarnetVoyage, RootAction>(null)
    .handleAction(setCarnet, (_state, action) => action.payload)
    .handleAction(loadCarnet.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => null);

const participant = createReducer<CarnetParticipant, RootAction>(null)
    .handleAction(setParticipant, (_state, action) => action.payload)

const items = createReducer<Item[], RootAction>([])
    .handleAction(loadItems.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => []);

const item = createReducer<Item, RootAction>(null)
    .handleAction(loadItem.success, (_state, action) => action.payload);

const activeItems = createReducer<Item[], RootAction>([])
    .handleAction(loadItems.success, (_state, action) =>
        action.payload.filter((item: Item) => item.active)
    )
    .handleAction(resetCarnet.success, (_state, action) =>
        []);

const onlineItems = createReducer<Item[], RootAction>([])
    .handleAction(loadItems.success, (_state, action) =>
        action.payload.filter((item: Item) => item.online)
    )
    .handleAction(resetCarnet.success, (_state, action) =>
        []
    );

const schedule = createReducer<CarnetDay[], RootAction>([])
    .handleAction(loadSchedule.success, (_state, action) => action.payload.schedule)
    .handleAction(resetCarnet.success, (_state, action) => []);

const scheduleCategories = createReducer<CarnetCategory[], RootAction>([]).handleAction(
    loadSchedule.success,
    (_state, action) => action.payload.scheduleCategories
)
    .handleAction(
        resetCarnet.success,
        (_state, action) => []
    );

const participants = createReducer<CarnetParticipant[], RootAction>([])
    .handleAction(loadParticipants.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => []);

const contacts = createReducer<Acteur[], RootAction>([])
    .handleAction(loadContacts.success, (_state, action) => action.payload)
    .handleAction(saveContacts.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => []);

const edito = createReducer<Edito, RootAction>(null)
    .handleAction(loadEdito.success, (_state, action) => action.payload)
    .handleAction(saveEdito.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => null);

const hotel = createReducer<Hotel, RootAction>(null)
    .handleAction(loadHotel.success, (_state, action) => action.payload)
    .handleAction(saveHotel.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => null);

const informations = createReducer<CarnetInformations[], RootAction>([])
    .handleAction(loadInformations.success, (_state, action) => action.payload.carnetInformations)
    .handleAction(saveInformations.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => []);

const informationsItems = createReducer<CarnetInformationsItem[], RootAction>([]).handleAction(
    loadInformations.success,
    (_state, action) => action.payload.carnetInformationsItems
)
    .handleAction(resetCarnet.success, (_state, action) => []);

const files = createReducer<B64File[], RootAction>([])
    .handleAction(setProgressSaveFiles, (_state, action) => _state.map(obj => action.payload.localIdFile == obj.localIdFile ? action.payload : obj))
    .handleAction(addFileTelechargements.request, (_state, action) => [..._state, action.payload])
    .handleAction(addFileTelechargements.success, (_state, action) => _state.map(obj => action.payload.localIdFile == obj.localIdFile ? action.payload : obj))
    .handleAction(removeFileTelechargements.success, (_state, action) => _state.filter(o => o.idFile != action.payload.idFile))
    .handleAction(loadTelechargements.success, (_state, action) => action.payload)
    .handleAction(resetCarnet.success, (_state, action) => []);

const error = createReducer<string, RootAction>('')
    .handleAction([resetCarnet.failure,
    addFileTelechargements.failure,
    loadHotel.failure,
    saveHotel.failure,
    loadContacts.failure,
    loadEdito.failure,
    saveEdito.failure,
    saveContacts.failure,
    saveInformations.failure,
    loadInformations.failure,
    loadSchedule.failure,
    loadCarnet.failure,
    loadItems.failure,
    loadTelechargements.failure], (_state, action) => action.payload);

const CarnetVoyageReducer = combineReducers({
    carnet,
    isLoading,
    item,
    items,
    activeItems,
    onlineItems,
    schedule,
    scheduleCategories,
    contacts,
    edito,
    informations,
    informationsItems,
    hotel,
    files,
    participants,
    participant,
    error
});

export default CarnetVoyageReducer;
