import { B64File } from 'classes/B64File.class';
import { ChallengeService } from 'services';
import { Transform, Type } from "class-transformer";
import { Challenge } from 'classes/challenges/Challenge.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

// SQL columns of the corresponding table (ex : "idActeur,emailActeur,imageActeur") (press tab when you're done) : idVideo,leChallenge,videoURL,description,actif
export class Video {
    public idVideo: number = null
    public leChallenge: number = null
    public videoURL: string = null
    public description: string = null
    public actif: boolean = null
    public nbrLikes: number = null;

    public isLiked: boolean = null;

    private ChallengeService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Video>>) {
        if (row) {
            const {
                idVideo,
                leChallenge,
                videoURL,
                description,
                actif,
                nbrLikes,
                isLiked
            } = row;

            this.idVideo = idVideo;
            this.leChallenge = leChallenge;
            this.videoURL = videoURL;
            this.description = description;
            this.actif = actif;

            this.nbrLikes = nbrLikes;

            this.isLiked = isLiked;

        }
    }

    public toDatabaseObject(): object {
        return {
            idVideo: this.idVideo,
            leChallenge: this.leChallenge,
            videoURL: this.videoURL,
            description: this.description,
            actif: this.actif
        }
    }

    public async saveVideo(challenge: Challenge): Promise<Video> {
        return this.ChallengeService.saveVideo(challenge.idChallenge, this);
    }

    public static rowsToArray(rows?: DeepRawify<Video>[]): Video[] {
        const array: Video[] = [];

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];

            const b64File: Video = new Video(row);

            array.push(b64File);
        }

        return array;
    }
}