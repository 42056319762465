import { createAsyncAction, createAction } from 'typesafe-actions';
import { Post } from "classes/animations/concoursPhoto/Post.class"
import { PostLike } from "classes/animations/concoursPhoto/PostLike.class"

export const loadPcPostsAsync = createAsyncAction(
    'LOAD_PC_POSTS_REQUEST',
    'LOAD_PC_POSTS_SUCCESS',
    'LOAD_PC_POSTS_FAILURE'
)<Array<any>, Post[], string>();

export const publishPcPost = createAction('PUBLISH_PC_POST')<Post>();

export const clearPcPosts = createAction('CLEAR_PC_POSTS')<void>();