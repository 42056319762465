import { TodoList } from "classes/todolist/TodoList.class";
import { createAsyncAction } from "typesafe-actions";

/**
 * Action de sauvegarde d'une tâche
 */
export const saveTaskAsync = createAsyncAction(
    'SAVE_TASK_REQUEST', 
    'SAVE_TASK_SUCCESS', 
    'SAVE_TASK_FAILURE'
)<TodoList, TodoList, string>();

/**
 * Action de suppression d'une tâche
 */
export const deleteTaskAsync = createAsyncAction(
    'DELETE_TASK_REQUEST', 
    'DELETE_TASK_SUCCESS', 
    'DELETE_TASK_FAILURE'
)<number, number, string>();

/**
 * Action de chargement des tâches
 */
export const loadTasksAsync = createAsyncAction(
    'LOAD_TASKS_REQUEST', 
    'LOAD_TASKS_SUCCESS', 
    'LOAD_TASKS_FAILURE'
)<void, TodoList[], string>();

/**
 * Action de chargement des tâches
 */
export const sortTasksAsync = createAsyncAction(
    'SORT_TASKS_REQUEST', 
    'SORT_TASKS_SUCCESS', 
    'SORT_TASKS_FAILURE'
)<number[], TodoList[], string>();
