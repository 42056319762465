import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { Metier } from "classes/referentiel/Metier.class";
import { loadMonProfilInfosAsync } from "./actions";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import { Post } from "classes/murs/Post.class";
import { QuizBadge } from "classes/animations/QuizBadge.class";


const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadMonProfilInfosAsync.request,
    ], (_state, action) => true)
    .handleAction([loadMonProfilInfosAsync.success,
    loadMonProfilInfosAsync.failure,
    ], (_state, action) => false);


const error = createReducer<string, RootAction>('')
    .handleAction([loadMonProfilInfosAsync.failure], (_state, action) => action.payload)

const mesPosts = createReducer<Post[], RootAction>([])
    .handleAction(loadMonProfilInfosAsync.success, (_state, action) => action.payload.mesPosts)

const favoris = createReducer<Post[], RootAction>([])
    .handleAction(loadMonProfilInfosAsync.success, (_state, action) => action.payload.favoris)

const badges = createReducer<QuizBadge[], RootAction>([])
    .handleAction(loadMonProfilInfosAsync.success, (_state, action) => action.payload.badges)

const NiveauxReducer = combineReducers({
    isLoading,
    error,
    mesPosts,
    favoris,
    badges
});

export default NiveauxReducer;
