import { Collapse, ThemeProvider, Button, Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyle from './Error.style';
import { Theme } from 'Theme';
import LoopIcon from '@material-ui/icons/Loop';
import AlertIcon from '../../../assets/visuels/alertes_vide.svg'
import { ThemeService } from 'services';

export interface ErrorProps {
	title?: string;
	error: Error;
}

export function Error({ title = 'Une erreur est survenue', error }: ErrorProps) {
	const classes = useStyle();

	const [showStack, setShowStack] = useState(false);

    useEffect(() => {
        // Reporting
        ThemeService.getInstance().saveError(error)
    }, []);

	return (
		<ThemeProvider theme={Theme}>
			<Box className={classes.mainContainer}>
                <Box maxWidth={800} textAlign={"center"} bgcolor={'white'} padding={2} borderRadius={7}>
                    <img src={AlertIcon} alt="Illustration d'alerte" className={classes.image} />
                    <Typography variant='h6'>{title}</Typography>
                    <Box className={classes.errorMessage}>{error.message}</Box>

                    <Button
                        onClick={() => setShowStack((prevState) => !prevState)}
                        color="primary"
                        variant="text"
					>
                        Voir {showStack ? 'moins' : 'plus'}
                    </Button>

                    <Collapse in={showStack} className={classes.errorStack}>
						<p>{error.stack}</p>
					</Collapse>

                </Box>

				<Button onClick={() => window.location.reload()} variant='contained' color="primary" startIcon={<LoopIcon />}>Recharger l'application</Button>
			</Box>
		</ThemeProvider>
	);
}
