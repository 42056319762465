import qs from 'qs';
import Axios from 'axios';
import { Section } from 'classes/faq/Section.class';
import { DeepRawify } from 'types';

const rub = IS_ADMIN ? 560 : 56;

export class FAQService {
    private static instance: FAQService;

    public static getInstance(): FAQService {
      if (!FAQService.instance) {
        FAQService.instance = new FAQService();
      }

      return FAQService.instance;
    }

    public async loadFAQAsync(): Promise<Section[]> {
      const params = {
        rub,
        p: 1,
      };

      return Axios.get<{ content: DeepRawify<Section>[] }>(`index.php?${qs.stringify(params)}`)
        .then(({ data: { content: sections } }) => sections.map((section) => new Section(section)));
    }
}
