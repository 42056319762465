import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles((theme: typeof Theme) => createStyles({
    headerContainer: {
        padding: '0 14px',
        width: '100%',
        height: 56,
    },
    listFilter: {
        marginRight: theme.spacing(1),
        padding: "2px 10px",
        fontSize: 15,
        whiteSpace: 'nowrap',
        cursor : 'pointer',
        lineHeight: "25px"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: 'calc(56 + env(safe-area-inset-top))',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingTop: 'env(safe-area-inset-top)'
    },
    menuButton: {
        marginRight: 36,
    },
}));