import { B64File } from 'classes/B64File.class';
import { Notifiable } from 'classes/notifications/Notifiable.class';
import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'

export enum TypeCategorieCaracteristique {
    PARAMETREE = 1,
    LIBRE
}

export class Categorie extends Notifiable {
    public getUrl(): string {
        return "/level-up/fiches/categories/" + this.idCategorie + "/";
    }
    public getID(): number {
        return this.idCategorie;
    }
    public notifType = 12;

    public uid: string = v4();

    /**
     * L'identifiant de la catégorie
     */
    public idCategorie: number;

    /**
     * Le libellé de la catégorie
     */
    public libelle: string;

    /**
     * L'icône de la catégorie
     */
    public icone: B64File;

    /**
     * Statue d'activation de la catégorie
     */
    public enLigne: boolean;

    /**
     * L'identifiant de l'API liée à la catégorie
     */
    public idApi: number;

    /**
     * Le chemin de l'API liée à la catégorie
     */
    public cheminApi: string;

    /**
     * Nombre de quiz associés à la catégorie (actifs & bon référentiel uniquement)
     */
    public nbQuizTotal: number;

    /**
     * Nombre de quiz effectués associés à la catégorie
     */
    public nbQuizFaits: number;

    /**
     * Type de caractéristiques lié à la catégorie
     */
    public typeCategorieCaracteristique: TypeCategorieCaracteristique;

    private levelUpService = LevelUpService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Categorie>>) {
        super();
        if (row) {
            const { idCategorie,
                libelle,
                icone,
                enLigne,
                idApi,
                cheminApi,
                typeCategorieCaracteristique,
                nbQuizTotal,
                nbQuizFaits
            } = row;

            this.idCategorie = idCategorie;
            this.libelle = libelle;
            this.icone = (typeof icone === 'string') ? B64File.fromDb(icone) : icone as B64File;
            this.enLigne = !!enLigne;
            this.idApi = idApi;
            this.cheminApi = cheminApi;
            this.typeCategorieCaracteristique = typeCategorieCaracteristique;
            this.nbQuizTotal = nbQuizTotal;
            this.nbQuizFaits = nbQuizFaits;
        }
    }


    public toRaw() {
        return {
            idCategorie: this.idCategorie,
            libelle: this.libelle,
            icone: this.icone,
            enLigne: this.enLigne,
            idApi: this.idApi,
            cheminApi: this.cheminApi,
            typeCategorieCaracteristique: this.typeCategorieCaracteristique,
        };
    }

    public save(): Promise<boolean> {
        return this.levelUpService.saveCategorie(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.levelUpService.deleteCategorie(this.idCategorie);
    }
}
