import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import useStyle from './AnimationsList.style';
import { useSelector } from 'store/rootStore';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { replace } from "connected-react-router";
import { AnimationStatus } from 'classes/animations/AnimationStatus.enum';
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { Quiz } from 'classes/animations/Quiz.class';
import CircularProgress from '@material-ui/core/CircularProgress'
import EmptyListComponent from 'components/EmptyList.component';
import useInfiniteScroll from 'components/infiniteScroll/useInfiniteScroll';
import { Pacman } from 'components/pacman';
import { colors } from 'Theme';
import moment from 'moment';
import { loadAnimationsAsync as loadAnimationsLevelUpAsync} from 'features/level_up/src/animations/store/actions';
import { loadAnimationsAsync } from '../store/actions';
import NoAnimationSVG from '../../assets/animations/aucune_animation.svg';
import quizSVG from '../../assets/animations/quiz.svg';
import photoContestSVG from '../../assets/animations/photoCompetition.svg';
import { setHeaderStore } from 'features/header/src/store/actions';
import { Animation } from 'classes/animations/Animation.class';
import { Animation as AnimationLevelUp } from 'classes/level_up/animations/Animation.class';
import { Box } from '@material-ui/core';
import { navigationPush } from 'store/navigation/navigationActions';

interface AnimationsListProps {
}

const AnimationsListComponent : React.FC<AnimationsListProps> = props => {

    const classes = useStyle();

    const dispatch = useDispatch();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    const [allAnimationsList, setAllAnimationsList] = useState<(Animation | AnimationLevelUp) []>([]);
    const [totalAllAnim, setTotalAllAnim] = useState<number>(null);
    const [nbrAnimToDisplay, setNbrAnimToDisplay] = useState<number>(10);
    const {isScrolling, setIsScrolling} = useInfiniteScroll(fetchMoreAnimations);

    const [animationsLevelUp, totalLevelUp, isLoadingLevelUp, animations, total, isLoading] = useSelector((state) => [
        state.levelUp.animations.animations,
        state.levelUp.animations.total,
        state.levelUp.animations.isLoadingAnimation,
        state.animations.list,
        state.animations.total,
        state.animations.isLoading,
    ], isEqual);

    useEffect(() => {
        // Affiche Animation dans le header et réinitialise le reste
        dispatch(setHeaderStore({
            pageTitle: 'Animations',
            leftComponents: [
            ],
            rightComponents: []
        }));   
        
        // Pour charger les animations non Level Up
        dispatch(loadAnimationsAsync.request(nbrAnimToDisplay))

        // Pour charger les animations LevelUp
        dispatch(loadAnimationsLevelUpAsync.request(nbrAnimToDisplay))
    }, []);

    useEffect(() => {
        if (animationsLevelUp.length != nbrAnimToDisplay) {
            dispatch(loadAnimationsLevelUpAsync.request(nbrAnimToDisplay))
            dispatch(loadAnimationsAsync.request(nbrAnimToDisplay))
        }
    }, [nbrAnimToDisplay])

    useEffect(() => {
        if ((!isLoadingLevelUp && animationsLevelUp) || (!isLoading && animations)) {

            // Si plusieurs type d'animations sont présentent on concatène les 2 listes et on les tries par date de début
            let tmpList : (Animation | AnimationLevelUp) [] = [];

            if(animations && animations.length > 0){
                tmpList = tmpList.concat(animations);
            }

            if(animationsLevelUp && animationsLevelUp.length > 0){
                tmpList = tmpList.concat(animationsLevelUp);
            }
            if(animationsLevelUp && animations) {
                tmpList = sortAllAnimations(tmpList);
            }

            setAllAnimationsList(tmpList);

            if(!isLoadingLevelUp && !isLoading){
                setTotalAllAnim(totalLevelUp + total);
            }
        }
    }, [animationsLevelUp, animations])

    function getAnimationCover(animationType: number) {
        switch (animationType) {
            case AnimationTypes.Quiz:
                return quizSVG;
            case AnimationTypes.PhotoContest:
                return photoContestSVG;
        }
    }

    /**
     * Méthode qui permet de trier tous types d'animations par date de début
     * @param listAnimations la liste des animations à trier
     * @returns la liste des animations triées par date de début
     */
    function sortAllAnimations(listAnimations : (Animation | AnimationLevelUp) []) : (Animation | AnimationLevelUp) [] {

        listAnimations.sort(function(anim1, anim2){

            let dateDebut1 = 0;
            let dateDebut2 = 0;

            if(anim1 instanceof Animation){
                dateDebut1 =  anim1.beginDate.unix();
            }
            else if(anim1 instanceof AnimationLevelUp){
                dateDebut1 =  anim1.dateDebut;
            }

            if(anim2 instanceof Animation){
                dateDebut2 =  anim2.beginDate.unix();
            }
            else if(anim2 instanceof AnimationLevelUp){
                dateDebut2 =  anim2.dateDebut;
            }
            return  Number(dateDebut2) - Number(dateDebut1);
        });

        return listAnimations;

    }

    //Affichage du label du statut de l'animation selon la date
    function getLabelColor(status: AnimationStatus): string {
        switch (status) {
            case AnimationStatus.INCOMING:
                return colors.yellow.dark;

            case AnimationStatus.ONGOING:
                return colors.green.main;

            case AnimationStatus.FINISHED:
                return colors.pink.main;
        }
    }

    function getCoverBackgroundColor(type: AnimationTypes): string {
        switch (type) {
            case AnimationTypes.Quiz:
                return colors.purple.main;
            case AnimationTypes.PhotoContest:
                return colors.blue.dark;
        }
    }

    /**
     * Méthode pour rediriger vers la consultation d'une animation
     * @param idAnimation id de l'animation qu'on veut consulter
     */
    function consultAnimation(idAnimation: number) {
        navigationPush(`/${idAnimation}/`)
        dispatch(replace(`/animation/${idAnimation}/`))
    }

    /**
     * Méthode pour rediriger vers la consultation d'une animation Level Up
     * @param idAnimation id de l'animation Level Up qu'on veut consulter
     */
    function consultAnimationLevelUp(idAnimation: number) {
        dispatch(replace(`/level-up/animations/${idAnimation}/`))
    }

    /**
     * Méthode pour afficher plus d'annimations lors du scroll s'il y a encore des animations à afficher
     */
    function fetchMoreAnimations() {
        if(totalAllAnim != allAnimationsList.length) {
            setNbrAnimToDisplay(nbrAnimToDisplay + 5);     
        }        
        setIsScrolling(false);
    }

    if(totalAllAnim == 0) {
        return (
            <Paper elevation={0} style={{ height: 700 }}>
                <EmptyListComponent title="C'est vide" subtitle="Il n'y a aucune animation pour le moment !" urlImage={NoAnimationSVG} />
            </Paper>
        );
    } else {
        return (
            <>
                <div className={isDesktopOrLaptop ? classes.animationsList : ''}>
                    {allAnimationsList.map((animation, index) => (
                        <>
                        {animation instanceof Animation &&
                            <Grow in timeout={1000 * Math.log(index + 2) / 2} key={index}>
                                <Paper elevation={0} onClick={() => consultAnimation(animation.idAnimation)} className={classes.animation}>                                
                                    <Chip
                                        size="small"
                                        className={classes.statusLabel}
                                        label={animation.getStatusLabel()}
                                        style={{
                                            backgroundColor: `${getLabelColor(animation.getStatus())}`
                                        }}
                                    />
                                    <Box className={classes.animationCardImage}
                                            style={{ 
                                                backgroundImage: `url('${animation.frontImage ? animation.frontImage.getSrc() : getAnimationCover(animation.type)}')`,
                                                backgroundColor: (animation.frontImage ? colors.white.main : getCoverBackgroundColor(animation.type)),
                                                backgroundSize: 'cover'
                                            }} 
                                    />
                                    <Box padding={2}>
                                        <p className={classes.typeName}>{animation.typeName}</p>
                                        <h3 className={classes.title}>{animation.title}</h3>
                                        <p className={classes.date}>
                                            Du <b>{animation.beginDate.format('L')}</b> au <b>{animation.endDate.format('L')}</b>
                                        </p>
                                    </Box>
                                </Paper>
                            </Grow>
                        }
                        {animation instanceof AnimationLevelUp &&
                            <Grow in timeout={1000 * Math.log(index + 2) / 2} key={index}>
                                <Paper elevation={0} onClick={() => consultAnimationLevelUp(animation.idAnimation)} className={classes.animation}>                                
                                    <Chip
                                        size="small"
                                        className={classes.statusLabel}
                                        label={animation.getStatusLabel()}
                                        style={{
                                            backgroundColor: `${getLabelColor(animation.getStatus())}`
                                        }}
                                    />
                                    <Box className={classes.animationCardImage}
                                            style={{ 
                                                backgroundImage: `url('${animation.image ? animation.image.getSrc() : quizSVG}')`, 
                                                backgroundColor: animation.image ? 'none' : colors.purple.main 
                                            }} 
                                    />
                                    <Box padding={2}>
                                        <p className={classes.typeName}>Antisèches</p>
                                        <h3 className={classes.title}>{animation.titre}</h3>
                                        <p className={classes.date}>
                                            Du <b>{moment(animation.dateDebut*1000)?.format('L')}</b> au <b>{moment(animation.dateFin*1000)?.format('L')}</b>
                                        </p>
                                    </Box>
                                </Paper>
                            </Grow>
                        }
                        </>

                    ))}
                </div>
            {(isScrolling || isLoadingLevelUp || isLoading) && 
                <Box display='flex' height={700} alignItems='center' justifyContent='center'>
                    <CircularProgress />
                </Box>
        }
            </>
        );
    }
}

export default AnimationsListComponent;
