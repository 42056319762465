import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { WithStyles, withStyles } from '@material-ui/styles';
import { Like } from "classes/murs/Like.class";
import { Post } from "classes/murs/Post.class";
import AvatarComponent from "components/avatar/Avatar.component";
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from "react";
import style from './ViewLikesPostDrawer.style';


interface BaseProps {
    triggered: boolean;
    onClose: ((ok: boolean) => void);
    post: Post;
}

interface GroupLikes {
    id_groupe_like: number;
    nbrLikes: number;
}

interface ViewLikesPostDrawerState {
    open: boolean;
    likes: Like[];
    currentGroupLike: number;
    groupesLikes: GroupLikes[];
    nbrLikesByGroup: {
        [key: number]: number;
    }
}

type ViewLikesPostDrawerProps = BaseProps
    & WithStyles<typeof style>

class ViewLikesPostDrawerComponent extends Component<ViewLikesPostDrawerProps, ViewLikesPostDrawerState> {

    public readonly state: ViewLikesPostDrawerState = {
        open: false,
        likes: [],
        currentGroupLike: -1,
        groupesLikes: [],
        nbrLikesByGroup: []
    };

    public componentDidUpdate(prevProps: ViewLikesPostDrawerProps): void {
        if (this.props.triggered != prevProps.triggered && this.props.triggered && !this.state.open) {
            this.openedDialog();
        }
    }

    async openedDialog() {
        this.setState({ open: true, likes: await this.props.post.getLikes(this.props.post.typeMur), groupesLikes: cloneDeep(this.props.post.groupesLikes).sort(function (a, b) { return a.id_groupe_like - b.id_groupe_like }) })
    }

    componentDidMount() {
    }

    public closeDrawer(ok: boolean) {
        this.props.onClose(ok);
        this.setState({
            open: false
        });
    }

    public render(): JSX.Element {
        const { classes, post } = this.props;
        const { open, currentGroupLike, likes, groupesLikes } = this.state;

        return <Drawer
            anchor="bottom"
            open={open}
            onClose={() => this.closeDrawer(false)}
            PaperProps={{ className: classes.drawer }}
        >
            <Tabs
                value={currentGroupLike}
                onChange={(_event, value) => this.setState({ currentGroupLike: value })}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label={`Tout (${post.nbLike})`} value={-1} />

                {
                    groupesLikes.map((groupLike) =>
                        <Tab value={groupLike.id_groupe_like} label={<Box display='flex' alignItems='center'><img className={classes.imgGroupeLike} src={Like.TYPES_LIKES[groupLike.id_groupe_like]} /><p>({groupLike.nbrLikes})</p></Box>} />
                    )
                }
            </Tabs>


            <Box overflow='scroll'>
                {
                    likes.map((like, index) => {
                        if (currentGroupLike === -1 || currentGroupLike == like.idGroupeLike) {
                            return <Box display='flex' width='100%' justifyContent='space-between' padding={1} alignItems='center'>
                                <Box display='flex' alignItems='center' fontWeight='700'>
                                    <AvatarComponent acteur={like.acteur} />
                                    <img style={{ marginLeft: '10px' }} className={classes.imgGroupeLike} src={Like.TYPES_LIKES[like.idGroupeLike]} />
                                    <p className={classes.textActeur}>{like.acteur.prenom} {like.acteur.nom}</p>
                                </Box>
                                <p className={classes.textDate}>{like.longueDateCreationLike}</p>
                            </Box>
                        }
                    }
                    )
                }
            </Box>
        </Drawer>;
    }
}

export default withStyles(style)(ViewLikesPostDrawerComponent);