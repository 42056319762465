import { ChallengeService } from "services";
import { B64File } from "classes/B64File.class";
import { ChallengeThemeImage } from "./ChallengeThemeImage.class";
import { DeepRawify } from "types";
import { DeepPartial } from "redux";

export class ChallengeThemePredefiniImage extends ChallengeThemeImage {

    public idThemePredefini: string = null;

    public image: string = null;

    private ChallengeService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ChallengeThemePredefiniImage>>) {

        super(row);
        if (row) {

            const {
                idThemePredefini,
                image,
            } = row;

            this.idThemePredefini = idThemePredefini;

            this.image = image;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.ChallengeService.saveChallengeThemeImage(this);
    // }

    public getSrc(): string {
        return this.image;
    }

    public toDatabaseObject(): object {
        return {
            idThemePredefini: this.idThemePredefini,
            image: this.image,
            type: this.type,
        }
    }
}