import React from 'react';

interface MomindumProps {
    /** URL de la vidéo */
    url: string;

    /** Pour ouvrir ou fermer le modal */
    onClick?: () => void;

    /** Mode preview : affiche la vignette et signale du clic, false par défaut */
    preview?: boolean;

    /** Jouer automatiquement la video, false par défaut */
    autoplay?: boolean;
}

const Momindum = (props: MomindumProps) => {
    if (props.preview) {
        return (
            <img 
                src={`/index.php?rub=1&p=21&url=${props.url}`} 
                alt="Vignette de la vidéo" 
                style={{ width: '100%' }} 
                onClick={props.onClick}
            />
        );
    }

    return (
        <iframe 
            src={props.url + `?displayHeader=false&autoplay=${props.autoplay ? "true" : "false"}`} 
            allowFullScreen
            style={{ width: '100%', aspectRatio: "16/9", border: 'none' }}></iframe>   
    );
}

Momindum.canPlay = (url: string) => {
    return url ? url.match(/(?:api\.momindum\.com\/)/) : false
};

export default Momindum;