import createStyles from "@material-ui/styles/createStyles";
import { colors, Theme } from "Theme";

export default ({ spacing, palette }: typeof Theme) =>
    createStyles({
        root: {
        },
        firstAddButton: {
            borderRadius: 5,
        },
        secondAddButton: {
            borderRadius: 5,
            backgroundColor: colors.lighterGrey.light,
            width: "100%",
            color: colors.grey.main,
            fontSize: 18,
            height: 56,
            marginBottom: spacing(-0.75)
        },
        firstAddButtonImg: {
            marginRight: spacing(0.5)
        },
        topTextLine: {
            fontWeight: 700,
            fontSize: 24
        },
        paper: {
            marginTop: spacing(0.5),
            padding: spacing(2),
            minHeight: 600,
            display: "flex",
        },
        progressBar: {
            width: '100%',
            height: 16,
            borderRadius: '100vw',
            margin: 'auto'
        },
    });
