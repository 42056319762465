import { ChallengeService } from 'services';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { B64File } from 'classes/B64File.class';
import { ChallengeStatus } from './ChallengeStatus.enum';
import { ChallengeThemePersonnaliseImage } from './ChallengeThemePersonnaliseImage.class';
import { ChallengeTheme } from './ChallengeTheme.class';
import { ChallengeThemePredefiniImage } from './ChallengeThemePredefiniImage.class';
import { ChallengeThemePredefini } from './ChallengeThemePredefini.class';
import { ChallengeThemePersonnalise } from './ChallengeThemePersonnalise.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';
import { Notifiable } from 'classes/notifications/Notifiable.class';

// SQL columns of the corresponding table (ex : "idActeur,emailActeur,imageActeur") (press tab when you're done) : idChallenge, leReferentielMetier, titre, description, dateDebut, dateFin, statut, typeChallenge, enLigne, imageCouverture
export class Challenge extends Notifiable {

	public getUrl(): string {
		return '/challenge/' + this.idChallenge + '/rubrique/';
	}
	public getID(): number {
		return this.idChallenge;
	}
	public notifType = 7;

	static typeChallengeTitle: string[] = [
		"Challenge national",
		"Challenge local",
		"Mini challenge",
	]
	
    public idChallenge: number = null;
	
    public leReferentielMetier: number = null;
	
    public titre: string = null;
	
    public description: string = null;
	
    public dateDebut: Moment = null;
	
    public dateFin: Moment = null;

    public dateActualisation: Moment = null;
	
    public typeChallenge: number = null;
	
    public enLigne: boolean = null;
	
    public imageCouverture: B64File = null;
	
    public idThemePredefini: number = null;
	
    public idThemePersonnalise: number = null;

    public idNiveau: number = null;

	public estResultatDefinitif: boolean = null;

	public estGainsEnvoyes: boolean = null;

	public afficherTempsRestant: boolean = null;

    public niveau : number = null;

    public entities : string[] = null;

    public hasClassements: boolean = null;

	public theme: ChallengeTheme = null;

	public reglementAccepte: number = null;

	public reglementObligatoire: number = null;

	public hasEdito: boolean = null;

	public hasAlreadySeenEdito: number = null;

    public nbSuivis : number = null;

    public hasSuivisSameNiveau : boolean = null;

	private ChallengeService = ChallengeService.getInstance();

	constructor(row?: DeepPartial<DeepRawify<Challenge>>, initializeTheme: boolean = true) {
		super();
		if (row) {
			const {
				idChallenge,
				leReferentielMetier,
				titre,
				description,
				dateDebut,
				dateFin,
                dateActualisation,
				typeChallenge,
				enLigne,
				imageCouverture,
				idThemePersonnalise,
				idThemePredefini,
				theme,
				reglementAccepte,
				reglementObligatoire,
				hasEdito,
				hasAlreadySeenEdito,
                idNiveau,
				estResultatDefinitif,
				estGainsEnvoyes,
				afficherTempsRestant,
                niveau,
                hasClassements,
                nbSuivis,
				hasSuivisSameNiveau,
                entities
			} = row;

			this.idChallenge = idChallenge;
			this.leReferentielMetier = leReferentielMetier;
			this.titre = titre;
			this.description = description;
			this.dateDebut = moment(dateDebut, 'X');
			this.dateFin = moment(dateFin, 'X');
			this.dateActualisation = dateActualisation ? moment(dateActualisation, 'X') : null;
			this.typeChallenge = typeChallenge;
			this.enLigne = enLigne;
			this.imageCouverture = B64File.fromDb(imageCouverture);
			this.idThemePredefini = idThemePredefini;
			this.idThemePersonnalise = idThemePersonnalise;
			this.idNiveau = idNiveau;
			this.estResultatDefinitif = estResultatDefinitif;
			this.estGainsEnvoyes = estGainsEnvoyes;
			this.afficherTempsRestant = afficherTempsRestant;
			this.niveau = niveau;
			this.hasClassements = hasClassements;
			this.reglementAccepte = reglementAccepte;
			this.reglementObligatoire = reglementObligatoire;
			this.hasEdito = hasEdito;
			this.nbSuivis = nbSuivis;
			this.hasSuivisSameNiveau = hasSuivisSameNiveau;
			this.hasAlreadySeenEdito = hasAlreadySeenEdito;
            this.entities = entities;
			if (initializeTheme) {
				if ('idThemePredefini' in theme) {
					this.theme = new ChallengeThemePredefini(theme)
				} else {
					this.theme = new ChallengeThemePersonnalise(theme)
				}
			}

		}
	}

	public getStatus(): ChallengeStatus {
		const now = moment();

		if (this.dateDebut.diff(now) > 0) {
			return ChallengeStatus.INCOMING;
		} if (this.dateFin.diff(now) > 0) {
			return ChallengeStatus.ONGOING;
		}
		return ChallengeStatus.FINISHED;
	}

	public getStatusLabel(): string {
		const status = this.getStatus();

		switch (status) {
			case ChallengeStatus.INCOMING:
				return 'À venir';

			case ChallengeStatus.ONGOING:
				return 'En cours';

			case ChallengeStatus.FINISHED:
				return 'Terminé';
		}
	}

	public save(themeImages?: ChallengeThemePersonnaliseImage[], entities? : string[]): Promise<string> {
		return this.ChallengeService.save(this, themeImages, entities);
	}

	public delete(): Promise<boolean> {
		return this.ChallengeService.delete(this.idChallenge);
	}

	public acceptRules() : Promise<boolean>
	{
		return this.ChallengeService.acceptRules(this.idChallenge);
	}

	public toDatabaseObject(): object {
		return {
			idChallenge: this.idChallenge,
			leReferentielMetier: this.leReferentielMetier,
			titre: this.titre,
			description: this.description,
			dateDebut: this.dateDebut.unix(),
			dateFin: this.dateFin.unix(),
			typeChallenge: this.typeChallenge,
			enLigne: this.enLigne,
			imageCouverture: this.imageCouverture,
			idThemePredefini: this.idThemePredefini,
			idThemePersonnalise: this.idThemePersonnalise,
            idNiveau: this.idNiveau,
			estResultatDefinitif: this.estResultatDefinitif,
			estGainsEnvoyes: this.estGainsEnvoyes,
			afficherTempsRestant: this.afficherTempsRestant,
		}
	}
}