import { DeepPartial } from "redux";
import { CanauxService } from "services";
import { DeepRawify } from "types";
import { NiveauCaracteristiqueChoix } from "./NiveauCaracteristiqueChoix.class";

export class NiveauCaracteristique {

    public idNiveauCaracteristique : number = null;

    public idNiveau : number = null;

    public libelle : string = null;

    public choix : NiveauCaracteristiqueChoix[] = [];

    private canauxService = CanauxService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<NiveauCaracteristique>>) {

        if (row) {

            const {
                idNiveauCaracteristique,
                idNiveau,
                libelle,
                choix
            } = row;

            this.idNiveauCaracteristique = idNiveauCaracteristique;

            this.idNiveau = idNiveau;

            this.libelle = libelle;

            this.choix = choix.map(rawChoix => new NiveauCaracteristiqueChoix(rawChoix));
        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.canauxService.saveNiveauCaracteristique(this);
    // }

    public toDatabaseObject(): object {
        return {
            idNiveauCaracteristique: this.idNiveauCaracteristique,
            idNiveau: this.idNiveau,
            libelle: this.libelle,
        }
    }
}