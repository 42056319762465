import React, { useState, useEffect, ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import BuildIcon from '@material-ui/icons/Build';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import MessageIcon from '@material-ui/icons/Message';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import MoodIcon from '@material-ui/icons/Mood';
import MoodOutlinedIcon from '@material-ui/icons/MoodOutlined';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccessibilityOutlinedIcon from '@material-ui/icons/AccessibilityOutlined';
import SchoolIcon from '@material-ui/icons/School';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import CupIcon from '../../assets/icon-coupe-blanc.svg';
import CupIconBlack from '../../assets/icon-coupe-noir.svg';
import MegaphoneIcon from '../../assets/icon-megaphone-blanc.svg';
import MegaphoneIconBlack from '../../assets/icon-megaphone-noir.svg';
import CachedIcon from '@material-ui/icons/Cached';

interface TypePostIconProps{
    icon: string;
    color?: string;
    class: string;
}

const TypePostIcon : React.FC<TypePostIconProps> = props => {

    const [Icon, setIcon] = useState<string | ComponentType<SvgIconProps>>(null);

    useEffect(() => {
        switch(props.icon) {
            case "icon-aide-blanc":
                setIcon(HelpIcon);
                break;
            case "icon-aide-blanc-outlined":
                setIcon(HelpOutlinedIcon);
                break;
            case "icon-ampoule-blanc":
                setIcon(EmojiObjectsIcon);
                break;
            case "icon-ampoule-blanc-outlined":
                setIcon(EmojiObjectsOutlinedIcon);
                break;
            case "icon-chart-blanc":
                setIcon(AssessmentIcon);
                break;
            case "icon-chart-blanc-outlined":
                setIcon(AssessmentOutlinedIcon);
                break;
            case "icon-cle-blanc":
                setIcon(VpnKeyIcon);
                break;
            case "icon-cle-blanc-outlined":
                setIcon(VpnKeyOutlinedIcon);
                break;
            case "icon-cleMolette-blanc":
                setIcon(BuildIcon);
                break;
            case "icon-cleMolette-blanc-outlined":
                setIcon(BuildOutlinedIcon);
                break;
            case "icon-cloche-blanc":
                setIcon(NotificationsIcon);
                break;
            case "icon-cloche-blanc-outlined":
                setIcon(NotificationsOutlinedIcon);
                break;
            case "icon-coeur-blanc":
                setIcon(FavoriteIcon);
                break;
            case "icon-coeur-blanc-outlined":
                setIcon(FavoriteOutlinedIcon);
                break;
            case "icon-coupe-blanc":
                setIcon(CupIcon)
                break;
            case "icon-coupe-noir":
                setIcon(CupIconBlack)
                break;
            case "icon-etoile-blanc":
                setIcon(StarIcon);
                break;
            case "icon-etoile-blanc-outlined":
                setIcon(StarOutlinedIcon);
                break;
            case "icon-graduation-blanc":
                setIcon(SchoolIcon)
                break;
            case "icon-graduation-blanc-outlined":
                setIcon(SchoolOutlinedIcon)
                break;
            case "icon-like-blanc":
                setIcon(ThumbUpIcon);
                break;
            case "icon-like-blanc-outlined":
                setIcon(ThumbUpOutlinedIcon);
                break;
            case "icon-liste-blanc":
                setIcon(AssignmentIcon);
                break;
            case "icon-liste-blanc-outlined":
                setIcon(AssignmentOutlinedIcon);
                break;
            case "icon-megaphone-blanc":
                setIcon(MegaphoneIcon);
                break;
            case "icon-megaphone-noir":
                setIcon(MegaphoneIconBlack);
                break;
            case "icon-message-blanc":
                setIcon(MessageIcon);
                break;
            case "icon-message-blanc-outlined":
                setIcon(MessageOutlinedIcon);
                break;
            case "icon-panier-blanc":
                setIcon(ShoppingBasketIcon);
                break;
            case "icon-panier-blanc-outlined":
                setIcon(ShoppingBasketOutlinedIcon);
                break;
            case "icon-signet-blanc":
                setIcon(BookmarkIcon);
                break;
            case "icon-signet-blanc-outlined":
                setIcon(BookmarkOutlinedIcon);
                break;
            case "icon-smiley-blanc":
                setIcon(MoodIcon);
                break;
            case "icon-smiley-blanc-outlined":
                setIcon(MoodOutlinedIcon);
                break;
            case "icon-user-blanc":
                setIcon(AccessibilityIcon);
                break;
            case "icon-user-blanc-outlined":
                setIcon(AccessibilityOutlinedIcon);
                break;
            default:
                setIcon(CachedIcon);
                break;
        }
    }, []);

    if (!Icon) return <CachedIcon className={props.class} />

    return (
        typeof Icon === "string" ? <img src={Icon} alt={props.icon} className={props.class} style={{ backgroundColor: props.color }} /> : <Icon className={props.class} style={{ backgroundColor: props.color, color: '#fff' }} />
    );
}

export default TypePostIcon;