import Axios from "axios";
import { Acteur } from "classes/Acteur.class";
import { Animation } from "classes/animations/Animation.class";
import { B64File } from "classes/B64File.class";
import { CarnetVoyage } from "classes/carnet_voyage/CarnetVoyage.class";
import { Edito } from "classes/carnet_voyage/edito/Edito.class";
import { Hotel } from "classes/carnet_voyage/hotel/Hotel.class";
import { CarnetInformations } from "classes/carnet_voyage/informations/CarnetInformations.class";
import { CarnetInformationsItem } from "classes/carnet_voyage/informations/CarnetInformationsItem.class";
import { Item } from "classes/carnet_voyage/Item.class";
import { CarnetParticipant } from "classes/carnet_voyage/participants/CarnetParticipant.class";
import { CarnetCategory } from "classes/carnet_voyage/programme/CarnetCategory.class";
import { CarnetDay } from "classes/carnet_voyage/programme/CarnetDay.class";
import { CarnetInformationsInfos, CarnetScheduleInfos } from "features/carnet_voyage/src/store/types";
import qs from "qs";
import { rxAxios, RxAxios } from "services/RxAxios";
import { DeepRawify } from "types";

export class CarnetVoyageService {
    private static instance: CarnetVoyageService;

    public static getInstance(): CarnetVoyageService {
        if (!CarnetVoyageService.instance) {
            CarnetVoyageService.instance = new CarnetVoyageService();
        }

        return CarnetVoyageService.instance;
    }

    private static rubriqueMur = IS_ADMIN ? 250 : 25;

    public save(carnet: CarnetVoyage, file: B64File): Promise<CarnetVoyage> {
        return Axios.post<{ content: DeepRawify<CarnetVoyage> }>(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 1
            })}`,
            qs.stringify({
                carnet: JSON.stringify(carnet.toRaw()),
                file: file ? JSON.stringify(file) : ''
            })
        ).then(({ data: { content } }) => new CarnetVoyage(content));
    }

    public saveItem(item: Item): Promise<Item> {
        return Axios.post<{ content: DeepRawify<Item> }>(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 4
            })}`,
            qs.stringify({
                item: JSON.stringify(item.toRaw())
            })
        ).then(({ data: { content } }) => new Item(content));
    }

    public deleteParticipant(participant: CarnetParticipant): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 25
            })}`,
            qs.stringify({
                participant: JSON.stringify(participant.idReferentielActeur)
            })
        );
    }

    public switchOnlineItem(idItem: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 21
            })}`,
            qs.stringify({
                idItem: JSON.stringify(idItem)
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public async loadItem(idItem: number): Promise<Item> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 26
        };

        return await Axios.post<{ content: DeepRawify<Item> }>(
            `index.php?${qs.stringify(params)}`,
            qs.stringify({
                idItem: JSON.stringify(idItem)
            })
        ).then(({ data: { content } }) => new Item(content));
    }

    public load(): Promise<CarnetVoyage> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 2
        };

        return Axios.get<{ content: DeepRawify<CarnetVoyage> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => new CarnetVoyage(content));
    }

    public loadItems(): Promise<Item[]> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 3
        };

        return Axios.get<{ content: DeepRawify<Item>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((item) => new Item(item)));
    }

    public loadParticipants(): Promise<CarnetParticipant[]> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 6
        };

        return Axios.get<{ content: DeepRawify<CarnetParticipant>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((carnetParticipant) => new CarnetParticipant(carnetParticipant)));
    }

    public loadSchedule(): Promise<CarnetScheduleInfos> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 7
        };

        return Axios.get<{ content: DeepRawify<CarnetScheduleInfos> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                const ret: CarnetScheduleInfos = {
                    schedule: content.schedule.map((schedule) => new CarnetDay(schedule)),
                    scheduleCategories: []
                };

                for (const category of content.scheduleCategories) {
                    ret.scheduleCategories[category.idCategory] = new CarnetCategory(category);
                };

                return ret;
            });
    }

    public loadInformations(): Promise<CarnetInformationsInfos> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 8
        };

        return Axios.get<{ content: DeepRawify<CarnetInformationsInfos> }>(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => ({
            carnetInformations: content.carnetInformations.map((carnetInformation) => new CarnetInformations(carnetInformation)),
            carnetInformationsItems: content.carnetInformationsItems.map((carnetInformationItem) => new CarnetInformationsItem(carnetInformationItem))
        }));
    }

    public saveInformations(informations: CarnetInformations[]): Promise<CarnetInformations[]> {
        return Axios.post<{ content: DeepRawify<CarnetInformations>[] }>(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 9
            })}`,
            qs.stringify({
                informations: JSON.stringify(informations)
            })
        )
            .then(({ data: { content } }) => content.map((information) => new CarnetInformations(information)));
    }

    public saveSchedule(schedule: CarnetDay[]): Promise<void> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 10
            })}`,
            qs.stringify({
                schedule: JSON.stringify(schedule.map(day => day.toRaw()))
            })
        );
    }

    public loadContacts(): Promise<Acteur[]> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 11
        };

        return Axios.get<{ content: DeepRawify<Acteur>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((acteur) => new Acteur(acteur)));
    }

    public saveContacts(contacts: Acteur[]): Promise<Acteur[]> {
        return Axios.post<{ content: DeepRawify<Acteur>[] }>(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 12
            })}`,
            qs.stringify({
                contacts: JSON.stringify(contacts)
            })
        )
            .then(({ data: { content } }) => content.map((contact) => new Acteur(contact)));
    }

    public loadCarnetQuestionnaires(): Promise<Animation[]> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 13
        };
        
        return Axios.get<{ content: DeepRawify<Animation>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: animations } }) => animations.map((animation) => new Animation.TYPES_ANIMATION[animation.type](animation)));
    }

    public loadEdito(): Promise<Edito> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 14
        };

        return Axios.get<{ content: DeepRawify<Edito> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => new Edito(content));
    }

    public saveEdito(edito: Edito): Promise<Edito> {
        return Axios.post<{ content: DeepRawify<Edito> }>(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 15
            })}`,
            qs.stringify({
                edito: JSON.stringify(edito)
            })
        )
            .then(({ data: { content } }) => new Edito(content));
    }

    public loadHotel(): Promise<Hotel> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 16
        };

        return Axios.get<{ content: DeepRawify<Hotel> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => new Hotel(content));
    }

    public saveHotel(hotel: Hotel): Promise<Hotel> {
        return Axios.post<{ content: DeepRawify<Hotel> }>(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 17
            })}`,
            qs.stringify({
                hotel: JSON.stringify(hotel)
            })
        )
            .then(({ data: { content } }) => new Hotel(content));
    }

    public async loadTelechargements(): Promise<B64File[]> {
        const params = {
            rub: CarnetVoyageService.rubriqueMur,
            p: 18
        };

        return Axios.get(`index.php?${qs.stringify(params)}`).then(({ data: { content } }) => B64File.rowsToArray(content));
    }

    public addFileTelechargements(b64File: B64File): RxAxios<{ content: DeepRawify<B64File> }> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 19
            })}`,
            data: qs.stringify({
                b64File: JSON.stringify(b64File)
            })
        });
    }

    public removeFileTelechargements(b64File: B64File): Promise<B64File> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 20
            })}`,
            qs.stringify({
                b64File: JSON.stringify(b64File)
            })
        )
            .then(({ data: { content } }) => B64File.rowsToFile(content));
    }

    public resetCarnet(): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: CarnetVoyageService.rubriqueMur,
                p: 24
            })}`
        );
    }
}
