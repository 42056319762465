import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import MonProfilReducer from "../monProfil/store/reducer";
import ContactReducer from "../contact/store/reducer";
import FAQReducers from "../faq/store/reducer";


const ParametresReducer = {
    monProfil : MonProfilReducer,
    contact : ContactReducer,
    faq : FAQReducers,
};

export default ParametresReducer;
