import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { CarnetVoyageService } from 'services';
import { AnimationStatus } from 'classes/animations/AnimationStatus.enum';
import { Acteur } from 'classes/Acteur.class';
import { B64File } from 'classes/B64File.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

export class CarnetVoyage {

    public idCarnet: number;

    public beginDate: Moment;

    public endDate: Moment;

    public nbrPhotos : number;


    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<CarnetVoyage>>) {
        if (row) {
            const { idCarnet, beginDate, endDate, nbrPhotos } = row;
            this.idCarnet = idCarnet;
            this.beginDate = moment(beginDate, 'X');
            this.endDate = moment(endDate, 'X');
            this.nbrPhotos = nbrPhotos;

        }
    }

    public getStatus(): AnimationStatus {
        const now = moment();

        if (this.beginDate.diff(now) > 0) {
            return AnimationStatus.INCOMING;
        } if (this.endDate.diff(now) > 0) {
            return AnimationStatus.ONGOING;
        }
        return AnimationStatus.FINISHED;
    }

    public getStatusLabel(): string {
        const status = this.getStatus();

        switch (status) {
            case AnimationStatus.INCOMING:
                return 'À venir';

            case AnimationStatus.ONGOING:
                return 'En cours';

            case AnimationStatus.FINISHED:
                return 'Terminé';
        }
    }


    public toRaw() {
        return {
            idCarnet: this.idCarnet,
            beginDate: this.beginDate.unix(),
            endDate: this.endDate.unix(),

        };
    }

    public async save(file : B64File): Promise<CarnetVoyage> {
        return this.carnetVoyageService.save(this, file);
    }
}
