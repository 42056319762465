import { DeepPartial } from "redux";
import { DeepRawify } from "types";

export abstract class ChallengeThemeImage{
    abstract getSrc() : string;


    public type: number = null;

    constructor(row? : DeepPartial<DeepRawify<ChallengeThemeImage>>){
        
        const {
            type,
        } = row;

        this.type = type;
    }
}