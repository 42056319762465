import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { v4 } from 'uuid';
import { Acteur } from 'classes/Acteur.class';
import { NotificationsService } from 'services/notifications/Notifications.service';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

export class Notification {

    public uid : string = v4();
    
    public id_notification: number;

    public nom: string;

    public timestamp: number;
    
    public message: string;

    public titre: string;

    public url: string;

    public image: string;

    public id_acteur_envoyeur: string;

    public acteur_envoyeur : Acteur = null;

    public id_acteur_receptionneur: string;

    public id_type : number;

    public id_pere : number;

    private notificationService = NotificationsService.getInstance();

    public date_envoie : string;

    public vue: boolean;


    constructor(row?: DeepPartial<DeepRawify<Notification>>) {
        if (row) {
            const { id_notification, nom, timestamp, message, url, image, titre, id_acteur_envoyeur, acteur_envoyeur, id_acteur_receptionneur, id_type, id_pere, date_envoie, vue } = row;
            this.id_notification = id_notification;
            this.nom = nom;
            this.timestamp = timestamp;
            this.message = message;
            this.url = url;
            this.image = image;
            this.id_acteur_receptionneur = id_acteur_receptionneur;
            this.titre = titre;
            this.id_acteur_envoyeur = id_acteur_envoyeur;
            this.acteur_envoyeur = new Acteur(acteur_envoyeur);
            this.id_type = id_type;
            this.id_pere = id_pere;
            this.date_envoie = date_envoie;
            this.vue = vue;
        }
    }


    public toRaw() {
        return {
            id_notification: this.id_notification,
            nom: this.nom,
            message: this.message,
            url: this.url,
            image: this.image,
            titre: this.titre,
            id_acteur_envoyeur: this.id_acteur_envoyeur,
            id_type : this.id_type,
            id_pere : this.id_pere,
            vue: this.vue
        };
    }

    public async vueNotification() {
        await this.notificationService.vueNotification(this.id_notification);
    }
}
