import qs from 'qs';
import Axios from 'axios';
import { Animation } from 'classes/animations/Animation.class';
import 'classes/animations/Quiz.class';
import 'classes/animations/concoursPhoto/ConcoursPhoto.class';
import { AnimationTypes } from '../../classes/animations/AnimationTypes.enum';
import { Observable, Subject } from 'rxjs';
import { DeepRawify } from 'types';


type FindParams = {
    rub : number,
    p : number,
    idAnimation : number,
    type? : number
}
export class AnimationsService {
    private static instance: AnimationsService;

    public static getInstance(): AnimationsService {
        if (!AnimationsService.instance) {
            AnimationsService.instance = new AnimationsService();
        }

        return AnimationsService.instance;
    }

    public findAllAdmin<T extends Animation>(): Promise<T[]> {
        return Axios.get<{ content: DeepRawify<T>[] }>(`index.php?${qs.stringify({
            rub: 22,
            p: 6
        })}`)
            .then(({ data: { content: animations } }) => animations.map((animation) => new Animation.TYPES_ANIMATION[animation.type](animation) as T));
    }

    public findAll<T extends Animation>(limit: number = null): Promise<{liste: T[], total: number}> {
        return Axios.get<{ content: {total: number, liste: DeepRawify<Animation>[]} }>(`index.php?${qs.stringify({
            rub: 20,
            p: 1,
            limit
        })}`)
            .then(({ data: { content } }) => {
                return {
                    total: content.total,
                    liste: content.liste.map(animation => new Animation.TYPES_ANIMATION[animation.type](animation) as T)
                };
            });
    }

    public find<T extends Animation>(idAnimation: number, type?: AnimationTypes, admin?: boolean): Promise<T> {
        const params : FindParams = {
            rub: admin ? 22 : 20,
            p: admin ? 3 : 2,
            idAnimation
        };

        if(type){
            params.type = type;
        }

        return Axios.get<{ content: DeepRawify<T> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: animation } }) => new Animation.TYPES_ANIMATION[animation.type](animation) as T);
    }

    public save<T extends Animation>(animation: T, callback: (error: string) => void): { progress: Observable<number>, animation: Observable<T> } {
        const progressSubject = new Subject<number>();
        const animationSubject = new Subject<T>();

        Axios.post<{ content: DeepRawify<T> }>(
            `index.php?${qs.stringify({
                rub: 22,
                p: 2
            })}`,
            qs.stringify({
                animation: JSON.stringify(animation.toObject())
            }),
            {
                onUploadProgress: (progressEvent) => {
                    progressSubject.next(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            }
        )
            .then((response) => {
                animationSubject.next(new Animation.TYPES_ANIMATION[animation.type](response.data.content) as T);
                animationSubject.complete();
                progressSubject.complete();
            })
            .catch(({ response: { data: { message } } }) => callback(message));

        return {
            progress: progressSubject.asObservable(),
            animation: animationSubject.asObservable()
        };
    }

    public delete(idAnimation: number): Promise<void> {
        const params = {
            rub: 22,
            p: 5,
            idAnimation
        };
        return Axios.get(`index.php?${qs.stringify(params)}`)

    }

    public duplicate(idAnimation: number): Promise<void> {
        const params = {
            rub: 22,
            p: 7,
            idAnimation
        };
        return Axios.get(`index.php?${qs.stringify(params)}`)
    }

    public getStatistics(idAnimation: number, type: AnimationTypes): Promise<{ playersNbr: string, averageScore: string, participationRate: string }> {
        const params = {
            rub: 22,
            p: 4,
            idAnimation,
            type
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: statistics } }) => statistics);
    }
}
