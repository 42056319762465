import { from, of } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import { deleteEntityAsync, loadArianeInfosAsync, loadEntitiesAsync, loadNiveauAsync, loadNiveauEntitiesInfosAsync, loadNbrMaxUtilisateursAtteintAsync, loadNiveauEntitiesParentsInfosAsync, loadEntityAsync, loadCreateModifyEntityInfosAsync } from "./actions";

const deleteEntityEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(deleteEntityAsync.request)),
    switchMap(action => from(participantsService.deleteEntity(action.payload)).pipe(
        map(deleteEntityAsync.success),
        catchError((message) => of(deleteEntityAsync.failure(message))),
    )),
);

const loadArianeInfosEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadArianeInfosAsync.request)),
    switchMap(action => from(participantsService.loadArianeInfos()).pipe(
        map(loadArianeInfosAsync.success),
        catchError((message) => of(loadArianeInfosAsync.failure(message))),
    )),
);

const loadEntitiesEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadEntitiesAsync.request)),
    switchMap(action => from(participantsService.loadEntities(action.payload)).pipe(
        map(loadEntitiesAsync.success),
        catchError((message) => of(loadEntitiesAsync.failure(message))),
    )),
);

const loadNiveauEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadNiveauAsync.request)),
    switchMap(action => from(participantsService.loadNiveau(action.payload)).pipe(
        map(loadNiveauAsync.success),
        catchError((message) => of(loadNiveauAsync.failure(message))),
    )),
);

const loadEntityEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadEntityAsync.request)),
    switchMap(action => from(participantsService.loadEntity(action.payload)).pipe(
        map(loadEntityAsync.success),
        catchError((message) => of(loadEntityAsync.failure(message))),
    )),
);

const loadNiveauEntitiesInfosEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadNiveauEntitiesInfosAsync.request)),
    switchMap(action => from(participantsService.loadNiveauEntitiesInfos(action.payload)).pipe(
        map(loadNiveauEntitiesInfosAsync.success),
        catchError((message) => of(loadNiveauEntitiesInfosAsync.failure(message))),
    )),
);

const loadNiveauEntitiesParentsInfosEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadNiveauEntitiesParentsInfosAsync.request)),
    switchMap(action => from(participantsService.loadNiveauEntitiesInfos(action.payload)).pipe(
        map(loadNiveauEntitiesParentsInfosAsync.success),
        catchError((message) => of(loadNiveauEntitiesParentsInfosAsync.failure(message))),
    )),
);

const loadCreateModifyEntityInfosEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadCreateModifyEntityInfosAsync.request)),
    switchMap(action => from(participantsService.loadCreateModifyEntityInfos(action.payload)).pipe(
        map(loadCreateModifyEntityInfosAsync.success),
        catchError((message) => of(loadCreateModifyEntityInfosAsync.failure(message))),
    )),
);

const loadNbrMaxUtilisateursAtteintEpics: RootEpic = (action$, state$, { participantsService }) => action$.pipe(
    filter(isActionOf(loadNbrMaxUtilisateursAtteintAsync.request)),
    switchMap(action => from(participantsService.loadNbrMaxUtilisateursAtteint()).pipe(
        map(loadNbrMaxUtilisateursAtteintAsync.success),
        catchError((message) => of(loadNbrMaxUtilisateursAtteintAsync.failure(message))),
    )),
);

const ParticipantsEpics = combineEpics(
    loadArianeInfosEpics,
    loadEntitiesEpics,
    loadNiveauEpics,
    loadEntityEpics,
    loadNiveauEntitiesInfosEpics,
    loadNiveauEntitiesParentsInfosEpics,
    loadCreateModifyEntityInfosEpics,
    loadNbrMaxUtilisateursAtteintEpics
);

export default ParticipantsEpics;
