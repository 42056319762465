import createStyles from "@material-ui/styles/createStyles";
import { colors, Theme } from "Theme";

export default ({ spacing, palette }: typeof Theme) =>
    createStyles({
        root: {
            padding: spacing(4),

            "& $formLabel": {
                fontSize: 20,
                fontWeight: "bold",
                color: colors.blue.main
            }
        },
        formLabel: {},
        bold: {
            fontWeight: 700
        },
        saveSVG: {
            marginRight: spacing(0.5),
        },
        buttonSave: {
            borderRadius: 5,
            marginBottom: spacing(1)
        },
    });
