import React, { Component } from 'react';
import './pacman.scss';

export class Pacman extends Component {
    render() {
        return (
            <div className="loader">
                <div className="circles">
                    <span className="one" />
                    <span className="two" />
                    <span className="three" />
                </div>
                <div className="pacman">
                    <span className="top" />
                    <span className="bottom" />
                    <span className="left" />
                </div>
            </div>
        );
    }
}
