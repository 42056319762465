import Axios from "axios";
import { Acteur } from "classes/Acteur.class";
import { Retour, Target } from "classes/notifications/Notifiable.class";
import { Notification } from "classes/notifications/Notification.class";
import { Entite } from "classes/referentiel/Entite.class";
import qs from 'qs';
import { DeepRawify } from "types";
import { NotificationsFilterState } from "./types";

export class NotificationsService {
    private static instance: NotificationsService;

    public static getInstance(): NotificationsService {
        if (!NotificationsService.instance) {
            NotificationsService.instance = new NotificationsService();
        }

        return NotificationsService.instance;
    }

    public loadNotifications(notificationsFilters: NotificationsFilterState): Promise<Notification[]> {
        return Axios.get<{ content: DeepRawify<Notification>[] }>(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 3,
            filters: JSON.stringify(notificationsFilters),
        })}`)
            .then(({ data: { content } }) => content.map((notification) => new Notification(notification)));
    }

    public vueNotification(id: number): Promise<void> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: IS_ADMIN ? 130 : 13,
                p: 4
            })}`,
            qs.stringify({
                id: JSON.stringify(id)
            })
        );

    }

    public vueAllNotifications(): Promise<void> {
        return Axios.post(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 5
        })}`);
    }

    public addAndroidSubscription(tokenFCM: string): Promise<void> {
        return Axios.post(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 6
        })}`,
        qs.stringify({
            tokenFCM: tokenFCM
        }));
    }

    public notifyActeurs(notif: Notification, targetType: Target, targets: string[], confirm: boolean): Promise<Retour> {
        return Axios.post<{ content: Retour}>(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 7
        })}`,
        qs.stringify({
            notif: JSON.stringify(notif.toRaw()),
            targetType,
            targets: JSON.stringify(targets),
            confirm: confirm ? 1 : 0
        })).then(({ data: { content } }) => content);
    }

    public listAD(): Promise<Entite[]> {
        return Axios.get<{ content: DeepRawify<Entite>[] }>(`index.php?${qs.stringify({
            rub: IS_ADMIN ? 130 : 13,
            p: 8
        })}`).then(({ data: { content } }) => content.map((i) => new Entite(i)));
    }
}
