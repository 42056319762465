import Axios from 'axios';
import qs from 'qs';
import { ActeurAnswer } from './ActeurAnswer.class';
import moment, { Moment } from 'moment';
import { Question } from './Question.class';
import { Answer, AnswerType } from './Answer.class';
import { QuestionTypes } from './QuestionTypes.enum';
import { QuizBadge } from './QuizBadge.class';
import { DeepRawify } from 'types';
import { IActeurAnswer, IPlayerResults } from 'features/admin/animations/src/store/types';
import { DeepPartial } from 'redux';

export class Participation {
    public idParticipation: number;

    public idAnimation: number;

    public completed: boolean;

    public score: number;

    public beginDate: Moment;

    public endDate: Moment;

    public duration: number;

    public rank: number;

    constructor(participation?: DeepPartial<DeepRawify<Participation>>) {
        if (participation) {
            const {
                idParticipation, idAnimation, completed, score, beginDate, endDate, duration, rank
            } = participation;
            this.idParticipation = idParticipation;
            this.idAnimation = idAnimation;
            this.completed = completed;
            this.score = score;
            this.beginDate = moment(beginDate, 'X');
            this.endDate = moment(endDate, 'X');
            this.duration = duration;
            this.rank = rank;
        }
    }

    public async fetchCurrentQuestion(): Promise<ActeurAnswer> {
        return Axios.get<{ content: DeepRawify<ActeurAnswer> }>(
            `index.php?${qs.stringify({
                rub: 21,
                p: 2,
                idParticipation: this.idParticipation,
            })}`,
        ).then(({ data: { content } }) => content ? new ActeurAnswer(content) : null);
    }

    public async computeScore(): Promise<void> {
        return Axios.post<{ content: DeepRawify<Participation> | null }>(
            `index.php?${qs.stringify({
                rub: 21,
                p: 5,
                idParticipation: this.idParticipation,
                endDate: moment.now(),
            })}`,
        ).then(({ data: { content } }) => {
            if (content) {
                const {
                    idParticipation, idAnimation, completed, score, beginDate, endDate, duration, rank
                } = content;
                this.idParticipation = idParticipation;
                this.idAnimation = idAnimation;
                this.completed = completed;
                this.score = score;
                this.beginDate = moment(beginDate, 'X');
                this.endDate = moment(endDate, 'X');
                this.duration = duration;
                this.rank = rank;
            }
        });
    }

    public async unlockQuizBadge(): Promise<QuizBadge> {
        return Axios.post<{
            content: {
                badge: DeepRawify<QuizBadge>,
                alreadyUnlocked: boolean
            }
        }>(
            `index.php?${qs.stringify({
                rub: 21,
                p: 7,
                idParticipation: this.idParticipation,
            })}`,
        ).then(({ data: { content: { badge, alreadyUnlocked } } }) => new QuizBadge({ ...badge, alreadyUnlocked }));
    }

    public async getActeurAnswers(): Promise<IActeurAnswer[]> {
        const params = {
            rub: 23,
            p: 7,
            idParticipation: this.idParticipation
        };

        return Axios.get<{
            content: DeepRawify<IActeurAnswer>[]
        }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: acteurs } }) => acteurs.map((acteur) => ({
                question: new Question(acteur.question),
                answers: typeof acteur.answers === 'string' ? acteur.answers : acteur.answers.map((answer) => new Answer(answer))
            })));
    }

    public async getPlayerResults(): Promise<IPlayerResults[]> {
        const params = {
            rub: 21,
            p: 6,
            idParticipation: this.idParticipation
        };

        return Axios.get<{ content: DeepRawify<IPlayerResults>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: acteurs } }) => acteurs.map((acteur) => ({
                ...acteur,
                question: new Question(acteur.question),
            })));
    }
}
