import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import AddSVG from '@material-ui/icons/AddBox'
import SaveSVG from '@material-ui/icons/Save'
import { WithStyles, withStyles } from '@material-ui/styles'
import { CarnetInformations } from 'classes/carnet_voyage/informations/CarnetInformations.class'
import { CarnetInformationsItem } from 'classes/carnet_voyage/informations/CarnetInformationsItem.class'
import EmptyListComponent from 'components/EmptyList.component'
import LigneHautTableau from 'components/LigneHautTableau.component'
import { Pacman } from 'components/pacman'
import { loadInformations, saveInformations } from 'features/carnet_voyage/src/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from "lodash/isEqual"
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dimensions, SortableContainer, SortEnd, SortEndHandler, SortEvent, SortStart } from 'react-sortable-hoc'
import { newSnackbar } from 'store/snackbars/snackBarsActions'
import { RootState } from 'store/types'
import { colors, greenTheme, Theme } from 'Theme'
import InformationsVideSVG from '../../../assets/informations_vide.svg'
import AddCarnetVoyageInformationsItemDialog from './AddCarnetVoyageInformationsItemDialog.component'
import style from './InformationsSettingsCarnet.style'
import SortableInformationComponent from './SortableInformation.component'



interface SortableItemsListProps {
    informations: CarnetInformations[];
    class: string;
    removeItem: ((index: number) => void);
}

const stateToProps = ({ carnetVoyage: { isLoading, informations, informationsItems } }: RootState) => ({
    informations, informationsItems, isLoading
})

const dispatchToProps = {
    loadInformations: loadInformations.request,
    saveInformations: saveInformations.request,
    newSnackbar
}

interface InformationsSettingsCarnetLocalProps {
}

interface InformationsSettingsCarnetState {
    allowSave: boolean,
    stateInformations: CarnetInformations[],
    openAddItem: boolean,
    alreadyLoaded: boolean,
    informationsItems: CarnetInformationsItem[]
}

type InformationsSettingsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & InformationsSettingsCarnetLocalProps;

class InformationsSettingsCarnet extends Component<InformationsSettingsCarnetProps, InformationsSettingsCarnetState> {
    private SortableItemsList = SortableContainer((props: SortableItemsListProps): JSX.Element => {
        const { informations, removeItem } = props;
        return (
            <Box display='flex' flexDirection='column' flexWrap='wrap' width='100%' padding={4}>
                {informations.map((information, index) =>
                    <SortableInformationComponent
                        updateParent={() => { this.forceUpdate(); this.setState({ allowSave: true }) }}
                        key={`information-${information.uid}`}
                        information={information}
                        class={props.class}
                        removeItem={() => removeItem(information.sequence)}
                        index={information.sequence}
                    />
                )}
            </Box>

        )
    });



    private blueTheme = (theme: typeof Theme) => {
        // debugger;
        return createTheme({
            ...theme,
            palette:
            {
                primary: colors.blue
            },
            overrides: {
                MuiButton: {
                    containedPrimary: {
                        '&:hover': {
                            backgroundColor: colors.blue.main,
                            boxShadow: 'none'
                        }
                    }
                }
            }
        });
    };

    /********** SORTABLE HOC METHODS *************/
    private readonly _onSortEnd: SortEndHandler = this._handleSortEnd.bind(this);

    private _handleSortEnd(sort: SortEnd, event: SortEvent): void {
        if (sort.newIndex >= this.state.stateInformations.length) {
            var k = sort.newIndex - this.state.stateInformations.length + 1;
            while (k--) {
                this.state.stateInformations.push(undefined);
            }
        }

        const newStateInformations = cloneDeep(this.state.stateInformations);
        newStateInformations.splice(sort.newIndex, 0, newStateInformations.splice(sort.oldIndex, 1)[0]);
        newStateInformations.forEach((item, index) => {
            item.sequence = index;
        });
        this.setState({
            stateInformations: newStateInformations
        })
    }

    private _getHelperDimensions(sort: SortStart): Dimensions {
        if (sort.node instanceof HTMLElement) {
            return ({
                width: sort.node.offsetWidth,
                height: sort.node.offsetHeight
            });
        }
        return { width: 0, height: 0 };
    }


    /************************/


    readonly state: InformationsSettingsCarnetState = {
        allowSave: false,
        stateInformations: [],
        openAddItem: false,
        alreadyLoaded: false,
        informationsItems: []
    }

    componentDidMount() {
        this.props.loadInformations();
    }

    componentDidUpdate(prevProps: InformationsSettingsCarnetProps, prevState: InformationsSettingsCarnetState) {
        const { loadInformations, informations, informationsItems, isLoading } = this.props;
        const { stateInformations, alreadyLoaded, allowSave } = this.state;
        if (!isLoading) {
            if (!allowSave && !!prevState.stateInformations && Array.isArray(prevState.stateInformations) && prevState.stateInformations.length > 0 && !isEqual(prevState.stateInformations, stateInformations)) {
                this.setState({
                    allowSave: true
                })
            }

            if (((stateInformations.length === 0 && stateInformations.length != informations.length) || informations.length === 0) && !alreadyLoaded) {

                // Find idItems of already added items
                const idItems: number[] = [];
                informations.map(value => idItems.push(value.idInformationItem));

                this.setState({
                    stateInformations: cloneDeep(informations) as CarnetInformations[],
                    alreadyLoaded: true,
                    informationsItems: informationsItems.filter(informationItem => !idItems.includes(informationItem.idInformationItem))
                });
            }
        }
    }

    handleCloseAddItemDialog(selectedItems: number[]) {
        const newStateInformations = cloneDeep(this.state.stateInformations);

        selectedItems.forEach((selectedItem, index) => {
            let information = new CarnetInformations();
            information.idInformationItem = selectedItem;
            information.sequence = index;

            newStateInformations.push(information)
        });
        // Find idItems of already added items
        const idItems: number[] = [];

        newStateInformations.map(value => idItems.push(value.idInformationItem));

        this.setState({
            stateInformations: newStateInformations,
            informationsItems: this.props.informationsItems.filter(informationItem => !idItems.includes(informationItem.idInformationItem)),
            openAddItem: false
        })
    }


    handleRemoveItem(index: number) {
        const { informations, } = this.props;
        const { stateInformations } = this.state;

        // Add back the item to the add list
        const item = this.props.informationsItems.filter(informationItem => informationItem.idInformationItem === this.state.stateInformations[index].idInformationItem)[0];
        this.state.informationsItems.push(item);

        let sequenceItem = this.state.stateInformations[index].sequence
        for (let i = index; i < this.state.stateInformations.length; i++) {
            if (this.state.stateInformations[i].sequence > sequenceItem) {
                this.state.stateInformations[i].sequence--;
            }
        }
        const newStateInformations = cloneDeep(this.state.stateInformations);
        newStateInformations.splice(index, 1);
        this.setState({
            stateInformations: newStateInformations
        });
    }

    saveAllInformations() {
        this.props.saveInformations(this.state.stateInformations);
        this.setState({
            allowSave: false
        })
    }

    render() {
        const { classes, isLoading } = this.props;
        const { stateInformations, informationsItems, openAddItem, allowSave } = this.state;

        if (isLoading) {
            return <Pacman />;
        }

        const componentsLigneHautInformations = [
            <ThemeProvider theme={greenTheme}>
                <Button
                    className={classes.buttonLaunch}
                    color='primary'
                    variant="contained"
                    size="large"
                    // disabled={isLoading}
                    onClick={() => this.saveAllInformations()}
                    disabled={!allowSave}
                >
                    <SaveSVG className={classes.imgLaunch} />
                    Enregistrer
            </Button>
            </ThemeProvider>


        ];
        const componentsLigneHautEditeur = [
            <ThemeProvider theme={this.blueTheme}>
                <Button
                    className={classes.buttonLaunch}
                    color='primary'
                    variant="contained"
                    size="large"
                    onClick={() => this.setState({ openAddItem: true })}
                >
                    <AddSVG className={classes.imgLaunch} />
                    Ajouter une rubrique
            </Button>
            </ThemeProvider>


        ];

        return (
            <Box>
                <LigneHautTableau className={classes.texteLigneHautInformations} components={componentsLigneHautInformations} title='Infos pratiques' />
                <Paper elevation={0} className={classes.paperEdition}>
                    <Box display='flex' flexDirection='column'>
                        <Box padding={4} display='flex' flexDirection='row' height={130} width='100%' justifyContent='center' alignItems='center' color='white' bgcolor={colors.blue.dark}>
                            <LigneHautTableau className={classes.texteLigneHautEditeur} components={componentsLigneHautEditeur} title='Editeur' />
                        </Box>
                        <Box minHeight={600} display='flex'>
                            {
                                stateInformations.length > 0 ?
                                    <this.SortableItemsList
                                        informations={stateInformations}
                                        removeItem={this.handleRemoveItem.bind(this)}
                                        class=''
                                        onSortEnd={this._onSortEnd}
                                        getHelperDimensions={this._getHelperDimensions}
                                        axis='y'
                                        useDragHandle />
                                    :
                                    <Box margin='auto'>
                                        <EmptyListComponent urlImage={InformationsVideSVG} title="C'est vide !" subtitle="Pour ajouter des informations, cliquez sur « Ajouter une rubrique » " />
                                    </Box>
                            }
                        </Box>
                    </Box>
                </Paper>
                <AddCarnetVoyageInformationsItemDialog carnetVoyageInformationsItem={informationsItems} triggered={openAddItem} onClose={this.handleCloseAddItemDialog.bind(this)} />
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(InformationsSettingsCarnet));