import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { ArianeInfo } from "./types";
import { Entite } from "classes/referentiel/Entite.class";
import { loadArianeInfosAsync, loadEntitiesAsync, loadEntityAsync, loadNiveauAsync, loadNiveauEntitiesInfosAsync, loadNbrMaxUtilisateursAtteintAsync, loadCreateModifyEntityInfosAsync, loadNiveauEntitiesParentsInfosAsync } from "./actions";
import { Niveau } from "classes/referentiel/Niveau.class";
import { NiveauChamp } from "classes/referentiel/NiveauChamp.class";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";
import cloneDeep from 'lodash/cloneDeep'
import { Acteur } from "classes/Acteur.class";
import { Role } from "classes/referentiel/Role.class";
import { loadCurrentActeurInfosAsync } from "store/acteur/acteurActions";

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadArianeInfosAsync.request,
    loadEntitiesAsync.request,
    loadNiveauEntitiesInfosAsync.request,
    loadNbrMaxUtilisateursAtteintAsync.request,
    loadEntitiesAsync.request,
    loadNiveauAsync.request,
    loadCreateModifyEntityInfosAsync.request], (_state, action) => true)
    .handleAction([loadArianeInfosAsync.success,
    loadEntitiesAsync.success,
    loadNiveauEntitiesInfosAsync.success,
    loadNbrMaxUtilisateursAtteintAsync.success,
    loadEntitiesAsync.success,
    loadNiveauAsync.success,
    loadCreateModifyEntityInfosAsync.success,
    loadArianeInfosAsync.failure,
    loadEntitiesAsync.failure,
    loadNiveauEntitiesInfosAsync.failure,
    loadNbrMaxUtilisateursAtteintAsync.failure,
    loadEntitiesAsync.failure,
    loadNiveauAsync.failure,
    loadCreateModifyEntityInfosAsync.failure], (_state, action) => false);

const isLoadingParents = createReducer<boolean, RootAction>(false)
    .handleAction([loadNiveauEntitiesParentsInfosAsync.request], (_state, action) => true)
    .handleAction([
    loadNiveauEntitiesParentsInfosAsync.success,
    loadNiveauEntitiesParentsInfosAsync.failure
    ], (_state, action) => false);

const arianeInfos = createReducer<ArianeInfo[], RootAction>([])
    .handleAction(loadArianeInfosAsync.success, (_state, action) => cloneDeep(action.payload))
    .handleAction(loadNiveauEntitiesInfosAsync.success, (_state, action) => cloneDeep(action.payload.arianeInfos))

const listEntities = createReducer<Entite[], RootAction>([])
    .handleAction(loadEntitiesAsync.success, (_state, action) => cloneDeep(action.payload))
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => cloneDeep(action.payload.entities))

// Liste des entités parents utilisée pour le transfert d'entité
const listEntitiesParents = createReducer<Entite[], RootAction>([])
    .handleAction([loadNiveauEntitiesParentsInfosAsync.success], (_state, action) => cloneDeep(action.payload.entities))

const listActors = createReducer<Acteur[], RootAction>([])
    // .handleAction(loadEntitiesAsync.success, (_state, action) => cloneDeep(action.payload))
    .handleAction([loadNiveauEntitiesInfosAsync.success], (_state, action) => cloneDeep(action.payload.actors))

const focusedEntity = createReducer<Entite, RootAction>(null)
    .handleAction(loadEntityAsync.success, (_state, action) => cloneDeep(action.payload))
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => cloneDeep(action.payload.currentEntity))

const currentNiveau = createReducer<Niveau, RootAction>(null)
    .handleAction(loadNiveauAsync.success, (_state, action) => cloneDeep(action.payload))
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => cloneDeep(action.payload.niveau))

const niveauPere = createReducer<Niveau, RootAction>(null)
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => cloneDeep(action.payload.niveauPere))

const fatherEntity = createReducer<Entite, RootAction>(null)
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => cloneDeep(action.payload.fatherEntity))

const niveauMax = createReducer<number, RootAction>(null)
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => action.payload.niveauMax)

const libelleNiveauMin = createReducer<string, RootAction>(null)
    .handleAction([loadNiveauEntitiesInfosAsync.success], (_state, action) => action.payload.libelleNiveauMin)

const niveauChamps = createReducer<NiveauChamp[], RootAction>(null)
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => cloneDeep(action.payload.niveauChamps))

const niveauCaracteristiques = createReducer<NiveauCaracteristique[], RootAction>(null)
    .handleAction([loadNiveauEntitiesInfosAsync.success, loadCreateModifyEntityInfosAsync.success, loadCurrentActeurInfosAsync.success], (_state, action) => cloneDeep(action.payload.niveauCaracteristiques))

const nbrMaxUtilisateursAtteint = createReducer<boolean, RootAction>(null)
    .handleAction([loadNbrMaxUtilisateursAtteintAsync.success], (_state, action) => action.payload)

const ParticipantsReducer = combineReducers({
    isLoading,
    isLoadingParents,
    arianeInfos,
    listEntities,
    listEntitiesParents,
    focusedEntity,
    currentNiveau,
    fatherEntity,
    niveauMax,
    libelleNiveauMin,
    niveauChamps,
    niveauCaracteristiques,
    niveauPere,
    listActors,
    nbrMaxUtilisateursAtteint
});

export default ParticipantsReducer;


