import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'

export class API {

    public uid: string = v4();

    public idApi: number;

    public libelle: string;

    public url: string;

    public enLigne: boolean;

    private levelUpService = LevelUpService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<API>>) {
        if (row) {
            const { idApi,
                libelle,
                url,
                enLigne,
            } = row;

            this.idApi = idApi;
            this.libelle = libelle;
            this.url = url;
            this.enLigne = !!enLigne;
        }
    }


    public toRaw() {
        return {
            idApi: this.idApi,
            libelle: this.libelle,
            url: this.url,
            enLigne: this.enLigne,
        };
    }

    public save(): Promise<boolean> {
        return this.levelUpService.saveAPI(this);
    }

    public delete: () => Promise<boolean> = () => {
        return this.levelUpService.deleteAPI(this.idApi);
    }
}
