import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadStatsAsync, loadMursAsync, loadStatsGlobalesAsync } from "./actions";
import { LoadStatsState, Murs, StatsGlobales } from "./types";
import { Post } from "classes/murs/Post.class";
import cloneDeep from 'lodash/cloneDeep'
import { Mur } from "classes/murs/Mur.class";

/** Concerne le chargement des stats d'un mur uniquement */
const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction(
        [loadStatsAsync.request],
         (_state, action) => true)
    .handleAction([loadStatsAsync.success, loadStatsAsync.failure], (_state, action) => false);

const stats = createReducer<Post[], RootAction>([])
    .handleAction([loadStatsAsync.success], (_state, action) => cloneDeep(action.payload));

/** Concerne le chargement de la liste des murs */
const areMursLoading = createReducer<boolean, RootAction>(false)
    .handleAction(
        [loadMursAsync.request],
         (_state, action) => true)
    .handleAction([loadMursAsync.success, loadMursAsync.failure], (_state, action) => false);

const murs = createReducer<Murs[], RootAction>([])
    .handleAction([loadMursAsync.success], (_state, action) => cloneDeep(action.payload));

const statsGlobales = createReducer<StatsGlobales[], RootAction>([])
    .handleAction([loadStatsGlobalesAsync.success], (_state, action) => cloneDeep(action.payload));

const RechercheReducer = combineReducers({
    isLoading,
    stats,
    areMursLoading,
    murs,
    statsGlobales
});

export default RechercheReducer;


