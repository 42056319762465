import { Post } from 'classes/murs/Post.class';
import { storeError } from 'store/types';
import { createAsyncAction, createAction } from 'typesafe-actions';
import { ElementLiteral, MurInfos, TypesPostAssociative, GetPostWithTypeMurType } from './types';
import { B64File } from 'classes/B64File.class';

export const loadPostsAsync = createAsyncAction('LOAD_POSTS_REQUEST', 'LOAD_POSTS_SUCCESS', 'LOAD_POSTS_FAILURE')<
void,
Post[],
string
>();

export const loadMorePostsAsync = createAsyncAction('LOAD_MORE_POSTS_REQUEST', 'LOAD_MORE_POSTS_SUCCESS', 'LOAD_MORE_POSTS_FAILURE')<
void,
Post[],
string
>();

export const loadElementsAsync = createAsyncAction('LOAD_ELEMENTS_REQUEST', 'LOAD_ELEMENTS_SUCCESS', 'LOAD_ELEMENTS_FAILURE')<
void,
ElementLiteral,
string>();

export const loadTypesPostAsync = createAsyncAction('LOAD_TYPES_POST_REQUEST', 'LOAD_TYPES_POST_SUCCESS', 'LOAD_TYPES_POST_FAILURE')<
void,
TypesPostAssociative,
string>();

export const getPostAsync = createAsyncAction('GET_POST_REQUEST', 'GET_POST_SUCCESS', 'GET_POST_FAILURE')<
number,
Post,
storeError>();

export const getPostFromIndexAsync = createAsyncAction('GET_POST_FROM_INDEX_REQUEST', 'GET_POST_FROM_INDEX_SUCCESS', 'GET_POST_FROM_INDEX_FAILURE')<
number,
Post,
storeError>();

export const getPostWithTypeMurAsync = createAsyncAction('GET_POST_WITH_TYPE_MUR_REQUEST', 'GET_POST_WITH_TYPE_MUR_SUCCESS', 'GET_POST_WITH_TYPE_MUR_FAILURE')<
GetPostWithTypeMurType,
Post,
storeError>();

export const loadMurInfos = createAsyncAction('LOAD_MUR_INFOS_REQUEST', 'LOAD_MUR_INFOS_SUCCESS', 'LOAD_MUR_INFOS_FAILURE')<
void,
MurInfos,
string
>();

export const preUploadVideoAsync = createAsyncAction('PRE_UPLOADED_VIDEO_REQUEST', 'PRE_UPLOADED_VIDEO_SUCCESS', "PRE_UPLOADED_VIDEO_FAILURE", "PRE_UPLOADED_VIDEO_CANCEL")<
File, 
B64File,
string,
void>();

export const resetPreUploadVideo = createAction('RESET_PRE_UPLOAD_VIDEO')<void>();

export const setProgressPreUploadedVideo = createAction('SET_PROGRESS_PRE_UPLOADED_VIDEO')<number>();

export const setTypeMur = createAction('SET_TYPE_MUR')<number>();

export const removePostFromList = createAction('REMOVE_POST_FROM_LIST')<number>();

export const setPost = createAction('SET_POST')<Post>();

export const resetError = createAction('RESET_ERROR')<void>();

export const setShouldPostsListGetUpdated = createAction('SET_SHOULD_POSTS_LIST_GET_UPDATED')<boolean>();

export const setList = createAction('SET_LIST_POSTS')<Post[]>();
/***********************************************
 ********** Actions filter list posts ********** 
 ***********************************************/

export const setListPostsTypePostFilter = createAction('SET_ID_TYPE_POST_FILTER')<number>();

export const setListPostsActifFilter = createAction('SET_LIST_POST_ACTIF_FILTER')<number>();

export const setListPostsLimitFilter = createAction('SET_LIST_POST_LIMIT_FILTER')<number>();

export const setListPostsMore = createAction('SET_LIST_POST_MORE')<boolean>();

export const setListPostsProgrammeFilter = createAction('SET_LIST_POST_PROGRAMME_FILTER')<boolean>();

export const setListPostsFavoriFilter = createAction('SET_LIST_POSTS_FAVORI_FILTER')<boolean>();

export const setListPostsActeurAuteurFilter = createAction('SET_LIST_POST_ACTEUR_AUTEUR_FILTER')<string>();

export const setListPostsActeurFilter = createAction('SET_LIST_POST_ACTEUR_FILTER')<string>();


/*** Configuration générale des filtres  */

export const setListPostsFilterToBrouillons = createAction('SET_LIST_POSTS_FILTER_TO_BROUILLONS')<void>();

export const setListPostsFilterToEnLigne = createAction('SET_LIST_POSTS_FILTER_TO_EN_LIGNE')<void>();

export const setListPostsFilterToProgramme = createAction('SET_LIST_POSTS_FILTER_TO_PROGRAMME')<void>();

export const setListPostsFilterToFavoris = createAction('SET_LIST_POSTS_FILTER_TO_FAVORIS')<void>();

export const setListPostsFilterToMesPosts = createAction('SET_LIST_POSTS_FILTER_TO_MES_POSTS')<string>();

export const resetMursStore = createAction('RESET_MURS_STORE')<void>();