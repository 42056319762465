import { createAsyncAction, createAction } from 'typesafe-actions';
import { Acteur } from 'classes/Acteur.class';
import { ActeurInfos, Droits } from './acteurTypes';


export const setLoggedIn = createAction('SET_LOGGED_IN')<boolean>();

export const loadCurrentActeurAsync = createAsyncAction(
    'LOAD_CURRENT_ACTEUR_REQUEST',
    'LOAD_CURRENT_ACTEUR_SUCCESS',
    'LOAD_CURRENT_ACTEUR_FAILURE',
)<void, Acteur, string>();


export const loadActeurAsync = createAsyncAction(
    'LOAD_ACTEUR_REQUEST',
    'LOAD_ACTEUR_SUCCESS',
    'LOAD_ACTEUR_FAILURE',
)<string, Acteur, string>();

export const loadActeurInfosAsync = createAsyncAction(
    'LOAD_ACTEUR_INFOS_REQUEST',
    'LOAD_ACTEUR_INFOS_SUCCESS',
    'LOAD_ACTEUR_INFOS_FAILURE',
)<string, ActeurInfos, string>();

export const loadCurrentActeurInfosAsync = createAsyncAction(
    'LOAD_CURRENT_ACTEUR_INFOS_REQUEST',
    'LOAD_CURRENT_ACTEUR_INFOS_SUCCESS',
    'LOAD_CURRENT_ACTEUR_INFOS_FAILURE',
)<void, ActeurInfos, string>();

