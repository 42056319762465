import { Moment } from 'moment';
import { CarnetVoyageService } from 'services';
import { B64File } from 'classes/B64File.class';
import { v4 } from 'uuid'
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';
export class CarnetInformations {

    public uid: string = v4();

    public idInformation: number;

    public content: string;

    public idInformationItem: number;

    public sequence: number;

    public image: B64File = null;

    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<CarnetInformations>>) {
        if (row) {
            const { idInformation,
                content,
                idInformationItem,
                sequence,
                image,
            } = row;
            this.idInformation = idInformation;
            this.content = content;
            this.idInformationItem = idInformationItem;
            this.sequence = sequence;
            this.image = B64File.fromDb(image);
        }
    }


    public toRaw() {
        return {
            idInformation: this.idInformation,
            idInformationItem: this.idInformationItem,
            content: this.content,
            sequence: this.sequence,
            image: this.image,
        };
    }


}
