import { createStyles, makeStyles } from "@material-ui/core";
import { Theme } from "Theme";

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    keyboardDateTimePicker: {
        '& [class*="MuiGrid-grid"]': {
            padding: "0px !important"
        },
        '& [class*="MuiGrid-direction"]': {
            marginRight: "25px !important"
        },
        '& [class*="MuiGrid-container"]': {
           width: "100%",
        },
        '& [class*="MuiPickerDTToolbar-toolbar"]': {
            marginBottom: "-15px",
         },
    }
}));
