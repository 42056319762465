import { Acteur } from "classes/Acteur.class";
import { DeepPartial } from "redux";
import { ChallengeService } from "services";
import { DeepRawify } from "types";

export class SuiviResultatMecaSimple {

    public id_challenge_resultat : number = null;

    public id_suivi : number = null;

    public id_referentiel_acteur : string = null;

    public acteur_nom : string = null;

    public acteur_prenom : string = null;

    public id_pere : string = null;

    public pere_libelle : string = null;

    public rang : number = null;

    public rangMaxClassement: number = null;

    public progressionRang: number = null;

    public total : number = null;

    public totalPourcent: number = null;

    public gain_libelle : string = null;

    public disqualification : string = null;

    public classement_libelle : string = null;

    public id_referentiel_metier : number = null;

    public id_resultat : number = null;

    public date_maj : string = null;

    public date_enregistrement : string = null;

    public periode : number = null;

    public listeGains : string[] = [];

    public joursRestants: number = null;

    public joursRestantsPourcentage: number = null;

    public infosActeur: Acteur = null;

    public estResponsableEquipe: boolean = false;    

    private challengeService = ChallengeService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<SuiviResultatMecaSimple>>) {

        if (row) {

            const {
                id_challenge_resultat,
                id_suivi,
                id_referentiel_acteur,
                acteur_nom,
                acteur_prenom,
                id_pere,
                pere_libelle,
                rang,
                rangMaxClassement,
                progressionRang,
                total,
                totalPourcent,
                gain_libelle,
                disqualification,
                classement_libelle,
                id_referentiel_metier,
                id_resultat,
                date_maj,
                date_enregistrement,
                periode,
                listeGains,
                joursRestants,
                joursRestantsPourcentage,
                infosActeur,
                estResponsableEquipe
            } = row;

            this.id_challenge_resultat = id_challenge_resultat;

            this.id_suivi = id_suivi;

            this.id_referentiel_acteur = id_referentiel_acteur;

            this.acteur_nom = acteur_nom;

            this.acteur_prenom = acteur_prenom;

            this.id_pere = id_pere;

            this.pere_libelle = pere_libelle;

            this.rang = rang;

            this.rangMaxClassement = rangMaxClassement;

            this.progressionRang = progressionRang;

            this.total = total;

            this.totalPourcent = totalPourcent

            this.gain_libelle = gain_libelle;

            this.disqualification = disqualification;

            this.classement_libelle = classement_libelle;

            this.id_referentiel_metier = id_referentiel_metier;

            this.id_resultat = id_resultat;

            this.date_maj = date_maj;

            this.date_enregistrement = date_enregistrement;

            this.periode = periode;

            this.listeGains = listeGains

            this.joursRestants = joursRestants;

            this.joursRestantsPourcentage = joursRestantsPourcentage;

            this.infosActeur = new Acteur(infosActeur);

            this.estResponsableEquipe = estResponsableEquipe;

        }
    }

    /**
     * Méthode permettant d'envoyer un message de félicitations
     * @param idActeurFelicite identifiant de l'acteur à féliciter
     * @param message Le message de félicitation à envoyer
     * @returns boolean
     */
    public async sendFelicitations(idActeurFelicite : string, message : string): Promise<boolean> {        
        return this.challengeService.sendFelicitations(idActeurFelicite, message);
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.challengeService.saveSuiviResultatMecaSimple(this);
    // }

    public toDatabaseObject(): object {
        return {
            id_challenge_resultat: this.id_challenge_resultat,
            id_suivi: this.id_suivi,
            id_referentiel_acteur: this.id_referentiel_acteur,
            acteur_nom: this.acteur_nom,
            acteur_prenom: this.acteur_prenom,
            id_pere: this.id_pere,
            pere_libelle: this.pere_libelle,
            rang: this.rang,
            total: this.total,
            gain_libelle: this.gain_libelle,
            disqualification: this.disqualification,
            classement_libelle: this.classement_libelle,
            id_referentiel_metier: this.id_referentiel_metier,
            id_resultat: this.id_resultat,
            date_maj: this.date_maj,
            date_enregistrement: this.date_enregistrement,
            periode: this.periode,
        }
    }
}