import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from "react";


interface ConfirmationDialogProps {
    triggered: boolean;
    onClose: ((Confirmation: boolean) => void);
    title: string;
    image?: string;
    text: string | JSX.Element;
    textOK?: string;
    textKO?: string;
    KO?: boolean;
    DialogProps? : DialogProps;
}

interface ConfirmationDialogState {
    open: boolean;
}



class ConfirmationDialogComponent extends Component<ConfirmationDialogProps, ConfirmationDialogState> {

    public readonly state: ConfirmationDialogState = {
        open: false,
    };


    public componentDidUpdate(prevProps: ConfirmationDialogProps): void {
        if (this.props.triggered != prevProps.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true });
        }
    }

    public closeDialog(ok: boolean) {
        this.props.onClose(ok);
        this.props.KO != false && this.setState({
            open: false
        });
    }


    public render(): JSX.Element {
        const { title, text, textKO, textOK, image, KO, DialogProps } = this.props;
        return <Dialog {...DialogProps} open={this.state.open} onClose={() => this.closeDialog(false)}>
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                {
                    image &&
                    <DialogContentText>
                        <img src={image} />
                    </DialogContentText>
                }
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                {
                    KO != false &&
                    <Button size="large" variant="contained" color="secondary" onClick={() => this.closeDialog(false)}>
                        {textKO ? textKO : "Annuler"}
                    </Button>
                }
                <Button size="large" variant="contained" color="primary" onClick={() => this.closeDialog(true)}>
                    {textOK ? textOK : "Valider"}
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

export default ConfirmationDialogComponent;