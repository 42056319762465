import React, { useState, useEffect } from 'react';
import { useSelector } from 'store/rootStore';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import useStyle from './TodoList.style';
import { TodoList } from 'classes/todolist/TodoList.class';
import EmptyListComponent from 'components/EmptyList.component';
import NoTaskSVG from '../assets/no_task.svg';
import { loadTasksAsync, saveTaskAsync, sortTasksAsync } from 'features/todolist/src/store/actions';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import cloneDeep from 'lodash/cloneDeep';
import SettingDrawer from 'features/parametres/src/monProfil/aPropos/SettingDrawer.component'
import { Setting } from 'features/parametres/src/monProfil/aPropos/Setting.interface'
import SortableList from './SortableList.component'
import { SortEndHandler } from 'react-sortable-hoc';
import { useMatomo } from '@datapunt/matomo-tracker-react'

interface TodoListProps{
}

const TodoListComponent : React.FC<TodoListProps> = props => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const [tasks, isLoading] = useSelector((state) => [state.todoList.todoList, state.todoList.isLoading], isEqual);

    const [list, setList] = useState<TodoList[]>([]);
    const [newTaskMessage, setMessage] = useState<string>("");
    const [newTaskDrawer,  setDrawer ] = useState<boolean>(false);

    const drawerSettings: Setting = {
        type: 'text',
        name: 'Tâche',
        action: 'Ajouter', /* Pour s'afficher dans le titre */
        value:'', 
        prefix:'une', /* Pour être affiché dans le titre du drawer mais pas le formulaire */
        regex: /^.{1,255}$/ /* Empêcher un champ vide */
    }

    const closeDrawer = (ok: boolean) => {
        if (ok) {
            // Création de la tâche
            let tmpTask = new TodoList({
                message: drawerSettings.value,
                done: false,
                priority: list.length
            })
            dispatch(saveTaskAsync.request(tmpTask))
        }
        setDrawer(false)
    }

    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        dispatch(sortTasksAsync.request([
            list[oldIndex].idTodo, 
            newIndex
        ]));
    }

    const { trackPageView } = useMatomo()

    useEffect(() => {
        dispatch(loadTasksAsync.request())

        if (!IS_ADMIN) {
            trackPageView({
                documentTitle: 'To-do list',
                href: URL_SITE + location.hash
            })
        }
    }, [])

    useEffect(() => {
        if (!isLoading && tasks != null) {
            setList(cloneDeep(tasks.sort((a, b) => a.priority - b.priority)))
        }
    }, [tasks])

    return (
        <Box className={classes.root}>
            {
                list.length > 0 ?
                    <SortableList list={list} useDragHandle pressDelay={150} lockAxis="y" onSortEnd={onSortEnd} />
                :
                    <EmptyListComponent 
                        title="Aucune tâche" 
                        subtitle="Vous n'avez renseigné aucune tâche pour le moment"
                        urlImage={NoTaskSVG} />
            }

            {
                IS_ADMIN ?
                    <Box display="flex" className={classes.bottomBar}>
                        <TextField 
                            variant="outlined" 
                            label="Qu'est-ce que j'ai à faire" 
                            fullWidth 
                            inputProps={{ maxLength: 255 }}
                            value={newTaskMessage}
                            onChange={(e) => {setMessage(e.target.value)}}
                            size="small" />

                        <Button 
                            className={classes.button}
                            startIcon={<AddCircleIcon />} 
                            variant="contained" 
                            onClick={() => {
                                if (newTaskMessage) {
                                    let tmpTask = new TodoList({
                                        message: newTaskMessage,
                                        priority: list.length
                                    })
                                    dispatch(saveTaskAsync.request(tmpTask))
                                    setMessage("")
                                }
                            }}
                            color="primary">Ajouter tâche</Button>
                    </Box>
                :
                    <Fab 
                        className={classes.fab} 
                        color="primary" 
                        onClick={() => setDrawer(true)}>
                            <AddIcon />
                    </Fab>
            }

            {
                newTaskDrawer &&
                <SettingDrawer
                    setting={drawerSettings}
                    onClose={closeDrawer} />
            }
        </Box>
    );

}

export default TodoListComponent;