import { Animation } from 'classes/level_up/animations/Animation.class';
import { Brique } from 'classes/level_up/animations/Brique.class';
import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ClassementAnswer, QuizInfos } from '../../quiz/store/types';

// +----------------------+
// |      ANIMATIONS      |
// +----------------------+

// Charge la liste des animations
export const loadAnimationsAsync = createAsyncAction(
    'LOAD_LEVELUP_ANIMATIONS_REQUEST',
    'LOAD_LEVELUP_ANIMATIONS_SUCCESS',
    'LOAD_LEVELUP_ANIMATIONS_FAILURE',
)<number, {liste: Animation[], total: number}, string>();

// Charge la liste des animations en cours pour le slider d'accueil
export const loadAnimationsAccueilAsync = createAsyncAction(
    'LOAD_LEVELUP_ANIMATIONS_ACCUEIL_REQUEST',
    'LOAD_LEVELUP_ANIMATIONS_ACCUEIL_SUCCESS',
    'LOAD_LEVELUP_ANIMATIONS_ACCUEIL_FAILURE',
)<void, Animation[], string>();

// Charge un animation
export const getAnimationAsync = createAsyncAction(
    'GET_LEVELUP_ANIMATION_REQUEST',
    'GET_LEVELUP_ANIMATION_SUCCESS',
    'GET_LEVELUP_ANIMATION_FAILURE',
)<number, Animation, string>();

// Animation en cours de modification
export const setSelectedAnimation = createAction('SET_LEVELUP_SELECTED_ANIMATION')<Animation>();

// Page (LIMIT) pour la liste
export const setPage = createAction('SET_LEVELUP_PAGE')<number>();

// PageFront (LIMIT) pour la liste en front
export const setPageFront = createAction('SET_LEVELUP_PAGE_FRONT')<number>();

// +---------------------+
// |       BRIQUES       |
// +---------------------+

// Charge la liste des briques
export const loadBriquesAsync = createAsyncAction(
    'LOAD_LEVELUP_BRIQUES_REQUEST',
    'LOAD_LEVELUP_BRIQUES_SUCCESS',
    'LOAD_LEVELUP_BRIQUES_FAILURE',
)<number, {liste: Brique[], total: number}, string>();

// Charge un brique
export const getBriqueAsync = createAsyncAction(
    'GET_LEVELUP_BRIQUE_REQUEST',
    'GET_LEVELUP_BRIQUE_SUCCESS',
    'GET_LEVELUP_BRIQUE_FAILURE',
)<number, Brique, string>();

// Brique en cours de modification
export const setSelectedBrique = createAction('SET_LEVELUP_SELECTED_BRIQUE')<Brique>();

// Page (LIMIT) pour la liste
export const setBriquesPage = createAction('SET_LEVELUP_BRIQUES_PAGE')<{order: string, limit: number}>();

// +---------------------+
// |        FRONT        |
// +---------------------+

// Charge les infos du quiz d'une animation
export const loadAnimQuizAsync = createAsyncAction(
    'LOAD_ANIM_QUIZ_REQUEST',
    'LOAD_ANIM_QUIZ_SUCCESS',
    'LOAD_ANIM_QUIZ_FAILURE',
)<number, QuizInfos, string>();

// Charge la prochaine question
export const getNextQuestionAsync = createAsyncAction(
    'GET_ANIM_NEXT_QUESTION_REQUEST',
    'GET_ANIM_NEXT_QUESTION_SUCCESS',
    'GET_ANIM_NEXT_QUESTION_FAILURE',
)<number, FicheQuestion, string>();

// Sauvegarde la réponse suivante
export const saveNextAnswerAsync = createAsyncAction(
    'SAVE_ANIM_NEXT_ANSWER_REQUEST',
    'SAVE_ANIM_NEXT_ANSWER_SUCCESS',
    'SAVE_ANIM_NEXT_ANSWER_FAILURE',
)<{answers: number[], idBrique: number}, FicheQuestion, string>();

// Charge le classement
export const loadClassementAnimAsync = createAsyncAction(
    'LOAD_CLASSEMENT_ANIM_REQUEST',
    'LOAD_CLASSEMENT_ANIM_SUCCESS',
    'LOAD_CLASSEMENT_ANIM_FAILURE',
)<number, ClassementAnswer[], string>();

export const loadClassementAnimBriqueAsync = createAsyncAction(
    'LOAD_CLASSEMENT_ANIM_BRIQUE_REQUEST',
    'LOAD_CLASSEMENT_ANIM_BRIQUE_SUCCESS',
    'LOAD_CLASSEMENT_ANIM_BRIQUE_FAILURE',
)<number, ClassementAnswer[], string>();