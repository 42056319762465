import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    fab: {
        outline: "none",
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: spacing(2)
    },
    bottomBar: {
        borderTopColor: colors.lighterGrey.light,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        padding: spacing(2, 0)
    },
    button: {
        minWidth: "150px",
        marginLeft: spacing(1.5)
    },
    root: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    }
}));