import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    closeButton: {
        position: 'absolute',
        right: spacing(1),
        top: spacing(1),
        color: colors.grey.main,
    },
    title: {
        textAlign: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    grid: {
        margin: spacing(1, 0),
    },
    gridItem: {
        flexBasis: '33%',
        minHeight: 270,
        borderRadius: 5,
    },
    card: {
        height: '100%',
    },
    cardActionArea: {
        width: '100%',
        height: '100%',
        cursor : 'pointer'
    },
    cardMedia: {
        height: 140,
        backgroundSize: 'cover',
    },
    cardContent: {
        minHeight: 110,
        '&:last-child' : {
            padding: spacing(2)
        }
    },
    cardContentTitle: {
        color: 'black',
        fontSize: 14,
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: spacing(1),
        fontFamily: "'Helvetica Neue' 'Helvetica' 'Arial' 'sans-serif'",
    },
    cardContentSubtitle: {
        /* Style for "Lorem ipsu" */
        color: colors.grey.main,
        fontSize: 16,
        fontWeight: 400,
        fontFamily: "'Helvetica Neue' 'Helvetica' 'Arial' 'sans-serif'",
    },
    blackBackground: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    bientotText: {
        width: 176,
        height: 56,
        fontSize: 29,
        fontWeight: 400,
        border: '1px solid white',
        borderRadius: '28px',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: 'white',
        position: 'relative',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        paddingTop: 6
    }
}));