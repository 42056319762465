import { DeepPartial } from "redux";
import { DeepRawify } from "types";
import { v4 } from "uuid";

/**
 * Complement d'une animation Level Up
 */
export class Complement
{

    public uid = v4();

    /**
     * L'identifiant du complément
     */
    public idComplement: number;

    /**
     * L'identifiant de l'animation associée
     */
    public idAnimation: number;

    /**
     * Titre du complément
     */
    public titre: string;

    /**
     * Valeur du complément
     */
    public valeur: string;

    /**
     * Date de mise à jour du complément
     */
    public dateMiseAJour: number;

    /**
     * Ordre d'affichage du complément
     */
    public ordre: number;

    /**
     * Constructeur du complément
     */
    public constructor(row?: DeepPartial<DeepRawify<Complement>>)
    {
        if (row) {
            const {
                idComplement,
                idAnimation,
                titre,
                valeur,
                dateMiseAJour,
                ordre
            } = row;

            this.idComplement = idComplement; 
            this.idAnimation = idAnimation; 
            this.titre = titre; 
            this.valeur = valeur; 
            this.dateMiseAJour = dateMiseAJour; 
            this.ordre = ordre; 
        }
    }

    public toRaw()
    {
        return {
            idComplement: this.idComplement,
            idAnimation: this.idAnimation,
            titre: this.titre,
            valeur: this.valeur,
            dateMiseAJour: this.dateMiseAJour,
            ordre: this.ordre
        };
    }

}



    
    
    
    
    
    
    
    