import { from, of, merge } from 'rxjs';
import {
    filter, switchMap, catchError, map, tap, mapTo, mergeMap, withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { adminLoadAnimationsAsync, adminSavePostAsync, adminLoadPostsAsync, adminGetAnimationAsync, adminGetQuestionsQuizAsync, adminSaveQuestionsQuizAsync, adminSetCurrentAnimation, adminGetQuizAsync, setProgressSaveQuestions, adminDeleteAnimationAsync, adminGetRankingsQuizAsync, adminDeleteRankingQuizAsync } from './actions';
import { setCurrentAnimation } from 'features/animations/src/store/actions';
import { rxAxios } from 'services/RxAxios';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';


const loadAnimationsEpics: RootEpic = (action$, state$, { animationService }) => action$.pipe(
    filter(() => state$.value.animations.list.length === 0),
    filter(isActionOf(adminLoadAnimationsAsync.request)),
    switchMap(() => from(animationService.findAllAdmin()).pipe(
        map(adminLoadAnimationsAsync.success),
        catchError(message => of(adminLoadAnimationsAsync.failure(message))),
    )),
);

const getAnimationEpics: RootEpic = (action$, state$, { animationService }) => action$.pipe(
    filter(isActionOf(adminGetAnimationAsync.request)),
    switchMap((action) => from(animationService.find(action.payload, null, true)).pipe(
        mergeMap((action) => [adminSetCurrentAnimation(action), adminGetAnimationAsync.success(action)]),
        // map(adminSetCurrentAnimation),
        catchError(message => of(adminGetAnimationAsync.failure(message)))
    ))
)

const deleteAnimationEpics: RootEpic = (action$, state$, { animationService }) => action$.pipe(
    filter(isActionOf(adminDeleteAnimationAsync.request)),
    switchMap((action) => from(animationService.delete(action.payload)).pipe(
        mergeMap((action) => [adminLoadAnimationsAsync.request(), adminDeleteAnimationAsync.success()]),
        catchError(message => of(adminDeleteAnimationAsync.failure(message)))
    ))
)
const getQuizEpics: RootEpic = (action$, state$, { quizService }) => action$.pipe(
    filter(isActionOf(adminGetQuizAsync.request)),
    switchMap((action) => from(quizService.find(action.payload)).pipe(
        mergeMap((action) => [adminGetQuizAsync.success(action), adminSetCurrentAnimation(action)]),
        // map(adminSetCurrentAnimation),
        catchError(message => of(adminGetQuizAsync.failure(message)))
    )
    )
)


const getQuestionsEpics: RootEpic = (action$, state$, { quizService }) =>
    action$.pipe(
        filter(isActionOf(adminGetQuestionsQuizAsync.request)),
        switchMap(() => from(quizService.getQuestionsQuiz(state$.value.adminAnimations.animations.currentAnimation.idAnimation)).pipe(
            map(adminGetQuestionsQuizAsync.success),
            catchError(message => of(adminGetQuestionsQuizAsync.failure(message)))
        ))
    );

const saveQuestionsEpics: RootEpic = (action$, state$, { quizService }) => action$.pipe(
    filter(isActionOf(adminSaveQuestionsQuizAsync.request)),
    map((action) => quizService.saveQuestions(state$.value.adminAnimations.animations.currentAnimation.idAnimation, action.payload)),
    switchMap(({ progress, response }) => merge(
        progress.pipe(
            map(setProgressSaveQuestions),
        ),
        response.pipe(
            mergeMap((action) => [newSnackbar({
                type: SnackbarType.SUCCESS,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Le quiz a bien été enregistré.`,
                }
            }), adminSaveQuestionsQuizAsync.success(action),adminGetQuestionsQuizAsync.request()]),
        ),
    )),
    catchError((error) => {
        newSnackbar({
            type: SnackbarType.WARNING,
            props: {
                open: true,
                autoHideDuration: 5000,
                message: `Il y a eu un problème dans l'enregistrement du quiz.`,
            }
        });
        return of(adminSaveQuestionsQuizAsync.failure(error))
    })
);


const getRankingsEpics: RootEpic = (action$, state$, { quizService }) => action$.pipe(
    filter(isActionOf(adminGetRankingsQuizAsync.request)),
    switchMap((action) => from(quizService.findRankings(state$.value.adminAnimations.animations.currentAnimation.idAnimation)).pipe(
        map(adminGetRankingsQuizAsync.success),
        catchError(message => of(adminGetRankingsQuizAsync.failure(message)))
    ))
);

const deleteRankingEpics: RootEpic = (action$, state$, { quizService }) => action$.pipe(
    filter(isActionOf(adminDeleteRankingQuizAsync.request)),
    switchMap((action) => from(quizService.deleteRanking(action.payload)).pipe(
        mergeMap((action) => [adminGetRankingsQuizAsync.request(), adminDeleteRankingQuizAsync.success()]),
        catchError(message => of(adminDeleteAnimationAsync.failure(message)))
    ))
)

/************** CONCOURS PHOTO **************** */

const adminLoadPostsEpic: RootEpic = (action$, state$, { concoursPhotoService } ) => action$.pipe(
    filter(isActionOf(adminLoadPostsAsync.request)),
    switchMap(action => from(concoursPhotoService.adminLoadPosts(action.payload)).pipe(
        map(adminLoadPostsAsync.success),
        catchError(message => of(adminLoadPostsAsync.failure(message)))
    ))
);

const adminSavePostEpic: RootEpic = (action$, state$, { concoursPhotoService }) => action$.pipe(
    filter(isActionOf(adminSavePostAsync.request)),
    switchMap((action) => from(concoursPhotoService.savePost(action.payload, () => {})).pipe(
        mergeMap((action) => [newSnackbar({
            type: SnackbarType.SUCCESS,
            props: {
                open: true,
                autoHideDuration: 3000,
                message: `Les modifications ont été enregistrées.`,
            }
        }), adminSavePostAsync.success(action)]),
        catchError((error) => {
            newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Il y a eu un problème dans l'enregistrement.`,
                }
            });
            return of(adminSavePostAsync.failure(error))
        })
    ))
);

const AnimationsEpics = combineEpics(
    loadAnimationsEpics,
    getAnimationEpics,
    getQuestionsEpics,
    saveQuestionsEpics,
    deleteAnimationEpics,
    getRankingsEpics,
    deleteRankingEpics,
    adminSavePostEpic,
    adminLoadPostsEpic
);

export default AnimationsEpics;
