import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { ThemeProvider, withStyles, WithStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import EmailIcon from "@material-ui/icons/EmailRounded"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import LinkIcon from "@material-ui/icons/LinkRounded"
import PhoneIcon from "@material-ui/icons/LocalPhoneRounded"
import PlaceIcon from "@material-ui/icons/PlaceRounded"
import SaveSVG from '@material-ui/icons/Save'
import WatchLaterIcon from "@material-ui/icons/WatchLaterRounded"
import { B64File } from "classes/B64File.class"
import { Hotel } from "classes/carnet_voyage/hotel/Hotel.class"
import B64FilePickerComponent from "components/filePicker/B64FilePicker.component"
import LigneHautTableauComponent from "components/LigneHautTableau.component"
import { Pacman } from "components/pacman"
import { loadHotel, saveHotel } from "features/carnet_voyage/src/store/actions"
import { HotelBooleanField, HotelBooleanFieldName, HotelFileFieldName, HotelStringFieldName } from "features/carnet_voyage/src/store/types"
import cloneDeep from 'lodash/cloneDeep'
import isEqual from "lodash/isEqual"
import React, { ChangeEvent, Component, Fragment } from "react"
import { connect } from "react-redux"
import { RootState } from "store/types"
import { colors, greenTheme } from "Theme"
import FacebookIcon from "../assets/facebook.svg"
import StarIcon from "../assets/star.svg"
import style from "./HotelSettingsCarnet.style"


const stateToProps = ({ carnetVoyage: { hotel, isLoading } }: RootState) => ({
    hotel,
    isLoading
});

const dispatchToProps = {
    loadHotel: loadHotel.request,
    saveHotel: saveHotel.request
};

interface HotelSettingsCarnetLocalProps { }

interface HotelSettingsCarnetState {
    allowSave: boolean;
    stateHotel: Hotel;
    alreadyLoaded: boolean;
}

type HotelSettingsCarnetProps = WithStyles<typeof style> & ReturnType<typeof stateToProps> & typeof dispatchToProps & HotelSettingsCarnetLocalProps;

class HotelSettingsCarnet extends Component<HotelSettingsCarnetProps, HotelSettingsCarnetState> {

    readonly state: HotelSettingsCarnetState = {
        allowSave: false,
        stateHotel: null,
        alreadyLoaded: false
    }
    // state: HotelSettingsCarnetState = {
    //     // equipments: {
    //     //     wifi: false, // replace with an array as [{ name: 'wifi', selected: false }, { name: 'wifi', selected: false }, ...]
    //     //     ordinateur: false, // find and replace using regex
    //     //     parking: false,
    //     //     laverie: false,
    //     //     piscine: false,
    //     //     "service de chambre": false,
    //     //     barbecue: false,
    //     //     "petit déjeuner": false,
    //     //     "centre commercial": false,
    //     //     "lit double": false,
    //     //     "centre d'affaires": false,
    //     //     restaurant: false,
    //     //     "fast food": false,
    //     //     animaux: false,
    //     //     "nom fumeur": false,
    //     //     épicerie: false,
    //     //     jeux: false,
    //     //     "salle de musculation": false,
    //     //     spa: false,
    //     //     jacuzzi: false,
    //     //     aviron: false,
    //     //     vélo: false,
    //     //     tennis: false,
    //     //     golf: false
    //     // }
    // };

    componentDidMount() {
        this.props.loadHotel();
        this.setState({ alreadyLoaded: true });
    }


    componentDidUpdate(prevProps: HotelSettingsCarnetProps, prevState: HotelSettingsCarnetState) {
        const { alreadyLoaded, stateHotel, allowSave } = this.state;
        const { isLoading, hotel } = this.props;
        if (!isLoading) {
            if (!allowSave && !!prevState.stateHotel && !isEqual(prevState.stateHotel, stateHotel)) {
                this.setState({
                    allowSave: true
                })
            }

            if (alreadyLoaded && !stateHotel) {
                this.setState({
                    stateHotel: hotel ? cloneDeep(hotel) : new Hotel()
                });
            }
        }
    }

    private onChangeBool(
        fieldName: HotelBooleanFieldName,
    ): void {
        const newStateHotel = cloneDeep(this.state.stateHotel);

        newStateHotel[fieldName] = !this.state.stateHotel[fieldName];

        this.setState({
            stateHotel: newStateHotel
        });
    }

    private onChangeFile(
        fieldName: HotelFileFieldName,
        file: B64File
    ): void {
        const newStateHotel = cloneDeep(this.state.stateHotel);

        newStateHotel[fieldName] = file;

        this.setState({
            stateHotel: newStateHotel
        });
    }

    private onChangeString(
        fieldName: HotelStringFieldName,
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ): void {
        const newStateHotel = cloneDeep(this.state.stateHotel);

        newStateHotel[fieldName] = event.target.value;

        this.setState({
            stateHotel: newStateHotel
        });
    }

    showStars() {
        let stars = [];
        let starsNumber = this.state.stateHotel.stars || 3;
        for (let i = 0; i < starsNumber; i++) {
            stars.push(<img key={i} src={StarIcon} style={{ marginLeft: 15 }} />);
        }

        return stars;
    }

    generateTextField(title: HotelStringFieldName, label: string, icon?: JSX.Element, rows?: number) {
        return (
            <TextField
                placeholder={label}
                label={label}
                rows={rows}
                multiline={rows ? true : false}
                value={this.state.stateHotel[title]}
                className={this.props.classes.textField}
                onChange={(e) => this.onChangeString(title, e)}
                style={{
                    marginBottom: 30
                }}
                InputProps={icon ? {
                    startAdornment: (
                        <InputAdornment position="start" style={{ color: "#8F8F8F" }}>
                            <Box>{icon}</Box>
                        </InputAdornment>
                    )
                } : {}}
            />
        );
    }

    generateEquipment(field: HotelBooleanField) {
        let Icon = field.icon;
        const { classes } = this.props;
        return (
            <Tooltip title={field.tooltip} placement="top">
                <Box
                    onClick={() => this.onChangeBool(field.field)}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="70px"
                    height="70px"
                    bgcolor={this.state.stateHotel[field.field] ? colors.green.main : "white"}
                    color={this.state.stateHotel[field.field] ? 'white' : colors.grey.main}
                    borderRadius='50%'
                    border={`1px solid ${colors.lighterGrey.light}`}
                    marginTop={2}
                    marginRight={2}
                >
                    {
                        field.materialIcon ?
                            <Icon />
                            :
                            <Fragment>
                                {this.state.stateHotel[field.field] ?
                                    <img className={classes.imgEquipment} src={field.whiteIcon} />
                                    :
                                    <img className={classes.imgEquipment} src={field.greyIcon} />
                                }
                            </Fragment>
                    }
                </Box>
            </Tooltip>
        );
    }

    // equipmentClicked(name: string) {
    //     this.state.equipments[name] = !this.state.equipments[name];
    //     this.forceUpdate();
    // }

    handleSelectChange(event: React.ChangeEvent<{
        name?: string;
        value: unknown;
    }>) {
        const newStateHotel = cloneDeep(this.state.stateHotel);

        newStateHotel.stars = event.target.value as number;

        this.setState({
            stateHotel: newStateHotel
        });
        // this.setState({ stars: event.target.value })
    }

    public render(): JSX.Element {
        const { classes, hotel, saveHotel } = this.props;
        const { stateHotel, allowSave } = this.state;

        const componentsLigneHaut = [
            <ThemeProvider theme={greenTheme}>
                <Button
                    disabled={!allowSave}
                    className={classes.buttonSave}
                    color='primary'
                    variant="contained"
                    size="large"
                    onClick={() => { this.setState({ allowSave: false }); saveHotel(stateHotel) }}
                >
                    <SaveSVG className={classes.saveSVG} />
                    Enregistrer
                </Button>
            </ThemeProvider>
        ]

        if (!stateHotel) {
            return <Pacman />
        }
        return (
            <Box display="flex" flexDirection="column">
                <LigneHautTableauComponent className={classes.bold} title="Hotel" components={componentsLigneHaut} />
                <Paper elevation={0}>
                    <Box display="flex" flexDirection="column" className={classes.root}>
                        <FormLabel className={classes.formLabel}>Informations principales</FormLabel>
                        <Box display="flex" flexBasis="50%" width="100%" marginBottom={4}>
                            <Box display="flex" flexBasis="50%" flexDirection="column" style={{ margin: "30px 0 0" }}>
                                {this.generateTextField('name', "Nom de l'hotel")}
                                {this.generateTextField('description', "Description", null, 7)}
                                <p className={classes.fieldTitle}>Nombre d'étoiles</p>
                                <Box display="flex">
                                    <Select
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={stateHotel.stars || 3}
                                        onChange={this.handleSelectChange.bind(this)}
                                        style={{ width: 150, marginRight: 15 }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                    {this.showStars()}
                                </Box>
                            </Box>
                            <Box display="flex" flexBasis="50%" flexDirection="column" marginLeft="40px">
                                <Box>
                                    <p className={classes.fieldTitle}>Photos de l'hôtel</p>
                                    <B64FilePickerComponent onChange={f => this.onChangeFile('image1', f)} value={stateHotel.image1} height="276px" />
                                </Box>
                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Box display="flex" flexBasis="20%" marginRight={2}>
                                        <B64FilePickerComponent emptyInnerZone onChange={f => this.onChangeFile('image2', f)} value={stateHotel.image2} />
                                    </Box>
                                    <Box display="flex" flexBasis="20%" marginRight={2}>
                                        <B64FilePickerComponent emptyInnerZone onChange={f => this.onChangeFile('image3', f)} value={stateHotel.image3} />
                                    </Box>
                                    <Box display="flex" flexBasis="20%" marginRight={2}>
                                        <B64FilePickerComponent emptyInnerZone onChange={f => this.onChangeFile('image4', f)} value={stateHotel.image4} />
                                    </Box>
                                    <Box display="flex" flexBasis="20%" marginRight={2}>
                                        <B64FilePickerComponent emptyInnerZone onChange={f => this.onChangeFile('image5', f)} value={stateHotel.image5} />
                                    </Box>
                                    <Box display="flex" flexBasis="20%">
                                        <B64FilePickerComponent emptyInnerZone onChange={f => this.onChangeFile('image6', f)} value={stateHotel.image6} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <FormLabel className={classes.formLabel}>Accès et coordonnées</FormLabel>
                        <Box display="flex" width="100%" style={{ marginTop: 30, marginBottom: 10 }}>
                            <Box display="flex" flexDirection="column" flexBasis="50%">
                                {this.generateTextField('location', "Localisation", <PlaceIcon />)}
                                {this.generateTextField('phone', "Téléphone", <PhoneIcon />)}
                                {this.generateTextField('website', "Site internet", <LinkIcon />)}
                            </Box>

                            <Box display="flex" flexDirection="column" flexBasis="50%" marginLeft="40px">
                                {this.generateTextField('arrivingTime', "Horaires", <WatchLaterIcon />)}
                                {this.generateTextField('mail', "E-mail", <EmailIcon />)}
                                {this.generateTextField('facebook', "Facebook", <img src={FacebookIcon} />)}
                            </Box>
                        </Box>

                        <FormLabel className={classes.formLabel} style={{ marginBottom: 20 }}>
                            Equipements
                    </FormLabel>
                        <p>Sélectionnez les équipements disponibles</p>
                        <Box display="flex" flexWrap="wrap" justifyContent="left">
                            {
                                Hotel.boolFields.map((field, index) =>
                                    this.generateEquipment(field)
                                )
                            }
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(HotelSettingsCarnet));
