import { DeepPartial } from "redux";
import { CanauxService } from "services";
import { DeepRawify } from "types";

export class NiveauTypeChamp {

    public idNiveauTypeChamp: number = null;

    public title: string = null;
    public groupe: number = null;
    public libelleGroupe: number = null;
    public validation: number = null;
    public typeChamp: number = null;

    private canauxService = CanauxService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<NiveauTypeChamp>>) {

        if (row) {

            const {
                idNiveauTypeChamp,
                title,
                groupe,
                libelleGroupe,
                validation,
                typeChamp
            } = row;

            this.idNiveauTypeChamp = idNiveauTypeChamp;

            this.title = title;

            this.groupe = groupe;

            this.libelleGroupe = libelleGroupe;
            this.validation = validation;

            this.typeChamp = typeChamp;


        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.canauxService.saveEntiteChamp(this);
    // }

    public toDatabaseObject(): object {
        return {
            idNiveauTypeChamp: this.idNiveauTypeChamp,
            title: this.title,
            groupe: this.groupe,
            libelleGroupe: this.libelleGroupe,
            validation: this.validation,
            typeChamp: this.typeChamp,
        }
    }
}