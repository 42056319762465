import { from, of } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import { loadSignalementsAsync, loadSignalementsFiltersAsync, loadSignalementsCountAsync } from "./actions";

const loadSignalementsEpics: RootEpic = (action$, state$, { signalementsService }) => action$.pipe(
    filter(isActionOf(loadSignalementsAsync.request)),
    switchMap((action) => from(signalementsService.loadSignalements(action.payload)).pipe(
        map(loadSignalementsAsync.success),
        catchError(message => of(loadSignalementsAsync.failure(message))),
    )),
);

const loadSignalementsInfosEpics: RootEpic = (action$, state$, { signalementsService }) => action$.pipe(
    filter(isActionOf(loadSignalementsFiltersAsync.request)),
    switchMap(() => from(signalementsService.loadSignalementsInfos()).pipe(
        map(loadSignalementsFiltersAsync.success),
        catchError(message => of(loadSignalementsFiltersAsync.failure(message))),
    )),
);

const loadSignalementsCountEpics: RootEpic = (action$, state$, { signalementsService }) => action$.pipe(
    filter(isActionOf(loadSignalementsCountAsync.request)),
    switchMap(() => from(signalementsService.loadSignalementsCount()).pipe(
        map(loadSignalementsCountAsync.success),
        catchError(message => of(loadSignalementsCountAsync.failure(message))),
    ))
);


const SignalementsEpics = combineEpics(
    loadSignalementsEpics,
    loadSignalementsInfosEpics,
    loadSignalementsCountEpics
);

export default SignalementsEpics;
