import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid';

/**
 * Réponse d'une fiche
 */
export class FicheAnswer {

    public uid: string = v4();

    /**
     * Identifiant de la réponse
     */
    public idAnswer: number;

    /**
     * Identifiant de la question associée
     */
    public idQuestion: number;

    /**
     * Libellé de la réponse
     */
    public title: string;

    /**
     * La réponse est-elle correcte
     */
    public isCorrect: boolean;

    //private levelUpService = LevelUpService.getInstance();

    /**
     * Constructeur d'un objet FicheAnswer
     */
    constructor(row?: DeepPartial<DeepRawify<FicheAnswer>>) {
        if (row) {
            const { idAnswer, idQuestion, title, isCorrect } = row;

            this.idAnswer = idAnswer ?? null;
            this.idQuestion = idQuestion ?? null;
            this.title = title ?? null;
            this.isCorrect = isCorrect ?? null;
        }
    }

    /**
     * Méthode de renvoi de l'objet en back sous la forme d'un tableau
     */
    public toRaw() {
        return {
            idAnswer: this.idAnswer,
            idQuestion: this.idQuestion,
            title: this.title,
            isCorrect: this.isCorrect
        };
    }

    /**
     * Sauvegarde la réponse en back
     */
    public save() {
        //return this.levelUpService.saveFicheAnswer(this);
    }

    /**
     * Suppression de la réponse en back
     */
    public delete() {
        //return this.levelUpService.deleteFicheAnswer(this.idAnswer);
    }

}
