import { B64File } from 'classes/B64File.class';
import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';

/**
 * Lorsque l'image vient d'un thème prédéfini on doit stocker son URL.
 */

export class ContenuBlocImage extends ContenuBloc {

    public uid: string = v4();

    public image : B64File = null;

    public imageURL : string = null;

    constructor(row?: DeepPartial<DeepRawify<ContenuBlocImage>>) {
        super(row);

        if (row) {
            const { image, imageURL } = row;
            this.imageURL = imageURL;
            this.image = B64File.fromDb(image);
        }
    }


    public toRaw() {
        return {
            image : this.image,
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            imageURL : this.imageURL,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
            
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        if (!this.image && !this.imageURL) return true;
        else return false;
    }


}
