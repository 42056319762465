import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import moment, { Moment } from 'moment';
import { Acteur } from 'classes/Acteur.class';

/**
 * Commentaires d'une fiche  
 */
export class FicheCommentaire
{

    /**
     * L'identifiant du commentaire de la fiche
     * @var int
     */
    public idFicheCommentaire: number;

    /**
     * L'identifiant de l'acteur auteur du commentaire
     * @var string
     */
    public idActeur: string;

    /**
     * Données de l'acteur auteur du commentaire
     */
    public acteur: Acteur;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idFiche: number;

    /**
     * Contenu du commentaire
     * @var string
     */
    public commentaire: string;

    /**
     * Dernier enregistrement
     * @var int
     */
    public dateEnregistrement: Moment;

    /**
     * Date de creation
     * @var int
     */
    public dateCreation: Moment;

    private levelUpService = LevelUpService.getInstance();

    /**
     * __construct
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FicheCommentaire>>) {
        if (row) {
            const { idFicheCommentaire,
                idFiche, dateCreation,
                idActeur, acteur,
                dateEnregistrement,
                commentaire } = row;

            this.idFicheCommentaire = idFicheCommentaire ?? null;
            this.idFiche = idFiche ?? null;
            this.idActeur = idActeur ?? null;
            this.acteur = new Acteur(acteur) ?? null;
            this.commentaire = commentaire ?? null;
            this.dateCreation = dateCreation ? moment(dateCreation, 'X') : null;
            this.dateEnregistrement = dateEnregistrement ? moment(dateEnregistrement, 'X') : null;
        } 
    }

    public toRaw() {
        return {
            idFicheCommentaire: this.idFicheCommentaire,
            idFiche: this.idFiche,
            idActeur: this.idActeur,
            dateEnregistrement: this.dateEnregistrement && this.dateEnregistrement.unix(),
            commentaire: this.commentaire,
            dateCreation: this.dateCreation && this.dateCreation.unix()
        };
    }

    public save(): Promise<boolean> {
        return this.levelUpService.saveCommentaire(this);
    }

    public delete(): Promise<boolean> {
        return this.levelUpService.deleteCommentaire(this.idFicheCommentaire);
    }

}
