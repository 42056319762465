import { ParticipantsService } from "services";
import { EntiteCaracteristiqueValue } from "./EntiteCaracteristiqueValue.class";
import { EntiteChampValue } from "./EntiteChampValue.class";
import cloneDeep from 'lodash/cloneDeep'
import { DeepRawify } from "types";
import { DeepPartial } from "redux";

export class Entite {

    public idEntite: string = null;

    public idEntiteAffichage: string = null;

    public idPere: string = null;

    public idReferentielNiveau: number = null;

    public niveau: number = null;

    public entiteLibelle: string = null;

    public entiteActif: boolean = null;

    public idReferentielMetier: number = null;

    public nbrOfChilds: number = null;

    public nbrOfActeurs: number = null;

    public entiteChampsValues: EntiteChampValue[] = [];

    public entiteCaracteristiquesValues: EntiteCaracteristiqueValue[] = [];

    public libellePere: string = null;

    public actifPere: string = null;

    private participantsService = ParticipantsService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<Entite>>) {

        if (row) {

            const {
                idEntite,
                idPere,
                idReferentielNiveau,
                niveau,
                entiteLibelle,
                entiteActif,
                idReferentielMetier,
                nbrOfChilds,
                entiteChampsValues,
                entiteCaracteristiquesValues,
                nbrOfActeurs,
                libellePere,
                actifPere
            } = row;

            this.idEntite = idEntite;

            this.idEntiteAffichage = idEntite.substring(idEntite.indexOf("_") + 1);

            this.idPere = idPere;

            this.idReferentielNiveau = idReferentielNiveau;

            this.niveau = niveau;

            this.entiteLibelle = entiteLibelle;

            this.entiteActif = entiteActif;

            this.idReferentielMetier = idReferentielMetier;

            this.nbrOfChilds = nbrOfChilds;
            if(entiteChampsValues){
                this.entiteChampsValues = cloneDeep(entiteChampsValues).map(entiteChampsValue => new EntiteChampValue(entiteChampsValue));
            }

            if(entiteCaracteristiquesValues){
                this.entiteCaracteristiquesValues = cloneDeep(entiteCaracteristiquesValues)
                .map(entiteCaracteristiqueValue => new EntiteCaracteristiqueValue(entiteCaracteristiqueValue));
            }            

            this.nbrOfActeurs = nbrOfActeurs;

            this.libellePere = libellePere;

            this.actifPere = actifPere;

        }
    }

    public save(entiteChampValues: EntiteChampValue[] = [], entiteCaracteristiqueValues: EntiteCaracteristiqueValue[] = [], estModification: boolean = true): Promise<string> {
        return this.participantsService.saveEntity(this, entiteChampValues, entiteCaracteristiqueValues, estModification);
    }

    public delete(): Promise<Boolean> {
        return this.participantsService.deleteEntity(this.idEntite);
    }

    public toDatabaseObject(): object {
        return {
            id_entite: this.idEntite,
            id_pere: this.idPere,
            id_referentiel_niveau: this.idReferentielNiveau,
            niveau: this.niveau,
            entite_libelle: this.entiteLibelle,
            entite_actif: this.entiteActif,
            id_referentiel_metier: this.idReferentielMetier
        }
    }
}