import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AddSVG from '@material-ui/icons/AddBox';
import SaveSVG from '@material-ui/icons/Save';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Acteur } from 'classes/Acteur.class';
import EmptyListComponent from 'components/EmptyList.component';
import LigneHautTableau from 'components/LigneHautTableau.component';
import { Pacman } from 'components/pacman';
import { loadContacts, saveContacts } from 'features/carnet_voyage/src/store/actions';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from "lodash/isEqual";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dimensions, SortableContainer, SortEnd, SortEndHandler, SortEvent, SortStart } from 'react-sortable-hoc';
import { newSnackbar } from 'store/snackbars/snackBarsActions';
import { RootState } from 'store/types';
import { colors, greenTheme, Theme } from 'Theme';
import ContactsVideSVG from '../../../assets/informations_vide.svg';
import style from './ContactsSettingsCarnet.style';
import SortableContactComponent from './SortableContact.component';




interface SortableItemsListProps {
    contacts: Acteur[];
    class: string;
    removeItem: ((index: number) => void);
}

const stateToProps = ({ carnetVoyage: { isLoading, contacts } }: RootState) => ({
    contacts, isLoading
})

const dispatchToProps = {
    loadContacts: loadContacts.request,
    saveContacts: saveContacts.request,
    newSnackbar
}

interface ContactsSettingsCarnetLocalProps {
}

interface ContactsSettingsCarnetState {
    allowSave: boolean,
    stateContacts: Acteur[],
    openAddItem: boolean,
    alreadyLoaded: boolean
}

type ContactsSettingsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ContactsSettingsCarnetLocalProps;

class ContactsSettingsCarnet extends Component<ContactsSettingsCarnetProps, ContactsSettingsCarnetState> {
    private SortableItemsList = SortableContainer((props: SortableItemsListProps): JSX.Element => {
        const { contacts, removeItem } = props;
        return (
            <Box display='flex' flexDirection='column' flexWrap='wrap' width='100%' padding={4} paddingBottom={2}>
                {contacts.map((contact, index) =>
                    <SortableContactComponent
                        key={`contact-${contact.uid}`}
                        contact={contact}
                        class={props.class}
                        removeItem={() => removeItem(index)}
                        index={index}
                        updateParent={() => {
                            this.forceUpdate();
                            this.setState({
                                allowSave: true
                            })
                        }}
                    />
                )}
            </Box>
    
        )
    });


    private blueTheme = (theme: typeof Theme) => {
        // debugger;
        return createTheme({
            ...theme,
            palette:
            {
                primary: colors.blue
            },
            overrides: {
                MuiButton: {
                    containedPrimary: {
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: colors.blue.main,
                            boxShadow: 'none'
                        }
                    }
                }
            }
        });
    };


    /********** SORTABLE HOC METHODS *************/
    private readonly _onSortEnd: SortEndHandler = this._handleSortEnd.bind(this);

    private _handleSortEnd(sort: SortEnd, event: SortEvent): void {
        if (sort.newIndex >= this.state.stateContacts.length) {
            var k = sort.newIndex - this.state.stateContacts.length + 1;
            while (k--) {
                this.state.stateContacts.push(undefined);
            }
        }

        const newStateContacts = cloneDeep(this.state.stateContacts);

        newStateContacts.splice(sort.newIndex, 0, newStateContacts.splice(sort.oldIndex, 1)[0]);
        newStateContacts.forEach((item, index) => {
            item.sequence = index;
        });

        this.setState({
            stateContacts: newStateContacts
        })
    }

    private _getHelperDimensions(sort: SortStart): Dimensions {
        if (sort.node instanceof HTMLElement) {
            return ({
                width: sort.node.offsetWidth,
                height: sort.node.offsetHeight
            });
        }
        return { width: 0, height: 0 };
    }


    /************************/
    readonly state: ContactsSettingsCarnetState = {
        allowSave: false,
        stateContacts: [],
        openAddItem: false,
        alreadyLoaded: false
    }

    componentDidMount() {
        this.props.loadContacts();
    }

    componentDidUpdate(prevProps: ContactsSettingsCarnetProps, prevState: ContactsSettingsCarnetState) {
        const { loadContacts, contacts, isLoading } = this.props;
        const { stateContacts, alreadyLoaded, allowSave } = this.state;
        if (!isLoading) {
            if (!allowSave && !!prevState.stateContacts && Array.isArray(prevState.stateContacts) && prevState.stateContacts.length > 0 && !isEqual(prevState.stateContacts, stateContacts)) {
                this.setState({
                    allowSave: true
                })
            }

            if (stateContacts.length === 0 && stateContacts.length != contacts.length && !alreadyLoaded) {
                this.setState({
                    stateContacts: cloneDeep(contacts),
                    alreadyLoaded: true
                })
            }
        }
    }

    handleRemoveItem(index: number) {
        const newStateContacts = cloneDeep(this.state.stateContacts);

        const { stateContacts } = this.state;
        let sequenceItem = newStateContacts[index].sequence
        for (let i = index; i < newStateContacts.length; i++) {
            if (newStateContacts[i].sequence > sequenceItem) {
                newStateContacts[i].sequence--;
            }
        }

        newStateContacts.splice(index, 1);

        this.setState({
            stateContacts: newStateContacts
        });
    }

    saveAllContacts() {
        this.props.saveContacts(this.state.stateContacts);
        this.setState({
            allowSave: false
        })
    }

    render() {
        const { classes, contacts, isLoading } = this.props;
        const { stateContacts, openAddItem, allowSave } = this.state;

        if (isLoading) {
            return <Pacman />;
        }
        
        const componentsLigneHautContacts = [
            <ThemeProvider theme={greenTheme}>
                <Button
                    className={classes.buttonLaunch}
                    color='primary'
                    variant="contained"
                    size="large"
                    // disabled={isLoading}
                    onClick={() => this.saveAllContacts()}
                    disabled={!allowSave}
                >
                    <SaveSVG className={classes.imgLaunch} />
                    Enregistrer
            </Button>
            </ThemeProvider>


        ];
        const componentsLigneHautEditeur = [
            <ThemeProvider theme={this.blueTheme}>
                <Button
                    className={classes.buttonLaunch}
                    color='primary'
                    variant="contained"
                    size="large"
                    onClick={() => {
                        const newStateContacts = cloneDeep(this.state.stateContacts);

                        let acteur = new Acteur();
                        acteur.sequence = newStateContacts.length;
                        newStateContacts.push(acteur);

                        this.setState({
                            stateContacts: newStateContacts
                        })
                    }}
                >
                    <AddSVG className={classes.imgLaunch} />
                    Ajouter un accompagnateur
            </Button>
            </ThemeProvider>


        ];
        return (
            <Box>
                <LigneHautTableau className={classes.texteLigneHautContacts} components={componentsLigneHautContacts} title='Accompagnateurs' />
                <Paper elevation={0} className={classes.paperEdition}>
                    <Box display='flex' flexDirection='column'>
                        <Box padding={4} display='flex' flexDirection='row' height={130} width='100%' justifyContent='center' alignItems='center' color='white' bgcolor={colors.blue.dark}>
                            <LigneHautTableau className={classes.texteLigneHautEditeur} components={componentsLigneHautEditeur} title='Editeur' />
                        </Box>
                        <Box minHeight={600} display='flex'>
                            {
                                stateContacts.length > 0 ?
                                    <this.SortableItemsList
                                        contacts={stateContacts}
                                        removeItem={this.handleRemoveItem.bind(this)}
                                        class=''
                                        onSortEnd={this._onSortEnd}
                                        getHelperDimensions={this._getHelperDimensions}
                                        axis='y'
                                        useDragHandle />
                                    :
                                    <Box margin='auto'>
                                        <EmptyListComponent urlImage={ContactsVideSVG} title="C'est vide !" subtitle="Pour ajouter des Accompagnateurs, cliquez sur « Ajouter une rubrique » " />
                                    </Box>
                            }
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ContactsSettingsCarnet));