import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

/**
 * Les styles du composant Download App
 */
export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    paper: {
        textAlign: 'center',
        padding: spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    icon: {
        borderRadius: 5,
        backgroundColor: colors.lighterGrey.light,
        width: 50,
        height: 50,
        borderColor: colors.grey.light,
        borderWidth: 1,
        borderStyle: 'solid',
        marginRight: spacing(1)
    },
    subtitle: {
        textAlign: 'left'
    },
    button: {
        borderRadius: 50,
        minWidth: 100,

    },
    option: {
        display: 'flex',
        marginTop: spacing(1),
        justifyContent: 'space-between',
        maxWidth: 415,
        width: '100%'
    },
    title: {
        fontSize: '18px',
    }

}));