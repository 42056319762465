import { from, of } from 'rxjs';
import {
    filter, switchMap, catchError, map, tap
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic, RootAction } from 'store/types';
import rootAction from 'store/rootAction';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { replace } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';



const errorOnFailure: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter((action) => {
        const regex = RegExp('FAILURE$');
        return (regex.test(action.type) && typeof action.payload == 'string')
    }),
    // tap((action) => console.log(action.payload)),
    // take(1)
    switchMap((action) => {

        if (state$.value.error.backUpLink) {
            let location: LocationDescriptorObject = { pathname: state$.value.error.backUpLink };
            return of(newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Une erreur est survenue : ${action.payload}`,
                }
            }), replace(location));
        } else {
            return of(newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Une erreur est survenue : ${action.payload}`,
                }
            }))
        }
    })
);


const ErrorEpics = combineEpics(errorOnFailure);

export default ErrorEpics;
