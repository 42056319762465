import { ChallengeService } from "services";
import { ChallengeThemePredefiniImage } from "./ChallengeThemePredefiniImage.class";
import cloneDeep from 'lodash/cloneDeep'
import { ChallengeTheme } from "./ChallengeTheme.class";
import { DeepRawify } from "types";
import { DeepPartial } from "redux";

export class ChallengeThemePredefini extends ChallengeTheme {

    public idThemePredefini: number = null;

    public idGroupe: string = null;

    public libelle: string = null;

    public actif: number = null;

    public images: ChallengeThemePredefiniImage[]

    private ChallengeService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ChallengeThemePredefini>>) {
        super(row)
        if (row) {

            const {
                idThemePredefini,
                idGroupe,
                libelle,
                actif,
                images
            } = row;

            this.idThemePredefini = idThemePredefini;

            this.idGroupe = idGroupe;

            this.libelle = libelle;

            this.actif = actif

            if (images) {
                this.images = images.map((image) => new ChallengeThemePredefiniImage(image));
            }



        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.ChallengeThemeService.saveChallengeTheme(this);
    // }

    public toDatabaseObject(): object {
        return {
            idThemePredefini: this.idThemePredefini,
            idGroupe: this.idGroupe,
            libelle: this.libelle,
            actif: this.actif
        }
    }
}