import { Acteur } from "classes/Acteur.class";
import { DeepPartial } from "redux";
import { MursService } from "services";
import { DeepRawify } from "types";

export class ReponseSondage {

    public idMurReponseSondage: number = null;

    public idReferentielActeur: string = null;
    
    public acteur : Acteur = null;

    public idReferentielMetier: number = null;

    public idMurPost: number = null;

    public idReponse: number = null;

    public dateCreationReponse: string = null;

    public belleDateCreationReponse : string = null;

    public courteDateCreationReponse : string = null;

    public actif: number = null;



    constructor(reponseSondage?: DeepPartial<DeepRawify<ReponseSondage>>) {

        if (reponseSondage) {
            const {
                idMurReponseSondage,
                idReferentielActeur,
                acteur,
                idReferentielMetier,
                idMurPost,
                idReponse,
                dateCreationReponse,
                belleDateCreationReponse,
                courteDateCreationReponse,
                actif
            } = reponseSondage;

            this.idMurReponseSondage = idMurReponseSondage;
            this.idReferentielActeur = idReferentielActeur;
            this.acteur = new Acteur(acteur);
            this.idReferentielMetier = idReferentielMetier ;
            this.idMurPost = idMurPost;
            this.idReponse = idReponse;
            this.dateCreationReponse = dateCreationReponse;
            this.belleDateCreationReponse = belleDateCreationReponse;
            this.courteDateCreationReponse = courteDateCreationReponse;
            this.actif = actif;
        }
    }
}