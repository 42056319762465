import moment, { Moment } from 'moment';
import Axios from 'axios';
import qs from 'qs';
import { v4 } from 'uuid';
import { B64File } from 'classes/B64File.class';
import { CarnetParticipantActivity } from './CarnetParticipantActivity.class';
import { CarnetVoyageService } from 'services';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';
import axios from 'axios';

export class CarnetParticipant {
    private static readonly AVATAR_FOLDER: string = '/dist/assets/avatar/';

    public uid : string = v4();
    
    public idReferentielActeur: string;

    public nom: string;
    
    public prenom: string;

    public entite: string;

    public avatar: string;

    public fileAvatar : B64File = null;

    public role: string;

    public canal: string;

    public customAvatar : boolean;

    public activities : CarnetParticipantActivity[];

    private carnetVoyageService = CarnetVoyageService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<CarnetParticipant>>) {
        if (row) {
            const { idReferentielActeur, nom, prenom, avatar, entite, role, canal, customAvatar, activities } = row;
            this.idReferentielActeur = idReferentielActeur;
            this.nom = nom;
            this.prenom = prenom;
            this.customAvatar = customAvatar;
            if(customAvatar){
                //TODO : C'est dégueulasse

                this.fileAvatar = B64File.fromDb(avatar);
                this.avatar = this.fileAvatar.getSrc();
            }else{
                this.avatar = axios.defaults.baseURL + `/dist/assets/avatar/${avatar}.svg`;
                this.fileAvatar = null;
            }
            this.canal = canal;
            this.entite = entite;
            this.role = role;
            if (activities) {
                this.activities = activities.map((activity) => new CarnetParticipantActivity(activity));
            }
        }
    }

    public getAvatarUrl(): string {
        return this.avatar;
    }

    public toRaw() {
        return {
            idReferentielActeur: this.idReferentielActeur,
            nom: this.nom,
            prenom: this.prenom,
            avatar: this.avatar,
            entite: this.entite,
            role: this.role,
        };
    }

    public async delete(): Promise<boolean> {
        return this.carnetVoyageService.deleteParticipant(this);
    }
}
