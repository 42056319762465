import cloneDeep from 'lodash/cloneDeep'
import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import { ChallengeThemeImage } from './ChallengeThemeImage.class'
import { ChallengeThemePersonnaliseImage } from './ChallengeThemePersonnaliseImage.class'
import { ChallengeThemePredefiniImage } from './ChallengeThemePredefiniImage.class'
export abstract class ChallengeTheme {


    public libelle: string = null;

    public actif: number = null;

    public images: ChallengeThemeImage[]

    public getImageCouv(): ChallengeThemeImage {
        return this.images.filter((image) => image.type == 1)[0];
    }

    constructor(row?: DeepPartial<DeepRawify<ChallengeTheme>>) {
        if (row) {

            const {
                libelle,
                actif,
                images
            } = row;

            this.libelle = libelle;

            this.actif = actif

            this.images = cloneDeep(images).map(image =>
                'idThemePersonnalise' in image ? new ChallengeThemePersonnaliseImage(image) : new ChallengeThemePredefiniImage(image)
            )



        }
    }

}