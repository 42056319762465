import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import EmailIcon from "@material-ui/icons/Email"
import LanguageIcon from "@material-ui/icons/Language"
import LocalPhoneIcon from "@material-ui/icons/LocalPhone"
import PlaceIcon from "@material-ui/icons/Place"
import WatchLaterIcon from "@material-ui/icons/WatchLater"
import Rating from '@material-ui/lab/Rating'
import { Hotel } from "classes/carnet_voyage/hotel/Hotel.class"
import { Pacman } from "components/pacman"
import { HotelBooleanField } from "features/carnet_voyage/src/store/types"
import React, { Component } from "react"
import ImageGallery from "react-image-gallery"
import { connect } from "react-redux"
import { RootState } from "store/types"
import { loadHotel } from "../store/actions"
import FacebookIcon from "./facebook.svg"
import style from "./HotelCarnet.style"

/*
import MarkerIcon from "./marker-editor.png";
import ReactMapGL, { Marker } from 'react-map-gl';
// @ts-ignore (pas de @types pour cette lib)
import Geocoder from 'react-mapbox-gl-geocoder';

const mapAccess = {
    mapboxApiAccessToken: 'pk.eyJ1IjoiZm9ydGVzdGluZ3B1cnBvc2UiLCJhIjoiY2tkNG9xcjV5MDJldzJ3bnl4M2Z5d2NyNyJ9.8Hl5dIv8FSc2STHQFcXn8g'
}*/

const stateToProps = ({ carnetVoyage: { hotel, isLoading } }: RootState) => ({
    hotel,
    isLoading
});

const dispatchToProps = {
    loadHotel: loadHotel.request
};

interface HotelCarnetLocalProps { }

interface HotelCarnetState { /*viewport: any, markerLocation: any*/ }

type HotelCarnetProps = WithStyles<typeof style> & ReturnType<typeof stateToProps> & typeof dispatchToProps & HotelCarnetLocalProps;

let images: { original: string }[];

class HotelCarnet extends Component<HotelCarnetProps, HotelCarnetState> {
    constructor(props: HotelCarnetProps) {
        super(props);
        /*this.state = {
            viewport: {
                "width": "100%",
                "height": 500,
                "latitude": 0,
                "longitude": 0,
                "zoom": 0,
                "bearing": 0,
                "pitch": 0,
                "altitude": 1.5,
                "maxZoom": 24,
                "minZoom": 0,
                "maxPitch": 60,
                "minPitch": 0,
                "transitionDuration": 0,
                "transitionInterpolator": {
                    "propNames": [
                        "longitude",
                        "latitude",
                        "zoom",
                        "bearing",
                        "pitch"
                    ]
                },
                "transitionInterruption": 1
            },
            markerLocation: {

                "latitude": 0,
                "longitude": 0,
                "zoom": 0,
                "bearing": 0,
                "pitch": 0,
                "altitude": 1.5,
                "maxZoom": 24,
                "minZoom": 0,
                "maxPitch": 60,
                "minPitch": 0,
                "transitionDuration": 0,
                "transitionInterpolator": {
                    "propNames": [
                        "longitude",
                        "latitude",
                        "zoom",
                        "bearing",
                        "pitch"
                    ]
                },
                "transitionInterruption": 1
            }
        }*/
    }

    componentDidMount() {
        this.props.loadHotel();
    }

    generateEquipment(field: HotelBooleanField) {
        const { classes, hotel } = this.props;
        let Icon = field.icon;
        return (
            <Tooltip title={field.tooltip} placement="top">
                <Box className={classes.eachEquipment}>
                    {
                        field.materialIcon ?
                            <Icon />
                            :
                            <img className={classes.imgEquipmentIcon} src={field.greyIcon} />

                    }
                </Box>
            </Tooltip>
        );
    }

    /*onSelected = (viewport: any, item: any) => {
        this.setState({ viewport: viewport, markerLocation: viewport });

    }*/

    render() {
        const { classes, hotel } = this.props;
        /*const { viewport, markerLocation } = this.state;*/

        if (!hotel) {
            return <Pacman />;
        }

        if (!images) {
            images = [];
            hotel.image1 ? images.push({ original: hotel.image1.getSrc() }) : false;
            hotel.image2 ? images.push({ original: hotel.image2.getSrc() }) : false;
            hotel.image3 ? images.push({ original: hotel.image3.getSrc() }) : false;
            hotel.image4 ? images.push({ original: hotel.image4.getSrc() }) : false;
            hotel.image5 ? images.push({ original: hotel.image5.getSrc() }) : false;
            hotel.image6 ? images.push({ original: hotel.image6.getSrc() }) : false;
        }

        return (
            <Box display="flex" flexDirection="column">
                <Box className={classes.carousel}>
                    <ImageGallery
                        items={images}
                        showFullscreenButton={false}
                        showThumbnails={false}
                        showPlayButton={false}
                        showNav={false}
                        showIndex={false}
                        showBullets
                        autoPlay
                    />
                </Box>
                <Paper elevation={0} className={classes.topSection + " " + classes.section}>
                    <p className={classes.title}>{hotel.name}</p>
                    <Box display="flex">
                        <Rating value={hotel.stars} max={hotel.stars} readOnly size="small" />
                    </Box>
                    <p className={classes.description}>{hotel.description}</p>
                </Paper>
                <Paper elevation={0} className={classes.section}>
                    <p className={classes.title}>Accès et coordonnées</p>
                    {hotel.location ? (
                        <Box className={classes.eachInfo} display="flex">
                            <PlaceIcon className={classes.infoIcon} />
                            <p className={classes.infoText}>{hotel.location}</p>
                        </Box>
                    ) : (
                            false
                        )}
                    {hotel.arrivingTime ? (
                        <Box className={classes.eachInfo} display="flex">
                            <WatchLaterIcon className={classes.infoIcon} />
                            <p className={classes.infoText}>{hotel.arrivingTime}</p>
                        </Box>
                    ) : (
                            false
                        )}
                    {hotel.phone ? (
                        <Box className={classes.eachInfo} display="flex">
                            <LocalPhoneIcon className={classes.infoIcon} />
                            <p className={classes.infoText}>{hotel.phone}</p>
                        </Box>
                    ) : (
                            false
                        )}
                    {hotel.mail ? (
                        <Box className={classes.eachInfo} display="flex">
                            <EmailIcon className={classes.infoIcon} />
                            <p className={classes.infoText}>{hotel.mail}</p>
                        </Box>
                    ) : (
                            false
                        )}
                    {hotel.website ? (
                        <Box className={classes.eachInfo} display="flex">
                            <LanguageIcon className={classes.infoIcon} />
                            <p className={classes.infoText}>{hotel.website}</p>
                        </Box>
                    ) : (
                            false
                        )}
                    {hotel.facebook ? (
                        <Box className={classes.eachInfo} display="flex">
                            <img className={classes.infoIcon} src={FacebookIcon} />
                            <p className={classes.infoText}>{hotel.facebook}</p>
                        </Box>
                    ) : (
                            false
                        )}
                </Paper>
               {/* <Paper elevation={0} className={classes.section}>
                    <Geocoder
                        initialInputValue={hotel.location}
                        {...mapAccess} onSelected={this.onSelected} viewport={viewport} hideOnSelect={true}
                    />
                    <ReactMapGL
                        attributionControl={false}
                        mapStyle="mapbox://styles/mapbox/streets-v11"
                        {...mapAccess} {...viewport}
                        onViewportChange={(newViewport) => this.setState({
                            viewport: newViewport
                        })}
                    >
                        <Marker
                            longitude={markerLocation.longitude}
                            latitude={markerLocation.latitude}
                        >
                            <img src={MarkerIcon} style={{ width: 20, height: 20, pointerEvents: "none", transform: "translate(-50%, -100%) scale(1, 1.5)" }} />
                        </Marker>
                    </ReactMapGL>
                    </Paper>*/}
                <Paper elevation={0} className={classes.section}>
                    <p className={classes.title}>Equipements</p>
                    <Box className={classes.equipments}>
                        {Hotel.boolFields.map((field, index) =>
                            hotel[field.field] ? <div key={index}>{this.generateEquipment(field)}</div> : false
                        )}
                    </Box>
                </Paper>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(HotelCarnet));
