import LikePleinSVG from 'assets/typesLikes/like-plein2-stroke.svg'
import ApplaudirPleinSVG from 'assets/typesLikes/applaudir-plein-stroke.svg'
import MdrPleinSVG from 'assets/typesLikes/mdr-plein-stroke.svg'
import EtonnementPleinSVG from 'assets/typesLikes/etonnement-plein-stroke.svg'
import EnervePleinSVG from 'assets/typesLikes/enerve-plein-stroke.svg'
import { MursService } from 'services'
import { Acteur } from 'classes/Acteur.class'
import { DeepRawify } from 'types'
import { DeepPartial } from 'redux'

export class Like {
    public static readonly TYPES_LIKES: { [key: number]: string } = {
        1: LikePleinSVG,
        2: ApplaudirPleinSVG,
        3: MdrPleinSVG,
        4: EtonnementPleinSVG,
        5: EnervePleinSVG,
    };

    public idMurLike: number;

    public idReferentielActeur: number;

    public acteur: Acteur;

    public idReferentielMetier: number;

    public idMurPost: number;

    public idCommentaire: number;

    public longueDateCreationLike: string;

    public actif: number;

    public idGroupeLike: number;

    private murService = MursService.getInstance();

    constructor(comment?: DeepPartial<DeepRawify<Like>>) {

        if (comment) {
            const {
                idMurLike,
                idReferentielActeur,
                acteur,
                idReferentielMetier,
                idMurPost,
                idCommentaire,
                longueDateCreationLike,
                actif,
                idGroupeLike
            } = comment;

            this.idMurLike = idMurLike;
            this.idReferentielActeur = idReferentielActeur;
            this.acteur = new Acteur(acteur);
            this.idReferentielMetier = idReferentielMetier;
            this.idMurPost = idMurPost;
            this.idCommentaire = idCommentaire;
            this.longueDateCreationLike = longueDateCreationLike;
            this.actif = actif;
            this.idGroupeLike = idGroupeLike;
        }
    }

}