import Box from '@material-ui/core/Box'
import { createTheme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Theme } from 'Theme'
import 'moment/locale/fr'
import React, { useEffect, useState } from "react"

interface ChoiceDialogProps {
    triggered: boolean;
    choices: { title: string, icon: string, onClick: () => void }[];
    onClose: () => void;
    dialogTitle?: string;
}

const ChoiceDialog: React.FC<ChoiceDialogProps> = (props: ChoiceDialogProps) => {
    const { triggered, choices, onClose, dialogTitle } = props;

    const datePickerTheme = (mainTheme: typeof Theme) => createTheme({
        props: mainTheme.props,
        palette: mainTheme.palette
    });

    const [open, setOpen] = useState(false)
    const [dateValue, setDateValue] = useState(null)

    useEffect(() => {
        if (triggered && !open) {
            setOpen(true);
        }
    }, [triggered, open])

    function close() {
        onClose();
        setOpen(false);
    }

    function getChoiceComponents() {
        const choicesComponent: JSX.Element[] = [];

        choices.forEach((choice) => {
            choicesComponent.push(
                <Box key={choice.title} style={{ cursor: "pointer" }} onClick={() => { choice.onClick(); close() }} margin="5% 8%" width="25%" display="flex" flexDirection="column" >
                    <img src={choice.icon} />
                    <Box color="dimgray" marginTop="30px">{choice.title}</Box>
                </Box>
            );
        });

        return <Box maxWidth="400px" width="100vw" justifyContent="center" display="flex" flexShrink="0" flexWrap="wrap">{choicesComponent}</Box>;
    }

    return (
        <Dialog open={open} onClose={() => close()}>
            <DialogTitle>
                {dialogTitle}
            </DialogTitle>
            <DialogActions>
                {getChoiceComponents()}
            </DialogActions>
        </Dialog>);
}

export default ChoiceDialog;