import { LevelUpService } from 'services';
import { Fiche } from '../fiches/Fiche.class';

export interface BriqueQuizFiche {
    idBriqueQuizFiche?: number,
    idBrique?: number,
    idFiche: number,
    nbAleatoire: number,
    nbMax: number,
    obligatoire: number[],
    fiche?: Fiche
}

/**
 * Brique d'une animation Level Up
 */
export class Brique {

    /**
     * L'identifiant de la brique
     */
    public idBrique: number;

    /**
     * Identifiant de l'animation associée
     */
    public idAnimation: number;

    /**
     * Titre de la brique
     */
    public titre: string;

    /**
     * Date de début de la brique
     */
    public dateDebut: number;

    /**
     * Date de fin de la brique
     */
    public dateFin: number;

    /**
     * Statut d'activation de la brique
     */
    public enLigne: number;

    /**
     * Type de la brique (1 pour Quiz)
     */
    public type: number;

    /**
     * Date de mise à jour de la brique
     */
    public dateMiseAJour: number;

    /**
     * Date de création de la brique
     */
    public dateCreation: number;

    /**
     * Par combien on additionne/multiplie
     */
    public coefficientPoint: number;

    /**
     * 1:multiplication , 2:addition
     */
    public typeCoefficientPoint: number;

    /**
     * Liste des types utilisés (quiz/expert)
     */
    public typeQuestion: number[];

    /**
     * Liste des fiches sélectionnées
     */
    public listeFiches: BriqueQuizFiche[];

    /**
     * Est-ce que la notification pour annoncer la création de l'animation a déjà été envoyé ou non
     */
    public notificationNewEnvoyee: number;

    /**
     * Constructeur de la brique
     */
    constructor(row?: any)
    {
        if (row) {
            const {
                idBrique,
                idAnimation,
                titre,
                dateDebut,
                dateFin,
                enLigne,
                type,
                dateMiseAJour,
                typeQuestion,
                listeFiches,
                typeCoefficientPoint,
                coefficientPoint,
                dateCreation,
                notificationNewEnvoyee
            } = row;

            this.idBrique = idBrique;
            this.idAnimation = idAnimation;
            this.titre = titre;
            this.dateDebut = dateDebut;
            this.dateFin = dateFin;
            this.enLigne = enLigne;
            this.type = type;
            this.dateMiseAJour = dateMiseAJour;
            this.typeQuestion = typeQuestion ? typeQuestion.split(',').map(Number) : [];
            this.listeFiches = listeFiches ?? [];
            this.typeCoefficientPoint = typeCoefficientPoint;
            this.coefficientPoint = coefficientPoint;
            this.dateCreation = dateCreation;
            this.notificationNewEnvoyee = notificationNewEnvoyee;
        } else {
            this.listeFiches = []
            this.typeQuestion = []
        }
    }

    public toRaw() {
        return {
            idBrique: this.idBrique,
            titre: this.titre,
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            enLigne: this.enLigne,
            idAnimation: this.idAnimation,
            type: this.type,
            dateMiseAJour: this.dateMiseAJour,
            typeQuestion: this.typeQuestion.join(','),
            listeFiches: this.listeFiches,
            typeCoefficientPoint: this.typeCoefficientPoint,
            coefficientPoint: this.coefficientPoint,
            dateCreation: this.dateCreation,
            notificationNewEnvoyee: this.notificationNewEnvoyee,
        };
    }

    private levelUpService = LevelUpService.getInstance();

    public delete() {
        return this.levelUpService.deleteBrique(this.idBrique);
    }

    public save() {
        return this.levelUpService.saveBrique(this);
    }

}



    
    
    
    
    
    
    
    