import { DeepPartial } from "redux";
import { CanauxService } from "services";
import { DeepRawify } from "types";

export class Role {


    public static typeLibelle : { [key : number ] : string } =
    {
        1 : 'Admin',
        2 : 'Hiérachique',
        3 : 'Fonctionnel',
        4 : 'Transverse',
    } 

    public idReferentielRole: number = null;

    public idReferentielNiveau: number = null;

    public libelle: string = null;

    public droit: { [key : string ] : number[]};

    public idReferentielMetier: number = null;

    public genreLibelle: number = null;

    public idRoleChallenge: number = null;

    public idType: number = null;

    public actif: boolean = null;

    private canauxService = CanauxService.getInstance();
    
    constructor(row?: DeepPartial<DeepRawify<Role>>) {

        if (row) {
            const {
                idReferentielRole,
                idReferentielNiveau,
                libelle,
                droit,
                idReferentielMetier,
                genreLibelle,
                idRoleChallenge,
                idType,
                actif,
            } = row;

            this.idReferentielRole = idReferentielRole;

            this.idReferentielNiveau = idReferentielNiveau;

            this.libelle = libelle;

            this.droit = droit;

            this.idReferentielMetier = idReferentielMetier;

            this.idType = idType;

            this.genreLibelle = genreLibelle;

            this.idRoleChallenge = idRoleChallenge;

            this.actif = !!actif;

        }
    }

    public save: () => Promise<boolean> = () => {
        return this.canauxService.saveRole(this);
    }
    // public save: () => Promise<boolean> = () => {
    // return true;
    // return this.canauxService.saveNiveau(this);
    // }

    public delete: () => Promise<boolean> = () => {
    return this.canauxService.deleteRole(this.idReferentielRole);
    }

    public toDatabaseObject(): object {
        return {
            id_referentiel_role: this.idReferentielRole,
            id_referentiel_niveau: this.idReferentielNiveau,
            libelle: this.libelle,
            droit: this.droit,
            id_referentiel_metier: this.idReferentielMetier,
            genre_libelle: this.genreLibelle,
            id_role_challenge: this.idRoleChallenge,
            id_type: this.idType,
            actif: this.actif,
        }
    }


}