import { combineEpics } from 'redux-observable';
import { from, of, iif, empty, EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, tap, take } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadCanauxAsync, loadCanalAsync, loadNiveauxAsync, loadNiveauAsync } from './actions';
import { push } from 'connected-react-router';
import NiveauxEpics from '../niveaux/src/store/epics'


const loadCanauxEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadCanauxAsync.request)),
    switchMap(() => from(canauxService.loadCanaux()).pipe(
        map(loadCanauxAsync.success),
        catchError(message => of(loadCanauxAsync.failure(message))),
    )),
);

const loadCanalEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadCanalAsync.request)),
    switchMap(action => from(canauxService.loadCanal(action.payload)).pipe(
        map(loadCanalAsync.success),
        catchError((message) => of(loadCanalAsync.failure(message)))
    )),
);

const loadCanalOnRouted: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter((action) => action.type == '@@router/LOCATION_CHANGE'),
    switchMap((action => {

        let results: string[] = null;
        let canal = state$.value.parametres.canaux.canal;
        const { pathname } = action.payload.location;
        let myRe = /.*\/parametres\/canaux\/([0-9])+\/.*/
        results = pathname.match(myRe);
        return (!state$.value.parametres.canaux.isLoading && results && (!canal || canal.idReferentielMetier != (+results[1])))
            ? of(loadCanalAsync.request(+results[1]))
            : EMPTY;
        // return iif(() => Boolean(results && (!canal || canal.idReferentielMetier != (+results[0]))), of(loadCanalAsync.request(+results[0])), empty())
    }))

);



const CanauxEpics = combineEpics(
    loadCanauxEpics,
    loadCanalEpics,
    // loadCanalOnRouted,
    NiveauxEpics
);

export default CanauxEpics;
