import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    buttonLaunch : {
        borderRadius : 5
    },
    imgLaunch : {
        marginRight : spacing(0.5)
    },
    texteLigneHautEditeur : {
        color : 'white',
        fontWeight : 700
    },
    texteLigneHautContacts : {
        fontWeight : 700
    },
    paperEdition : {
        marginTop : spacing(0.5)
    },
    paperWave : {
        display : 'flex',
        padding : spacing(2),
        marginBottom : spacing(1),
        alignItems : 'center'
    },
    paperContact : {
        display : 'flex',
        flexDirection : 'column',
        borderRadius : 5,
        marginBottom : spacing(1)
    },
    mailImageLink : {
        color : 'white',
        backgroundColor : colors.blue.main,
        borderRadius : '50%',
        height : 44,
        width  : 44,
        display : 'flex'
    },
    phoneImageLink : {
        color : 'white',
        backgroundColor : colors.green.main,
        borderRadius : '50%',
        height : 44,
        width  : 44,
        display : 'flex'
    },
    phoneMailImage:  {
        color : 'white',
        margin : 'auto'
    },
    phoneMailTitle : {
        fontSize : 14
    },
    phoneMailSubtitle : {
        fontSize : 12,
        color : colors.grey.main

    },
    imgWave : {
        marginRight : spacing(2),
        width : 60,
        height : 60
    },
    nameText: {
        color: colors.white.main
    },
    roleText : {
        color : colors.lighterGrey.light
    },
    textWave : {
        fontSize : 14
    }
});