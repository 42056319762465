import { createStyles } from "@material-ui/styles";

const styles = () => createStyles({
    root: {
    },

    image: {},

    normal: {},
    border : {},
    front: {
        '&$image': {
            height: 44,
            width: 44
        }
    },
    medium: {
        '&$image': {
            height: 55,
            width: 55
        }
    },
    large: {
        '&$image': {
            height: 100,
            width: 100
        }
    },
    none : {
        border : 0
    },
    thin : {
        border: `2px solid white`,
    },
    thick : {
        border : `5px solid white`
    }

});

export default styles;
