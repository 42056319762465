import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    list : {
        width : 500
    },
    dialogTitle : {
        fontSize : 24,
        fontWeight : 700
    },
    listItemText : {
        fontWeight : 700
    }
});