import { Notifiable } from "./Notifiable.class";

/**
 * Cette classe permet d'envoyer une notification à 
 * tous les acteurs de tous les référentiels, en étant
 * compatible avec Notifiable.
 */
export class FAQ extends Notifiable {

    private link: string;

    public constructor(link: string) {
        super();

        this.link = link;
    }
    public getUrl(): string {
        return this.link;
    }

    public getID(): number {
        return 0;
    }

    public notifType = 10;
}