import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
    searchPersonne,
    loadMessagerie,
    loadGroupe,
    loadPersonne,
    sendMessageGroup,
    sendMessagePersonne,
    setConversationRead,
    setGroupRead,
    loadJWTAsync
} from './actions';

const searchPersonneEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(searchPersonne.request)),
    switchMap((action) => from(messagerieService.search(action.payload)).pipe(
        map(searchPersonne.success),
        catchError(message => of(searchPersonne.failure(message)))
    ))
);

const loadMessagerieEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(loadMessagerie.request)),
    switchMap((action) => from(messagerieService.loadMessagerie()).pipe(
        map(loadMessagerie.success),
        catchError(message => of(loadMessagerie.failure(message)))
    ))
);

const loadPersonneEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(loadPersonne.request)),
    switchMap((action) => from(messagerieService.loadPersonne(action.payload)).pipe(
        map(loadPersonne.success),
        catchError(message => of(loadPersonne.failure(message)))
    ))
);

const loadGroupeEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(loadGroupe.request)),
    switchMap((action) => from(messagerieService.loadGroupe(action.payload)).pipe(
        map(loadGroupe.success),
        catchError(message => of(loadGroupe.failure(message)))
    ))
);

const sendMessageGroupEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(sendMessageGroup.request)),
    switchMap((action) => from(messagerieService.sendMessageGroupe(action.payload)).pipe(
        map(sendMessageGroup.success),
        catchError(message => of(sendMessageGroup.failure(message)))
    ))
);

const sendMessagePersonneEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(sendMessagePersonne.request)),
    switchMap((action) => from(messagerieService.sendMessagePersonne(action.payload)).pipe(
        map(sendMessagePersonne.success),
        catchError(message => of(sendMessagePersonne.failure(message)))
    ))
);

const setConversationReadEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(setConversationRead.request)),
    switchMap((action) => from(messagerieService.setConversationRead(action.payload, false)).pipe(
        map(setConversationRead.success),
        catchError(message => of(setConversationRead.failure(message)))
    ))
);

const setGroupReadEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(setGroupRead.request)),
    switchMap((action) => from(messagerieService.setConversationRead(action.payload, true)).pipe(
        map(setGroupRead.success),
        catchError(message => of(setGroupRead.failure(message)))
    ))
);

const loadJWTEpics: RootEpic = (action$, state$, { messagerieService }) => action$.pipe(
    filter(isActionOf(loadJWTAsync.request)),
    switchMap((action) => from(messagerieService.loadJWT()).pipe(
        map(loadJWTAsync.success),
        catchError(message => of(loadJWTAsync.failure(message)))
    ))
);

const MessagerieEpics = combineEpics(
    searchPersonneEpics,
    loadMessagerieEpics,
    loadPersonneEpics,
    loadGroupeEpics,
    sendMessageGroupEpics,
    sendMessagePersonneEpics,
    setConversationReadEpics,
    setGroupReadEpics,
    loadJWTEpics
);

export default MessagerieEpics;
