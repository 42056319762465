import React, { useState, useEffect } from 'react';
import useStyle from './DownloadAppDrawer.style';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import PublicIcon from '@material-ui/icons/Public';
import IconButton from '@material-ui/core/IconButton';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'store/rootStore';
import { Capacitor } from '@capacitor/core';

/**
 * Composant pour choisir entre télécharger l'app et continuer sur le site mobile
 */
interface DownloadAppDrawerParams {
}

const DownloadAppDrawer : React.FC<DownloadAppDrawerParams> = props => {
    const classes = useStyle();

    const [open, setOpen] = useState<boolean>(localStorage.getItem("hideDlPopup") != "1");
    const [openSecond, setOpenSecond] = useState<boolean>(true);

    const [theme] = useSelector((state) => [
        state.theme.theme
    ], isEqual);

    useEffect(() => {
        setOpen(localStorage.getItem("hideDlPopup") != "1");
    }, [localStorage.getItem("hideDlPopup")])

    const hideDrawer = () => {
        localStorage.setItem("hideDlPopup", "1");
        setOpen(false);
    }

    const getLink = () => {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return theme.lien_app_store;
        } else {
            return theme.lien_play_store;
        }
    }

    if (!theme || !(/Android|iPhone/i.test(navigator.userAgent))) {
        return <></>
    }

    return (
        <>
        <Drawer
            anchor="bottom"
            open={open && !Capacitor.isNativePlatform()}
            PaperProps={{ className: classes.paper }}>
                <Typography variant='h6' className={classes.title}>Profite du meilleur de TeamUp avec l'application.</Typography>

                <Box className={classes.option}>
                    <Button variant="contained" className={classes.button} onClick={hideDrawer}>Plus tard</Button>
                    <Button variant="contained" color="primary" className={classes.button} href={getLink()}>Utiliser l'application</Button>
                </Box>
        </Drawer>

        <Drawer
            anchor="bottom"
            open={!Capacitor.isNativePlatform() && !open && openSecond}
            PaperProps={{ className: classes.paper }}
            variant="persistent">
                <Box display='flex' alignItems='center'>
                    <Typography>Ouvrir dans l'application</Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.button} 
                        href={getLink()}
                        style={{ margin: "0 10px" }}
                        size='small'>Ouvrir</Button>
                    <IconButton color="inherit" size="small" onClick={() => {
                        setOpenSecond(false)
                    }}>
                        <Close/>
                    </IconButton>
                </Box>
        </Drawer>

        </>
    );
}

export default DownloadAppDrawer;