import Axios from 'axios';
import { combineEpics } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
preUploadVideo, setProgressPreUploadedVideo, resetPreUploadVideo
} from './actions';

const preUploadVideoEpic: RootEpic = (action$, state$, { videoService }) =>
    action$.pipe(
        filter(isActionOf(preUploadVideo.request)),
        mergeMap((action =>
            of(action).pipe(
                map((action) => videoService.preUploadVideo(action.payload)),
                switchMap(({ progress, response }) =>
                    merge(
                        progress.pipe(
                            map(setProgressPreUploadedVideo),
                            takeUntil(action$.pipe(
                                filter(isActionOf(preUploadVideo.cancel)),
                                tap(() => videoService.cancelPreUploadVideo()),
                            ))
                        ),
                        response.pipe(
                            mergeMap(({ content }) => [newSnackbar({
                                type: SnackbarType.SUCCESS,
                                props: {
                                    open: true,
                                    autoHideDuration: 5000,
                                    message: `Votre vidéo a bien été reçue.`,
                                }
                            }), preUploadVideo.success(content)]),
                        ),
                    )
                )
            )
        )),
        catchError((error, caught) => {
            if (!Axios.isCancel(error)) {
                newSnackbar({
                    type: SnackbarType.WARNING,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Votre vidéo n'a pas pu être envoyée.`,
                    }
                });
            }
            return merge(of(preUploadVideo.failure(error)), caught)
        })
    )

const VideoEpics = combineEpics(
    preUploadVideoEpic
);

export default VideoEpics;
