import { createTheme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export const theme = (mainTheme: typeof Theme) =>
    createTheme({
        ...mainTheme,
        palette: {
            primary: mainTheme.palette.primary,
            secondary: colors.blue,
            error: colors.purple
        },
        overrides: {
            ...mainTheme.overrides,
            MuiLinearProgress: {
                colorPrimary: {
                    backgroundColor: "#eeeeee;"
                },
                barColorPrimary: {
                    backgroundColor: colors.green.main,
                    borderRadius: 50
                }
            }
        }
    });
export const style = ({ spacing, palette }: typeof Theme) =>
    createStyles({
        root: {
            padding: "20px",
            display: "flex",
            alignItems: "center",
            maxWidth: "100%",
            height: "56px",
            border: `solid 1px ${colors.lighterGrey.light}`,
            borderRadius: "5px",
            marginBottom: "10px",
            justifyContent: "space-between",
            backgroundColor: "white",
        },
        iconAndName: {
            display: "flex",
            width:"100%",
            minWidth: 0,
            alignItems: "center"
        },
        fileIcon: {
            marginRight: spacing(1),
            //marginTop: spacing(1),
            width: 24,
            height: 24,
        },
        fileIconAdmin: {
            marginRight: spacing(1),
            width: 24,
            height: 24,
        },
        fileName: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
            marginRight: spacing(1),
            fontSize: 14
        },
        defaultButton: {
            padding: 0,
            "&:hover": {
                background: "transparent"
            },
            minWidth: 0,
        },
        defaultButtonText: {
            padding: 0,
            "&:hover": {
                background: "transparent",
                color: "black"
            },
            "&:focus": {
                color: "black"
            },
            minWidth: 0,
        },
        progressBar: {
            width: 200,
            height: 16,
            borderRadius: 50,
            marginRight: spacing(3)
        },
        icon: {
            color: colors.grey.main
        }
    });

export default style;
