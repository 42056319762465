import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid';

/**
 * Groupe de questions d'une fiche
 */
export class FicheQuestionGroupe {

    public uid: string = v4();

    /**
     * Identifiant du groupe de questions
     */
    public idGroupe: number;

    /**
     * Libellé du groupe de questions
     */
    public title: string;

    /**
     * La réponse est-elle correcte
     */
    public active: boolean;

    private levelUpService = LevelUpService.getInstance();

    /**
     * Constructeur d'un objet Fiche Question Groupe
     */
    constructor(row?: DeepPartial<DeepRawify<FicheQuestionGroupe>>) {
        if (row) {
            const { idGroupe, title, active } = row;

            this.idGroupe = idGroupe ?? null;
            this.title = title ?? null;
            this.active = active ?? null;
        }
    }

    /**
     * Méthode de renvoi de l'objet en back sous la forme d'un tableau
     */
    public toRaw() {
        return {
            idGroupe: this.idGroupe,
            title: this.title,
            active: this.active
        };
    }

    /**
     * Sauvegardu groupe de questions en back
     */
    public save() {
        return this.levelUpService.saveQuestionGroupe(this);
    }

    /**
     * Suppression du groupe de questions en back
     */
    public delete() {
        return this.levelUpService.deleteQuestionGroupe(this.idGroupe);
    }

}
