import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';

/**
 * Question
 * Question d'une section
 */
export class Question {
    /**
     * id de la question
     */
    public idQuestion: number;

    /**
     * id de la section d'origine
     */
    public idSection: number;

    /**
     * intitulé de la question
     */
    public nomQuestion: string;

    /**
     * Réponse à la question
     */
    public nomReponse: string;

    /**
     * Constructeur de la question
     * @param questionObj à construire
     */
    constructor(questionObj?: DeepPartial<DeepRawify<Question>>) {
      if (questionObj) {
        const {
          idQuestion,
          idSection,
          nomQuestion,
          nomReponse,
        } = questionObj;
        this.idQuestion = idQuestion;
        this.idSection = idSection;
        this.nomQuestion = nomQuestion;
        this.nomReponse = nomReponse;
      }
    }
}
