import { createAction, createAsyncAction } from 'typesafe-actions';
import { B64File } from 'classes/B64File.class';

export const preUploadVideo = createAsyncAction('PRE_UPLOAD_VIDEO_REQUEST', 'PRE_UPLOAD_VIDEO_SUCCESS', "PRE_UPLOAD_VIDEO_FAILURE", "PRE_UPLOAD_VIDEO_CANCEL")<
    File,
    B64File,
    string,
    void>();

export const resetPreUploadVideo = createAction('RESET_PRE_UPLOADED_VIDEO')<void>();

export const setProgressPreUploadedVideo = createAction('SET_PROGRESS_PRE_UPLOADEDED_VIDEO')<number>();


