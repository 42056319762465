import { combineEpics } from 'redux-observable';
import * as levelUpParametres from 'features/level_up/src/parametres/store/epics'
import * as levelUpFiches from 'features/level_up/src/fiches/store/epics'
import * as levelUpQuiz from 'features/level_up/src/quiz/store/epics'
import * as levelUpStatistiques from 'features/admin/level_up/src/statistiques/store/epics'
import * as levelUpAnimations from 'features/level_up/src/animations/store/epics'
import * as levelUpBattles from 'features/level_up/src/battles/store/epics'

// Pour ne pas avoir des fichiers à rallonge, les actions, reducer et epics
// de Level Up ont été séparés en plusieurs sous fichiers.

export default combineEpics(
    ...Object.values(levelUpParametres),
    ...Object.values(levelUpFiches),
    ...Object.values(levelUpQuiz),
    ...Object.values(levelUpStatistiques),
    ...Object.values(levelUpAnimations),
    ...Object.values(levelUpBattles),
);