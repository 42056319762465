import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'store/rootStore';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import isEqual from 'lodash/isEqual';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from 'react-responsive';
import { adminHeaderSetStore } from 'store/adminHeader/adminHeaderActions';
import { setHeaderStore } from 'features/header/src/store/actions';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ArrowBackSVG from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import CreateNotificationDialog from 'components/notifications/CreateNotificationDialog.component';
import { FAQ } from 'classes/notifications/FAQ.class';
import { Section } from 'classes/faq/Section.class';
import { Question } from 'classes/faq/Question.class';
import { loadFAQAsync } from './store/actions';
import useStyle from './Faq.style';

interface FaqProps{}

const Faq: React.FC<FaqProps> = (props) => {
  const classes: ReturnType<typeof useStyle> & Record<string, string> = useStyle();

  const [expanded, setExpanded] = useState<string | boolean>('');
  const [notifDialogOpen, setNotifDialogOpen] = useState<boolean>(false);

  const [listeSections, droits] = useSelector((state) => [state.parametres.faq.sections, state.acteur.droits], isEqual);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (IS_ADMIN) {
      dispatch(adminHeaderSetStore({
        ariane: ['Paramètres', 'Foire Aux Questions'],
        returnHeader: true,
        returnLink: '/parametres/',
        title: 'Foire Aux Questions',
      }));
    } else {
      dispatch(setHeaderStore({
        pageTitle: 'FAQ',
        rightComponents: [],
        leftComponents: [
            <ArrowBackSVG onClick={() => dispatch(push("/parametre/"))} style={{ height: 24, width: 24, color: 'white', margin : 'auto', cursor: 'pointer' }} />
        ],
        isPageFilter: false,
      }));
    }
    dispatch(loadFAQAsync.request());
  }, []);

  const afficherSection = (sections: Section[], niveau = 1): JSX.Element[] => {
    // Le any est ici utilisé car dans le cas présent on doit retourner un tableau de JSX.Element[JSX.Element[...]] Avec autant de tableau imbriqué qu'il peut y avoir de sections.
    const ret: any[] = [];

    // Map sur les sections parentes
    sections.map((mySection: Section, indexSection: number) => {
      // Ajout à la liste JSX.Element du titre de la section, elle est indenté en fonction du param niveau
      // eslint-disable-next-line react/no-array-index-key
      ret.push(<h2 className={classNames(classes.category, classes[`level${niveau}`])} key={indexSection}>
          {mySection.nomSection}
               </h2>);
      // S'il y a des questions, on les affiche en les ajoutants à la liste de JSX.Element
      if (mySection.questions) {
        ret.push(mySection.questions.map((myQuestion: Question, indexQuestion: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={indexQuestion}>
                <Accordion
                  expanded={expanded === myQuestion.idQuestion.toString()}
                  onChange={(_event, isExpanded: boolean) => setExpanded(isExpanded ? myQuestion.idQuestion.toString() : false)}
                  elevation={isDesktopOrLaptop ? 0 : 1}
                  className={isDesktopOrLaptop ? classes.expansionPanel : ''}
                >
                    <AccordionSummary className={classes.question} expandIcon={<ExpandMoreIcon />}>
                        {myQuestion.nomQuestion}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography
                          color="secondary"
                          className={classes.content}
                          dangerouslySetInnerHTML={{ __html: myQuestion.nomReponse }}
                        />
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        )));
      }
      // S'il y a des sections enfant, on apelle la fonction de nouveau, niveau +1 concerne l'indentation.
      if (mySection.sections) {
          ret.push(afficherSection(mySection.sections, niveau + 1));
      }
    });
    return ret;
  };

  return (
      <Container maxWidth={isDesktopOrLaptop ? 'lg' : 'sm'} className={classes.root}>
          {
              IS_ADMIN && droits.hasOwnProperty('notifs_avancees') && (
                <>
                  <CreateNotificationDialog
                    triggered={notifDialogOpen}
                    object={new FAQ('/faq/')}
                    onClose={() => setNotifDialogOpen(false)}
                    defaultTitle={`FAQ - `}
                  />
                  <Box display="flex" justifyContent="right" marginBottom={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<AddAlertIcon />}
                        onClick={() => setNotifDialogOpen(true)}
                      >
                          Notifier les acteurs
                      </Button>
                  </Box>
                </>
              )
          }
          {
              isDesktopOrLaptop ? (
                  <Paper className={classes.paper} elevation={0}>
                      {afficherSection(listeSections)}
                  </Paper>
              )
              : 
                afficherSection(listeSections)
          }
      </Container>
  );
};

export default Faq;
