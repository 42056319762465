import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    loadBattlesListAsync,
    loadOpponentsListAsync,
    getQuestionBattleAsync,
    saveNextAnswerBattleAsync,
    loadRecapQuizBattleAsync,
    loadInfosMesBattlesAsync,
    loadBattlesClassementAsync
} from './actions';

const loadInfosMesBattlesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadInfosMesBattlesAsync.request)),
        switchMap(() =>
            from(levelUpService.loadInfosMesBattles()).pipe(
                map(loadInfosMesBattlesAsync.success),
                catchError(message => of(loadInfosMesBattlesAsync.failure(message)))
            )
        ),
    );

const loadBattlesListEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadBattlesListAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadBattlesList(action.payload)).pipe(
                map(loadBattlesListAsync.success),
                catchError(message => of(loadBattlesListAsync.failure(message)))
            )
        ),
    );

const loadOpponentsListEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadOpponentsListAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadOpponentsList(action.payload)).pipe(
                map(loadOpponentsListAsync.success),
                catchError(message => of(loadOpponentsListAsync.failure(message)))
            )
        ),
    );

const getQuestionBattleEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getQuestionBattleAsync.request)),
        switchMap((action) =>
            from(levelUpService.getQuestionBattle(action.payload)).pipe(
                map(getQuestionBattleAsync.success),
                catchError(message => of(getQuestionBattleAsync.failure(message)))
            )
        ),
    );
    
const saveNextAnswerBattleEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(saveNextAnswerBattleAsync.request)),
        switchMap((action) =>
            from(levelUpService.saveNextAnswerBattle(action.payload.answers, action.payload.idBattle)).pipe(
                map(saveNextAnswerBattleAsync.success),
                catchError(message => of(saveNextAnswerBattleAsync.failure(message)))
            )
        ),
    );

const loadLastQuizStatsBattleEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadRecapQuizBattleAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadRecapQuizBattle(action.payload)).pipe(
                map(loadRecapQuizBattleAsync.success),
                catchError(message => of(loadRecapQuizBattleAsync.failure(message)))
            )
        ),
    );

const loadBattlesClassementEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadBattlesClassementAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadBattlesClassement(action.payload)).pipe(
                map(loadBattlesClassementAsync.success),
                catchError(message => of(loadBattlesClassementAsync.failure(message)))
            )
        ),
    );


const LevelUpBattlesEpics = combineEpics(
    loadBattlesListEpics,
    loadOpponentsListEpics,
    getQuestionBattleEpic,
    saveNextAnswerBattleEpic,
    loadLastQuizStatsBattleEpic,
    loadInfosMesBattlesEpics,
    loadBattlesClassementEpic
);

export default LevelUpBattlesEpics;