import Axios from "axios";
import qs from "qs";
import { RechercheFilterState, RechercheFiltersInfos } from "features/admin/recherche/src/store/types";
import { Post } from "classes/murs/Post.class";
import { DeepRawify } from "types";

export class RechercheService {
    private static instance: RechercheService;

    public static getInstance(): RechercheService {
        if (!RechercheService.instance) {
            RechercheService.instance = new RechercheService();
        }

        return RechercheService.instance;
    }

    private static rubriqueRecherche = 280;

    public loadPosts(rechercheFilters: RechercheFilterState): Promise<Post[]> {
        const params = {
            rub: RechercheService.rubriqueRecherche,
            filters: JSON.stringify(rechercheFilters),
            p: 1
        };

        return Axios.get<{ content: DeepRawify<Post>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: posts } }) => posts.map((post) => new Post(post)));
    }

    public loadPostsInfos(): Promise<RechercheFiltersInfos> {
        const params = {
            rub: RechercheService.rubriqueRecherche,
            p: 2
        };

        return Axios.get<{ content: RechercheFiltersInfos }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }
}
