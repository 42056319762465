import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/styles'
import EmailSVG from '@material-ui/icons/Email';
import PhoneSVG from '@material-ui/icons/Phone';
import Avatar from 'components/avatar/Avatar.component';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { colors } from 'Theme';
import HandWaveSVG from '../../assets/hand_wave.svg';
import { loadContacts } from '../store/actions';
import style from './ContactsCarnet.style';

const stateToProps = ({ carnetVoyage: { contacts } }: RootState) => ({
    contacts
})

const dispatchToProps = {
    loadContacts: loadContacts.request
}

interface ContactsCarnetLocalProps {
}

interface ContactsCarnetState {
}

type ContactsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & ContactsCarnetLocalProps;

class ContactsCarnet extends Component<ContactsCarnetProps, ContactsCarnetState> {

    componentDidMount() {
        this.props.loadContacts();
    }

    render() {
        const { contacts, classes } = this.props;
        return (
            <Box display='flex' flexDirection='column' padding={1}>
                <Paper elevation={0} className={classes.paperWave} >
                    <img className={classes.imgWave} src={HandWaveSVG} />
                    <Typography className={classes.textWave}>Hey ! Voici les personnes que vous pouvez contacter en cas de besoin.</Typography>
                </Paper>
                {
                    contacts.map((contact, index) =>
                        <Paper className={classes.paperContact} elevation={0}>
                            <Box display='flex' flexDirection='row' alignItems='center' padding={2} bgcolor={colors.blue.dark}>
                                <Avatar variant='front' acteur={contact} />
                                <Box display='flex' flexDirection='column' marginLeft={1} height='100%' justifyContent='space-between' color='white'>
                                    <Typography className={classes.nameText} > <b>{contact.prenom} {contact.nom}</b></Typography>
                                    <Typography className={classes.roleText}> {contact.role} </Typography>
                                </Box>
                            </Box>
                            {
                                (contact.mobile || contact.idActeur) &&
                                <Box display='flex' flexDirection='column' padding={2}>
                                    {
                                        contact.mobile &&
                                        <Box display='flex' justifyContent='space-between' height={44} width='100%' marginBottom={1}>
                                            <Box display='flex' flexDirection='column' justifyContent='space-between' >
                                                <Typography className={classes.phoneMailTitle}>{contact.mobile}</Typography>
                                                <Typography className={classes.phoneMailSubtitle}>Mobile</Typography>
                                            </Box>
                                            <Link href={`tel:${contact.mobile.replace(/ /g, '')}`} className={classes.phoneImageLink}>
                                                <PhoneSVG className={classes.phoneMailImage} />
                                            </Link>
                                        </Box>
                                    }
                                    {
                                        contact.idActeur &&
                                        <Box display='flex' justifyContent='space-between' height={44} width='100%'>
                                            <Box display='flex' flexDirection='column' justifyContent='space-between'>
                                                <Typography className={classes.phoneMailTitle}>{contact.idActeur}</Typography>
                                                <Typography className={classes.phoneMailSubtitle}>E-mail</Typography>
                                            </Box>
                                            <Link href={`mailto:${contact.idActeur}`} className={classes.mailImageLink}>
                                                <EmailSVG className={classes.phoneMailImage} />
                                            </Link>
                                        </Box>
                                    }

                                </Box>
                            }

                        </Paper>

                    )
                }
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(ContactsCarnet));