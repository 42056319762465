import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import moment, { Moment } from 'moment';

/**
 * Sauvearde les caracteristiques d'une fiche  
 */
export class FicheProduitCaracteristique
{

    /**
     * L'identifiant de la valeur caractéristique
     * @var int
     */
    public idFicheProduitCaracteristique: number;
    
    /**
     * L'identifiant de la valeur caractéristique
     * @var int
     */
    public idFicheProduit: number;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idCaracteristique: number;

    /**
     * Valeur de la caractéristique
     * @var string
     */
    public valeur: string;

    /**
     * Identifiant du picto associé
     * @var int
     */
    public idCaracteristiquePicto: number;

    /**
     * Couleur pour l'affichage
     * @var string
     */
    public codeCouleur: string;

    /**
     * Dernière MàJ
     * @var int
     */
    public dateMiseAJour: Moment;

    private levelUpService = LevelUpService.getInstance();

    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FicheProduitCaracteristique>>) {
        if (row) {
            this.idFicheProduitCaracteristique = row['idFicheProduitCaracteristique'] ?? null;
            this.idFicheProduit = row['idFicheProduit'] ?? null;
            this.idCaracteristique = row['idCaracteristique'] ?? null;
            this.valeur = row['valeur'] ?? null;
            this.idCaracteristiquePicto = row['idCaracteristiquePicto'] ?? null;
            this.codeCouleur = row['codeCouleur'] ?? null;
            this.dateMiseAJour = row['dateMiseAJour'] ? moment(row['dateMiseAJour'], 'X') : moment();
        }
    }

    public toRaw()
    {
        return {
            idFicheProduitCaracteristique: this.idFicheProduitCaracteristique,
            idFicheProduit: this.idFicheProduit,
            idCaracteristique: this.idCaracteristique,
            valeur: this.valeur,
            idCaracteristiquePicto: this.idCaracteristiquePicto,
            codeCouleur: this.codeCouleur,
            dateMiseAJour: this.dateMiseAJour && this.dateMiseAJour.unix(),
        };
    }

}
