import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadPostsAsync, loadPostsFiltersAsync } from "./actions";
import { RechercheFiltersInfos, RechercheFilterState } from "./types";
import { Post } from "classes/murs/Post.class";

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadPostsAsync.request], (_state, action) => true)
    .handleAction([loadPostsAsync.success, loadPostsAsync.failure], (_state, action) => false);

const posts = createReducer<Post[], RootAction>([])
    .handleAction([loadPostsAsync.success], (_state, action) => action.payload)

const filtersInfos = createReducer<RechercheFiltersInfos, RootAction>(null)
    .handleAction(loadPostsFiltersAsync.success, (_state, action) => action.payload)

const previousFiltersState = createReducer<RechercheFilterState, RootAction>(null)
    .handleAction(loadPostsAsync.request, (_state,action) => action.payload)

const RechercheReducer = combineReducers({
    isLoading,
    posts,
    filtersInfos,
    previousFiltersState
});

export default RechercheReducer;


