import qs from 'qs';
import Axios, { CancelTokenSource } from 'axios';
import { Challenge } from 'classes/challenges/Challenge.class';
import { rxAxios, RxAxios } from 'services/RxAxios';

export class VideoService {
    private static instance: VideoService;

    public static getInstance(): VideoService {
        if (!VideoService.instance) {
            VideoService.instance = new VideoService();
        }

        return VideoService.instance;
    }

    private source: CancelTokenSource = null;

    // In order to not wait for the save action to send the video, we pre-upload it.
    public preUploadVideo(file: File): RxAxios<{ content: any }> {
        const formData = new FormData();
        formData.append('file', file);
        
        this.source = Axios.CancelToken.source();

        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub: 300,
                p: 1,
                fileManager: "challenges_video"
            })}`,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            },
            cancelToken: this.source.token
        });
    }


    public cancelPreUploadVideo() {
        if (this.source != null) {
            this.source.cancel("Operation canceled by the user.")
        }
    }
}
