import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { saveTaskAsync, deleteTaskAsync, loadTasksAsync, sortTasksAsync } from "./actions";
import { TodoList } from "classes/todolist/TodoList.class";
import cloneDeep from "lodash/cloneDeep";

const isLoading = createReducer<boolean, RootAction>(false)
    .handleAction([loadTasksAsync.request], (_state, action) => true)
    .handleAction([loadTasksAsync.success, loadTasksAsync.failure], (_state, action) => false);

const todoList = createReducer<TodoList[], RootAction>([])
    .handleAction([loadTasksAsync.success], (_state, action) => action.payload)
    .handleAction([saveTaskAsync.success], (_state, action) => [..._state.filter(i => i.idTodo != action.payload.idTodo), action.payload])
    .handleAction([deleteTaskAsync.success], (_state, action) => _state.filter((value) => value.idTodo != action.payload))
    .handleAction([sortTasksAsync.success], (_state, action) => action.payload);

const TableauBordReducer = combineReducers({
    isLoading,
    todoList
});

export default TableauBordReducer;


