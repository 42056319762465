import Axios from 'axios';
import { B64File } from 'classes/B64File.class';
import { combineEpics } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    addFileTelechargements,
    getChallengeAsync,
    loadChallengeActualiteAsync,
    loadChallengeActualitesAsync,
    loadChallengeEditoAsync,
    loadChallengeReglementAsync,
    loadChallengeRubriquesAndInfosAsync,
    loadChallengesAsync,
    loadChallengesTerminesAsync,
    loadDestinationRubriquesAsync,
    loadDestinationRubriquesInfosAsync,
    loadPredefinedThemesAsync,
    loadTelechargements,
    loadVideos,
    removeFileTelechargements,
    removeVideo,
    saveRubriquesDestinationAsync,
    saveVideo,
    setProgressSaveFiles,
    updateFileTelechargementsIndex,
    loadChallengeSuivisAsync,
    loadChallengeSuivisMobileAsync,
    loadChallengeSuivisNiveauAsync,
    loadChallengeSuiviAsync,
    loadClassementsMulticritereAsync,
    loadClassementSimpleActeurAsync,
    loadClassementsSimpleAsync,
    loadMonEquipeAsync,
    loadClassementMulticritereParActeurAsync,
    getChallengeAndRubriqueAsync,
    loadMoreClassementsMulticritereAsync,
    loadClassementGlobalAsync,
    loadMoreClassementGlobalAsync,
    loadClassementGlobalActeurAsync
} from './actions';

const loadChallengesEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengesAsync.request)),
    switchMap(() => from(challengeService.findAll(state$.value.challenges.listChallengesFilters)).pipe(
        map(loadChallengesAsync.success),
        catchError(message => of(loadChallengesAsync.failure(message))),
    )),
);

const loadChallengesTerminesEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengesTerminesAsync.request)),
    switchMap(() => from(challengeService.findAllChallengesTermines(state$.value.challenges.listChallengesFilters)).pipe(
        map(loadChallengesTerminesAsync.success),
        catchError(message => of(loadChallengesTerminesAsync.failure(message))),
    )),
);

const getChallengeEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(getChallengeAsync.request)),
    switchMap((action) => from(challengeService.find(action.payload)).pipe(
        map(getChallengeAsync.success),
        catchError(message => of(getChallengeAsync.failure(message))),
    )),
);

const getChallengeAndRubriqueEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(getChallengeAndRubriqueAsync.request)),
    switchMap((action) => from(challengeService.getChallengeAndRubrique(action.payload.idChallenge, action.payload.idRubriqueInfos)).pipe(
        map(getChallengeAndRubriqueAsync.success),
        catchError(message => of(getChallengeAndRubriqueAsync.failure(message))),
    )),
);


const loadPredefinedThemesEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadPredefinedThemesAsync.request)),
    switchMap(() => from(challengeService.loadPredefinedThemes()).pipe(
        map(loadPredefinedThemesAsync.success),
        catchError(message => of(loadPredefinedThemesAsync.failure(message))),
    )),
);


const getEditoEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeEditoAsync.request)),
    switchMap((action) => from(challengeService.findEdito(action.payload)).pipe(
        map(loadChallengeEditoAsync.success),
        catchError(message => of(loadChallengeEditoAsync.failure(message)))
    ))
);
const loadTelechargementsEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(loadTelechargements.request)),
        switchMap((action) =>
            from(challengeService.loadTelechargements(action.payload)).pipe(
                map(loadTelechargements.success),
                catchError(message => of(loadTelechargements.failure(message)))
            )
        ),
    );

const addFileTelechargementsEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(addFileTelechargements.request)),
        map((action) => {
            const rxAxios = challengeService.addFileTelechargements(action.payload.b64File, action.payload.idChallenge);
            return { action, rxAxios }
        }),
        mergeMap(({ action, rxAxios: { progress, response } }) => merge(
            progress.pipe(
                map((progress) => {
                    const file: B64File = action.payload.b64File;
                    file.progress = progress;
                    return setProgressSaveFiles(file);
                }),
            ),
            response.pipe(
                mergeMap(({ content }) => [newSnackbar({
                    type: SnackbarType.SUCCESS,
                    props: {
                        open: true,
                        autoHideDuration: 2000,
                        message: `Le fichier a bien été ajouté.`,
                    }
                }),
                addFileTelechargements.success(<B64File>content)]),
            ),
        )),
        catchError((error) => {
            newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 2000,
                    message: `Il y a eu un problème dans la sauvegarde des fichiers.`,
                }
            });
            return of(addFileTelechargements.failure(error))
        })
    );


const removeFileTelechargementsEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(removeFileTelechargements.request)),
        mergeMap(action =>
            from(challengeService.removeFileTelechargements(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 2000,
                            message: `Le fichier a bien été supprimé.`
                        }
                    }),
                    removeFileTelechargements.success(action)
                ]),
                catchError(message => of(removeFileTelechargements.failure(message)))
            )
        )
    );

const updateFileTelechargementsIndexEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(updateFileTelechargementsIndex.request)),
        switchMap((action) =>
            from(challengeService.updateFileTelechargementsIndex(action.payload, state$.value.challenges.selectedChallenge)).pipe(
                map(updateFileTelechargementsIndex.success),
                catchError(message => of(updateFileTelechargementsIndex.failure(message)))
            )
        ),
    );

// Chargement des actualités en fonction du challenge
const loadAdminActualitesEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeActualitesAsync.request)),
    switchMap((action) => from(challengeService.loadListActualites(action.payload)).pipe(
        map(loadChallengeActualitesAsync.success),
        catchError(message => of(loadChallengeActualitesAsync.failure(message))),
    )),
);

const loadAdminActualiteEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeActualiteAsync.request)),
    switchMap((action) => from(challengeService.loadActualite(action.payload)).pipe(
        map(loadChallengeActualiteAsync.success),
        catchError(message => of(loadChallengeActualiteAsync.failure(message))),
    )),
);

// const loadChallengeRubriquesEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
//     filter(isActionOf(loadChallengeRubriquesAsync.request)),
//     switchMap((action) => from(challengeService.loadChallengeRubriques(action.payload)).pipe(
//         map(loadChallengeRubriquesAsync.success),
//         catchError(message => of(loadChallengeRubriquesAsync.failure(message))),
//     )),
// );

// const loadChallengeRubriquesInfosEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
//     filter(isActionOf(loadChallengeRubriquesInfosAsync.request)),
//     switchMap((action) => from(challengeService.loadChallengeRubriquesInfos()).pipe(
//         map(loadChallengeRubriquesInfosAsync.success),
//         catchError(message => of(loadChallengeRubriquesInfosAsync.failure(message))),
//     )),
// );

const loadChallengeRubriquesAndInfosEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeRubriquesAndInfosAsync.request)),
    switchMap((action) => from(challengeService.loadChallengeRubriquesAndInfos(action.payload.idChallenge, action.payload.rubriquesOrInfos)).pipe(
        map(loadChallengeRubriquesAndInfosAsync.success),
        catchError(message => of(loadChallengeRubriquesAndInfosAsync.failure(message))),
    )),
);

const getReglementEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeReglementAsync.request)),
    switchMap((action) => from(challengeService.findReglement(action.payload)).pipe(
        map(loadChallengeReglementAsync.success),
        catchError(message => of(loadChallengeReglementAsync.failure(message)))
    ))
);
// Chargement des rubriques de destination en fonction du challenge
const loadDestinationRubriquesEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadDestinationRubriquesAsync.request)),
    switchMap((action) => from(challengeService.loadDestinationRubriques(action.payload)).pipe(
        map(loadDestinationRubriquesAsync.success),
        catchError(message => of(loadDestinationRubriquesAsync.failure(message))),
    )),
);

// Chargement des informations des rubriques de destination
const loadDestinationRubriquesInfosEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadDestinationRubriquesInfosAsync.request)),
    switchMap(() => from(challengeService.loadAllDestinationRubriquesInfos()).pipe(
        map(loadDestinationRubriquesInfosAsync.success),
        catchError(message => of(loadDestinationRubriquesInfosAsync.failure(message))),
    )),
);

// Enregistrement des Rubriques de la partie Destination d'un challenge
const saveRubriquesDestinationEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(saveRubriquesDestinationAsync.request)),
        switchMap(action =>
            from(challengeService.saveRubriquesDestination(action.payload)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `Les informations ont bien été enregistrées.`
                        }
                    }),
                    saveRubriquesDestinationAsync.success(action)
                ]),
                catchError((error) => {
                    newSnackbar({
                        type: SnackbarType.WARNING,
                        props: {
                            open: true,
                            autoHideDuration: 2000,
                            message: `Il y a eu un problème lors de l'enregistrement des informations. Veuillez vérifier vos informations et recommencer.`,
                        }
                    });
                    return of(saveRubriquesDestinationAsync.failure(error))
                })
            )
        )
    );

const saveVideoEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(saveVideo.request)),
        switchMap(action =>
            from(challengeService.saveVideo(action.payload.idChallenge, action.payload.video)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 5000,
                            message: `La vidéo a bien été sauvegardée.`
                        }
                    }),
                    saveVideo.success(action)
                ]),
                catchError(message => of(saveVideo.failure(message)))
            )
        )
    );

const loadVideosEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(loadVideos.request)),
        switchMap((action) =>
            from(challengeService.loadVideos(action.payload)).pipe(
                map(loadVideos.success),
                catchError(message => of(loadVideos.failure(message)))
            )
        ),
    );

const removeVideoEpics: RootEpic = (action$, state$, { challengeService }) =>
    action$.pipe(
        filter(isActionOf(removeVideo.request)),
        mergeMap(action =>
            from(challengeService.removeVideo(action.payload.idChallenge, action.payload.video)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 2000,
                            message: `La vidéo a bien été supprimée.`
                        }
                    }),
                    removeVideo.success(action)
                ]),
                catchError(message => of(removeVideo.failure(message)))
            )
        )
    );

// Chargement des suivis en fonction du challenge
const loadChallengeSuivisEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeSuivisAsync.request)),
    switchMap((action) => from(challengeService.loadChallengeSuivis(action.payload)).pipe(
        map(loadChallengeSuivisAsync.success),
        catchError(message => of(loadChallengeSuivisAsync.failure(message))),
    )),
);



// Chargement des suivis en fonction du challenge pour la version mobile
const loadChallengeSuivisMobileEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeSuivisMobileAsync.request)),
    switchMap((action) => from(challengeService.loadChallengeSuivisMobile(action.payload)).pipe(
        map(loadChallengeSuivisMobileAsync.success),
        catchError(message => of(loadChallengeSuivisMobileAsync.failure(message))),
    )),
);

// Chargement des suivis en fonction du challenge
const loadChallengeSuivisNiveauEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeSuivisNiveauAsync.request)),
    switchMap((action) => from(challengeService.loadSuivisByNiveau(action.payload.idChallenge, action.payload.idEntiteNiveau)).pipe(
        map(loadChallengeSuivisNiveauAsync.success),
        catchError(message => of(loadChallengeSuivisNiveauAsync.failure(message))),
    )),
);

const loadChallengeSuiviEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadChallengeSuiviAsync.request)),
    switchMap((action) => from(challengeService.loadChallengeSuivi(action.payload)).pipe(
        map(loadChallengeSuiviAsync.success),
        catchError(message => of(loadChallengeSuiviAsync.failure(message))),
    )),
);

// Chargement des classements multicritère
const loadClassementsMulticritereEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadClassementsMulticritereAsync.request)),
    switchMap((action) => from(challengeService.loadClassementsMulticritere(action.payload)).pipe(
        map(loadClassementsMulticritereAsync.success),
        catchError(message => of(loadClassementsMulticritereAsync.failure(message))),
    )),
);

// Chargement des classements multicritère
const loadMoreClassementsMulticritereEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadMoreClassementsMulticritereAsync.request)),
    switchMap((action) => from(challengeService.loadMoreClassementsMulticritere(action.payload)).pipe(
        map(loadMoreClassementsMulticritereAsync.success),
        catchError(message => of(loadMoreClassementsMulticritereAsync.failure(message))),
    )),
);

// Chargement des classements multicritère pour un acteur
const loadClassementMulticritereParActeurEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadClassementMulticritereParActeurAsync.request)),
    switchMap((action) => from(challengeService.loadClassementMulticritereParActeur(action.payload)).pipe(
        map(loadClassementMulticritereParActeurAsync.success),
        catchError(message => of(loadClassementMulticritereParActeurAsync.failure(message))),
    )),
);

// Chargement du classement simple d'un acteur
const loadClassementSimpleActeurEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadClassementSimpleActeurAsync.request)),
    switchMap((action) => from(challengeService.loadClassementSimpleActeur(action.payload)).pipe(
        map(loadClassementSimpleActeurAsync.success),
        catchError(message => of(loadClassementSimpleActeurAsync.failure(message))),
    )),
);

// Chargement des classements simple d'un suivi
const loadClassementsSimpleEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadClassementsSimpleAsync.request)),
    switchMap((action) => from(challengeService.loadClassementsSimple(action.payload)).pipe(
        map(loadClassementsSimpleAsync.success),
        catchError(message => of(loadClassementsSimpleAsync.failure(message))),
    )),
);

// Chargement des membres de l'équipe d'un acteur sur un suivi simple
const loadMonEquipeEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadMonEquipeAsync.request)),
    switchMap((action) => from(challengeService.loadMonEquipe(action.payload)).pipe(
        map(loadMonEquipeAsync.success),
        catchError(message => of(loadMonEquipeAsync.failure(message))),
    )),
);

// Chargement du classement global
const loadClassementGlobalEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadClassementGlobalAsync.request)),
    switchMap((action) => from(challengeService.loadClassementGlobal(action.payload)).pipe(
        map(loadClassementGlobalAsync.success),
        catchError(message => of(loadClassementGlobalAsync.failure(message))),
    )),
);

// Charge plus de lignes du classement global
const loadMoreClassementGlobalEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadMoreClassementGlobalAsync.request)),
    switchMap((action) => from(challengeService.loadMoreClassementGlobal(action.payload)).pipe(
        map(loadMoreClassementGlobalAsync.success),
        catchError(message => of(loadMoreClassementGlobalAsync.failure(message))),
    )),
);

// Chargement des classements multicritère pour un acteur
const loadClassementGlobalActeurEpics: RootEpic = (action$, state$, { challengeService }) => action$.pipe(
    filter(isActionOf(loadClassementGlobalActeurAsync.request)),
    switchMap((action) => from(challengeService.loadClassementGlobalActeur(action.payload)).pipe(
        map(loadClassementGlobalActeurAsync.success),
        catchError(message => of(loadClassementGlobalActeurAsync.failure(message))),
    )),
);



const ChallengesEpics = combineEpics(
    loadChallengesEpics,
    loadChallengesTerminesEpics,
    getChallengeEpics,
    loadPredefinedThemesEpics,
    getEditoEpics,
    loadTelechargementsEpics,
    addFileTelechargementsEpics,
    removeFileTelechargementsEpics,
    updateFileTelechargementsIndexEpics,
    loadAdminActualitesEpics,
    loadAdminActualiteEpics,
    getReglementEpics,
    // loadChallengeRubriquesEpics,
    // loadChallengeRubriquesInfosEpics,
    loadChallengeRubriquesAndInfosEpics,
    loadDestinationRubriquesEpics,
    loadDestinationRubriquesInfosEpics,
    saveRubriquesDestinationEpics,
    saveVideoEpics,
    loadVideosEpics,
    removeVideoEpics,
    loadChallengeSuivisEpics,
    loadChallengeSuivisMobileEpics,
    loadChallengeSuivisNiveauEpics,
    loadChallengeSuiviEpics,
    getChallengeAndRubriqueEpics,
    loadClassementsMulticritereEpics,
    loadClassementMulticritereParActeurEpics,
    loadMoreClassementsMulticritereEpics,
    loadClassementSimpleActeurEpics,
    loadClassementsSimpleEpics,
    loadMonEquipeEpics,
    loadClassementGlobalEpics,
    loadMoreClassementGlobalEpics,
    loadClassementGlobalActeurEpics
);

export default ChallengesEpics;
