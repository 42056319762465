import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    buttonLaunch : {
        borderRadius : 5,
        marginBottom: spacing(1)
    },
    imgLaunch : {
        marginRight : spacing(0.5),
    },
    buttonDay : {
        borderRadius : 5,
        marginRight : spacing(1),
        height : 50,
        fontSize : 18,
        width : 120,
        marginBottom : spacing(1)
    },
    dateTextField : {
        marginLeft : spacing(4),
        marginTop : 0,
        marginBottom : 0,
        backgroundColor : 'white',
        border : `1px solid ${colors.lighterGrey.main}`,
        borderRadius : 5,
        '& fieldset' : {
            border : 0
        }
    },
    addActivityButton : {
        minHeight : 40,
        height : 40,
        width: 200,
        fontSize : 14,
        borderRadius: 5
    },
    bold : {
        fontWeight : 700
    }
});