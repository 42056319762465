import qs from 'qs';
import Axios from 'axios';
import { TodoList } from "classes/todolist/TodoList.class";
import { DeepRawify } from 'types';

export class TodoListService {
    private static instance: TodoListService;

    public static getInstance(): TodoListService {
        if (!TodoListService.instance) {
            TodoListService.instance = new TodoListService();
        }

        return TodoListService.instance;
    }

    private static rubrique = IS_ADMIN ? 190 : 19;

    public async createTask(task: TodoList): Promise<TodoList> {
        const params = {
            rub: TodoListService.rubrique,
            p: 2, /* Code pour avoir les données en JSON */
        };

        return Axios.post<{ content: DeepRawify<TodoList> }>(`index.php?${qs.stringify(params)}`, qs.stringify({ task: task.toRaw() }))
            .then(({ data: { content } }) => new TodoList(content));
    }

    public async deleteTask(idTodo: number): Promise<number> {
        const params = {
            rub: TodoListService.rubrique,
            p: 3, /* Code pour avoir les données en JSON */
            idTodo
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

    public async loadTasks(): Promise<TodoList[]> {
        const params = {
            rub: TodoListService.rubrique,
            p: 1, /* Code pour avoir les données en JSON */
        };

        return Axios.get<{ content: DeepRawify<TodoList>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((task) => new TodoList(task)));
    }

    public async sortTasks(request: number[]): Promise<TodoList[]> {
        const params = {
            rub: TodoListService.rubrique,
            p: 5, /* Code pour avoir les données en JSON */
        };

        return await Axios.post<{ content: DeepRawify<TodoList>[] }>(`index.php?${qs.stringify(params)}`, qs.stringify({ sort: request }))
            .then(({ data: { content } }) => content.map((task) => new TodoList(task)));
    }
}
