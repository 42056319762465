import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    loadGroupesAsync,
    getGroupeAsync,
    loadQuestionsAsync,
    getQuestionAsync,
    loadFicheQuizAsync,
    saveNextAnswerAsync,
    loadLastQuizStatsAsync,
    loadClassementAsync
} from './actions';

const loadGroupesEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadGroupesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadQuestionGroupes()).pipe(
                map(loadGroupesAsync.success),
                catchError(message => of(loadGroupesAsync.failure(message)))
            )
        ),
    );

const getGroupeEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getGroupeAsync.request)),
        switchMap((action) =>
            from(levelUpService.getQuestionGroupe(action.payload)).pipe(
                map(getGroupeAsync.success),
                catchError(message => of(getGroupeAsync.failure(message)))
            )
        ),
    );

const loadQuestionsEpics: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadQuestionsAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadQuestions(action.payload)).pipe(
                map(loadQuestionsAsync.success),
                catchError(message => of(loadQuestionsAsync.failure(message)))
            )
        ),
    );

const getQuestionEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(getQuestionAsync.request)),
        switchMap((action) =>
            from(levelUpService.getQuestion(action.payload)).pipe(
                map(getQuestionAsync.success),
                catchError(message => of(getQuestionAsync.failure(message)))
            )
        ),
    );


const loadFicheQuizEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadFicheQuizAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadFicheQuiz(action.payload)).pipe(
                map(loadFicheQuizAsync.success),
                catchError(message => of(loadFicheQuizAsync.failure(message)))
            )
        ),
    );

const saveNextAnswerEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(saveNextAnswerAsync.request)),
        switchMap((action) =>
            from(levelUpService.saveNextAnswer(action.payload)).pipe(
                map(saveNextAnswerAsync.success),
                catchError(message => of(saveNextAnswerAsync.failure(message)))
            )
        ),
    );

const loadLastQuizStatsEpic: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadLastQuizStatsAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadLastQuizStats()).pipe(
                map(loadLastQuizStatsAsync.success),
                catchError(message => of(loadLastQuizStatsAsync.failure(message)))
            )
        ),
    );
    
const loadClassementEpic: RootEpic = (action$, state$, { levelUpService }) =>
        action$.pipe(
            filter(isActionOf(loadClassementAsync.request)),
            switchMap((action) =>
                from(levelUpService.loadClassement(action.payload)).pipe(
                    map(loadClassementAsync.success),
                    catchError(message => of(loadClassementAsync.failure(message)))
                )
            ),
        );

const LevelUpQuizEpics = combineEpics(
    loadGroupesEpics,
    getGroupeEpic,
    loadQuestionsEpics,
    getQuestionEpic,
    loadFicheQuizEpic,
    saveNextAnswerEpic,
    loadLastQuizStatsEpic,
    loadClassementEpic
);

export default LevelUpQuizEpics;
