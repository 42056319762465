import { from, of } from 'rxjs';
import {
    filter, switchMap, catchError, map,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { loadAnimationsAsync } from './actions';

import quizEpics from '../quiz/store/epics';
import concoursPhotoEpics from '../photoCompetition/store/epics';

const loadAnimationsEpics: RootEpic = (action$, state$, { animationService }) => action$.pipe(
    filter(isActionOf(loadAnimationsAsync.request)),
    switchMap((action) => from(animationService.findAll(action.payload)).pipe(
        map(loadAnimationsAsync.success),
        catchError(message => of(loadAnimationsAsync.failure(message))),
    )),
);

const AnimationsEpics = combineEpics(loadAnimationsEpics, quizEpics, concoursPhotoEpics);

export default AnimationsEpics;
