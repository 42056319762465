import Axios from 'axios';
import { combineEpics } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { getPostAsync, getPostWithTypeMurAsync, getPostFromIndexAsync, loadElementsAsync, loadMorePostsAsync, loadMurInfos, loadPostsAsync, loadTypesPostAsync, preUploadVideoAsync, setProgressPreUploadedVideo } from './actions';

// import quizEpics from '../quiz/store/epics';

const loadMurInfosEpics: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(loadMurInfos.request)),
    switchMap(() => from(mursService.loadMurInfos(state$.value.murs.typeMur, state$.value.murs.listPostsFilters)).pipe(
        map(loadMurInfos.success),
        catchError(message => of(loadMurInfos.failure(message))),
    )),
);

const loadPostsEpics: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(loadPostsAsync.request)),
    switchMap(() => from(mursService.loadPosts(state$.value.murs.typeMur, state$.value.murs.listPostsFilters)).pipe(
        map(loadPostsAsync.success),
        catchError(message => of(loadPostsAsync.failure(message))),
    )),
);

const loadMorePostsEpics: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(loadMorePostsAsync.request)),
    switchMap(() => from(mursService.loadPosts(state$.value.murs.typeMur, state$.value.murs.listPostsFilters)).pipe(
        map(loadMorePostsAsync.success),
        catchError(message => of(loadMorePostsAsync.failure(message))),
    )),
);



const loadElementsEpics: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(loadElementsAsync.request)),
    switchMap(() => from(mursService.loadElements(state$.value.murs.typeMur)).pipe(
        map(loadElementsAsync.success),
        catchError(message => of(loadElementsAsync.failure(message))),
    ))
)

const loadTypesPostEpics: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(loadTypesPostAsync.request)),
    switchMap(() => from(mursService.loadTypesPost(state$.value.murs.typeMur)).pipe(
        map(loadTypesPostAsync.success),
        catchError(message => of(loadTypesPostAsync.failure(message))),
    ))
)

const getPostEpic: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(getPostAsync.request)),
    switchMap((action) => from(mursService.getPost(state$.value.murs.typeMur, action.payload)).pipe(
        map(getPostAsync.success),
        catchError(({ response: { data } }) => of(getPostAsync.failure(data))),
    ))
)

const getPostWithTypeMurEpic: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(getPostWithTypeMurAsync.request)),
    switchMap((action) => from(mursService.getPost(action.payload.typeMur, action.payload.idPost)).pipe(
        map(getPostWithTypeMurAsync.success),
        catchError(({ response: { data } }) => of(getPostWithTypeMurAsync.failure(data))),
    ))
)


const getPostFromIndexEpic: RootEpic = (action$, state$, { mursService }) => action$.pipe(
    filter(isActionOf(getPostFromIndexAsync.request)),
    switchMap((action) => from(mursService.getPost(state$.value.murs.typeMur, state$.value.murs.list[action.payload].idMurPost, action.payload)).pipe(
        map(getPostFromIndexAsync.success),
        catchError(({ response: { data } }) => of(getPostFromIndexAsync.failure(data))),
    ))
)

const preUploadVideoEpic: RootEpic = (action$, state$, { mursService }) =>
    action$.pipe(
        filter(isActionOf(preUploadVideoAsync.request)),
        mergeMap((action =>
            of(action).pipe(
                map((action) => mursService.preUploadVideo(state$.value.murs.typeMur, action.payload)),
                switchMap(({ progress, response }) =>
                    merge(
                        progress.pipe(
                            map(setProgressPreUploadedVideo),
                            takeUntil(action$.pipe(
                                filter(isActionOf(preUploadVideoAsync.cancel)),
                                tap(() => mursService.cancelPreUploadVideo()),
                            ))
                        ),
                        response.pipe(
                            mergeMap(({ content }) => [newSnackbar({
                                type: SnackbarType.SUCCESS,
                                props: {
                                    open: true,
                                    autoHideDuration: 5000,
                                    message: `Votre vidéo a bien été reçue.`,
                                }
                            }), preUploadVideoAsync.success(content)]),
                        ),
                    )
                )
            )
        )),
        catchError((error, caught) => {
            if (!Axios.isCancel(error)) {
                newSnackbar({
                    type: SnackbarType.WARNING,
                    props: {
                        open: true,
                        autoHideDuration: 5000,
                        message: `Votre vidéo n'a pas pu être envoyée.`,
                    }
                });
            }
            return merge(of(preUploadVideoAsync.failure(error)), caught)
        })
    )

const MursEpics = combineEpics(loadPostsEpics, loadMorePostsEpics, loadElementsEpics, getPostEpic, getPostWithTypeMurEpic, loadTypesPostEpics, loadMurInfosEpics, getPostFromIndexEpic, preUploadVideoEpic);

export default MursEpics;
