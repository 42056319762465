import createStyles from "@material-ui/styles/createStyles";

const style = () => createStyles({
    content: {
        display: 'flex',
        alignItems: 'center',
    }
});

export default style;
