import { DeepPartial } from 'redux';
import { CarnetVoyageService } from 'services';
import { Theme, colors } from 'Theme';
import { DeepRawify } from 'types';

export class CarnetCategory {

    public idCategory: number;

    public name: string;

    public active: boolean;

    public color: string;

    private carnetVoyageService = CarnetVoyageService.getInstance();

    static CarnetCategoryColor: Record<number, string> = {
        1: colors.blue.main,
        2: colors.purple.main,
        3: colors.yellow.main,
        4: colors.pink.main,
        5: colors.green.main,
        6: colors.grey.main,
        7: colors.blue.dark
    }

    constructor(row?: DeepPartial<DeepRawify<CarnetCategory>>) {
        if (row) {
            const { idCategory,
                name,
                color,
                active,
            } = row;
            this.idCategory = idCategory;
            this.name = name;
            this.color = color;
            this.active = active;
        }
    }


    public toRaw() {
        return {
            idCategory: this.idCategory,
            name: this.name,
            color: this.color,
            active: this.active,
        };
    }


}
