import { Acteur } from 'classes/Acteur.class';
import moment, { Moment } from 'moment';
import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';

/**
 * Cette classe représente un like donné à un post
 */
export class PostLike {

    /**
     * L'utilisateur qui a liké le post
     */
    public utilisateur: Acteur;

    /**
     * L'id du post qui a été liké
     */
    public idPost: number;

    /**
     * La date de publication du like
     */
    public date: Moment;

    constructor(postLike?: DeepPartial<DeepRawify<PostLike>>) {
        if (postLike) {
            this.utilisateur = new Acteur(postLike.utilisateur);
            this.idPost = postLike.idPost;
            this.date = moment.unix(postLike.date);
        }
    }

    /**
     * Convertit l'objet PostLike en tableau pour le transmettre au back
     * @returns Le tableau représentant l'instance
     */
    public toObject(): object {
        return {
            idUtilisateur: this.utilisateur.idActeur,
            idPost: this.idPost
        };
    }
}