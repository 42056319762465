import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers/';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store/rootStore';
import useStyle from './FixedKeyboardDateTimePicker.style';
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { Theme } from 'Theme';

interface FixedKeyboardDateTimePickerLocalProps {
}

type FixedKeyboardDateTimePickerProps = FixedKeyboardDateTimePickerLocalProps & KeyboardDateTimePickerProps;

const FixedKeyboardDateTimePicker: React.FC<FixedKeyboardDateTimePickerProps> = props => { 
    const datePickerTheme = (mainTheme: typeof Theme) => createTheme({
        props: mainTheme.props,
        palette: mainTheme.palette
    });

    const classes = useStyle();

    const [] = useSelector((state) => [], isEqual);

    useEffect(() => {
    });

    return (
        <ThemeProvider theme={datePickerTheme}>
            <KeyboardDateTimePicker
                DialogProps={{
                    className: classes.keyboardDateTimePicker
                }}
                {...props}
            />
        </ThemeProvider>
    );
}

export default FixedKeyboardDateTimePicker;