import { BreadcrumbsProps } from '@material-ui/core/Breadcrumbs';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import { adminHeaderSetStore } from './adminHeaderActions';

const arianeReducer = createReducer<(string | JSX.Element)[], RootAction>([])
    .handleAction(adminHeaderSetStore, (_state, action) => action.payload.ariane ?? _state)

const titleReducer = createReducer<(string | JSX.Element), RootAction>(null)
    .handleAction(adminHeaderSetStore, (_state, action) => action.payload.title ?? _state)

const accueilLinkReducer = createReducer<string, RootAction>("/")
    .handleAction(adminHeaderSetStore, (_state, action) => action.payload.accueilLink ?? _state)

const returnHeader = createReducer<boolean, RootAction>(false)
    .handleAction(adminHeaderSetStore, (_state, action) => action.payload.returnHeader ?? _state)

const returnLink = createReducer<string, RootAction>('')
    .handleAction(adminHeaderSetStore, (_state, action) => action.payload.returnLink ?? _state)

const breadcrumbsProps = createReducer<BreadcrumbsProps, RootAction>({ separator : '/'})
    .handleAction(adminHeaderSetStore, (_state, action) => action.payload.breadcrumbsProps ?? _state)


const adminHeaderReducers = {
    ariane: arianeReducer,
    title: titleReducer,
    accueilLink: accueilLinkReducer,
    returnHeader,
    returnLink,
    breadcrumbsProps
}

const adminHeaderReducer = combineReducers(adminHeaderReducers);


export default adminHeaderReducer;
