import { DeepPartial } from "redux";
import { CanauxService } from "services";
import { DeepRawify } from "types";
import { NiveauTypeChamp } from "./NiveauTypeChamp.class";

export class NiveauChamp {



    public idNiveau : number = null;

    public idNiveauTypeChamp : number = null;


    public obligatoire : number = null;

    public ordre : number = null;

    public niveauTypeChamp : NiveauTypeChamp = null;

    private canauxService = CanauxService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<NiveauChamp>>) {

        if (row) {

            const {
                idNiveau,
                idNiveauTypeChamp,
                obligatoire,
                ordre,
                niveauTypeChamp
            } = row;



            this.idNiveau = idNiveau;

            this.idNiveauTypeChamp = idNiveauTypeChamp;

            this.obligatoire = obligatoire;

            this.ordre = ordre;

            this.niveauTypeChamp = new NiveauTypeChamp(niveauTypeChamp);
        }
    }

    public toDatabaseObject(): object {
        return {
            idNiveau: this.idNiveau,
            idNiveauTypeChamp: this.idNiveauTypeChamp,
            obligatoire: this.obligatoire,
            ordre: this.ordre
        }
    }
}