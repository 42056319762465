import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ThemeProvider } from '@material-ui/core/styles';
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles, WithStyles } from '@material-ui/styles';
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { RootState } from "store/types";
import { filesIcons } from "Theme";
import { style, theme } from "./FileView.style";
import ButtonBase from '@material-ui/core/ButtonBase';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

const stateToProps = (state: RootState) => ({});

const dispatchToProps = {};

interface FileViewLocalProps {
    type: string;
    name: string;
    progressValue?: number;
    url?: string;
    removeFile?: () => void;
    admin?: boolean;
}

interface FileViewState {}

type FileViewProps = WithStyles<typeof style> & ReturnType<typeof stateToProps> & typeof dispatchToProps & FileViewLocalProps & FileViewState;

class FileView extends Component<FileViewProps, FileViewState> {
    render() {
        const { type, name, classes, progressValue, url, removeFile, admin } = this.props;        

        const isImage: boolean = type == "png" || type == "jpg" || type == "jpeg" || type == "pdf";

        const iconAndName = (
            <Fragment>
                <img className={admin ? classes.fileIconAdmin : classes.fileIcon} src={filesIcons[type] != undefined ? filesIcons[type] : filesIcons['unknown']} />
                <p className={classes.fileName}>{name}</p>
            </Fragment>
        );

        return (
            <Box>
                <ButtonBase 
                    target={"_blank"} 
                    rel={isImage ? "noopener noreferrer" : ""} 
                    href={url} 
                    onClick={(e) => {
                        if (Capacitor.isNativePlatform()) {
                            e.preventDefault();
                            Browser.open({ url });
                        }
                    }}
                    className={classes.root}>

                {admin ? (
                    <Box width="100%" minWidth={0} display="flex" justifyContent="left">
                        <Button
                            target={isImage ? "_blank" : ""}
                            rel={isImage ? "noopener noreferrer" : ""}
                            href={url}
                            className={classes.defaultButtonText}
                            disableRipple
                        >
                            {iconAndName}
                        </Button>
                    </Box>
                ) : (
                    <Box className={classes.iconAndName}>{iconAndName}</Box>
                )}

                {admin ? (
                    progressValue == 100 ? (
                        <Button className={classes.defaultButton} disableRipple onClick={removeFile}>
                            <DeleteIcon className={classes.icon} />
                        </Button>
                    ) : (
                        <ThemeProvider theme={theme}>
                            <LinearProgress variant="determinate" value={progressValue} className={classes.progressBar} />
                        </ThemeProvider>
                    )
                ) : (
                    <Button
                        target={isImage ? "_blank" : null}
                        rel={isImage ? "noopener noreferrer" : null}
                        href={url}
                        className={classes.defaultButton}
                        disableRipple
                    >
                        <GetAppIcon className={classes.icon} />
                    </Button>
                )}
                </ButtonBase>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(FileView));
