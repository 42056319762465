import Box from '@material-ui/core/Box';
import FileView from "components/fileView/FileView.component";
import { loadTelechargements } from "features/carnet_voyage/src/store/actions";
import isEqual from 'lodash/isEqual';
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useSelector } from "store/rootStore";
import useStyle from "./TelechargementsCarnet.style";

interface TelechargementsCarnetProps {
}

const TelechargementsCarnet: React.FC<TelechargementsCarnetProps> = (props: TelechargementsCarnetProps) => {
    const dispatch = useDispatch();

    const [
        files
    ] = useSelector((state) => [
        state.carnetVoyage.files
    ], isEqual);    
    
    const classes = useStyle();
    useEffect(() => { dispatch(loadTelechargements.request()) }, [])

    function showFiles() {
        const filesView: JSX.Element[] = [];
        const typeRegex = /.+\.([^\.]+)$/g;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const name = file.name;
            const type = name.replace(typeRegex, "$1");
            const url = file.idFile ? file.getSrc() : null;

            filesView.push(<FileView key={i} type={type} name={name} url={url} />);
        }

        return filesView;
    }

    return <Box className={classes.root}>{showFiles()}</Box>;
}

export default TelechargementsCarnet;
