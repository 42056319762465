import { ChallengeService } from "services"
import { DeepRawify } from "types";
import { DeepPartial } from "redux";
import { v4 as uuidv4 } from 'uuid';
import moment, { Moment } from "moment";
import { colors } from "Theme";

/**
 * Classe SuiviEnfantGlobal
 * Représente un suivi enfant sur un suivi global
 */
export class SuiviEnfantGlobal {

    public idSuiviEnfantGlobal : number = null;

    public idSuiviGlobal : number = null;

    public idSuiviEnfant : number = null;

    public libelleColonne : string = null;

    public libelle : string = null;

    public leChallenge : number = null;

    public idType : number = null;

    public dateDebut : Moment = null;

    public dateFin : Moment = null;

    public dateActualisation : Moment = null;

    public ponderation : number = null;

    public ordre : number = null;

    public checked : boolean = null;

    public estTotal : number = 0;

    public points : number = 0;

    public uuid : string = uuidv4();

    private challengeService = ChallengeService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<SuiviEnfantGlobal>>) {

        if (row) {

            const {
                idSuiviEnfantGlobal,
                idSuiviGlobal,
                idSuiviEnfant,
                libelleColonne,
                libelle,
                leChallenge,
                idType,
                dateDebut,
                dateFin,
                dateActualisation,
                ponderation,
                ordre,
                checked,
                estTotal,
                points,
            } = row;

            this.idSuiviEnfantGlobal = idSuiviEnfantGlobal;

            this.idSuiviGlobal = idSuiviGlobal;

            this.idSuiviEnfant = idSuiviEnfant;

            this.libelleColonne = libelleColonne;
            
            this.libelle = libelle;

            this.leChallenge = leChallenge;

            this.idType = idType;

            this.dateDebut =  moment(dateDebut, 'X');

            this.dateFin =  moment(dateFin, 'X');

            this.dateActualisation = dateActualisation ? moment(dateActualisation, 'X') : null;

            this.ponderation =  ponderation;

            this.ordre =  ordre;

            this.checked =  checked;
            
            this.estTotal =  estTotal;

            this.points =  points;
        }
    }

    /**
     * Définition du statut du suivi en fonction de la date
     * @returns le libellé du statut du suivi
     */
    public getStatusLabel(): string {
        const now = moment();

        if (this.dateDebut.diff(now) > 0) {
            return 'À venir';
        } if (this.dateFin.diff(now) > 0) {
            return 'En cours';
        }
        return 'Terminé';
    }

    /**
     * Définition de la couleur du statut du suivi en fonction de la date
     * @return la couleur à afficher
     */ 
    public getLabelColor() {
        const now = moment();

        if (this.dateDebut.diff(now) > 0) {
            return colors.yellow.dark;
        } if (this.dateFin.diff(now) > 0) {
            return colors.green.main;
        }
        return colors.pink.main;
    }
    /**
     * Définition de la couleur de la bordure pour le label du statut du suivi en fonction de la date
     * @returns la couleur de bordure à afficher
     */
    public getBorderLabelColor() {
        const now = moment();

        if (this.dateDebut.diff(now) > 0) {
            return colors.yellow.main;
        } if (this.dateFin.diff(now) > 0) {
            return colors.green.light;
        }
        return colors.pink.light;
    }

    public toDatabaseObject(): object {
        return {
            idSuiviEnfantGlobal: this.idSuiviEnfantGlobal,
            idSuiviGlobal: this.idSuiviGlobal,
            idSuiviEnfant: this.idSuiviEnfant,
            libelleColonne: this.libelleColonne,
            ponderation: this.ponderation,
            ordre: this.ordre,
            estTotal: this.estTotal
        }
    }
}