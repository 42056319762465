import { createAsyncAction } from "typesafe-actions";
import { TableauBordData } from "./types";

/**
 * Action de chargement des données du tableau de bord
 */
export const loadTableauBordAsync = createAsyncAction(
    'LOAD_TABLEAU_BORD_REQUEST', 
    'LOAD_TABLEAU_BORD_SUCCESS', 
    'LOAD_TABLEAU_BORD_FAILURE'
)<void, TableauBordData, string>();
