import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { WithStyles } from '@material-ui/styles';
import withStyles from '@material-ui/styles/withStyles';
import React, { Component, ComponentType, Fragment } from 'react';
import { connect } from 'react-redux';
import { removeTopSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { RootState } from 'store/types';
import { colors } from 'Theme';
import style from './SnackbarSpawner.style';

const stateToProps = ({ snackbars }: RootState) => ({
    snackbars
});

const dispatchToProps = {
    removeTopSnackbar
};

type SnackbarsSpawnerProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps;

class SnackbarSpawnerComponent extends Component<SnackbarsSpawnerProps> {

    private getBackgroundColor(type: SnackbarType): string {
        switch (type) {
            case SnackbarType.INFO:
                return colors.blue.main;

            case SnackbarType.WARNING:
                return colors.yellow.dark;

            case SnackbarType.SUCCESS:
                return colors.green.main;
        
        }
    }

    private getIcon(type: SnackbarType): ComponentType<SvgIconProps> {
        switch (type) {
            case SnackbarType.INFO:
                return InfoIcon;

            case SnackbarType.WARNING:
            case SnackbarType.ERROR:
                return ErrorIcon;

            case SnackbarType.SUCCESS:
                return CheckCircleIcon;
        }
    }

    public handleClose(): void {
        this.props.snackbars[0].props.open = false;
        setTimeout(() => this.props.removeTopSnackbar(), 100);
        this.forceUpdate();
    }

    public render(): JSX.Element {
        const { classes, snackbars } = this.props;

        const snackbar = snackbars[0];

        if (!snackbar) {
            return <Fragment />;
        }

        const { type, props } = snackbar;

        const SnackIcon = this.getIcon(type);

        return <Snackbar
            {...props}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                ...props.anchorOrigin
            }}
            onClose={this.handleClose.bind(this)}
        >
            <SnackbarContent
                action={[
                    props.action || <IconButton
                        key="close"
                        onClick={this.handleClose.bind(this)}
                        color="inherit"
                    >
                        <CloseIcon />
                    </IconButton>,

                ]}
                style={{
                    backgroundColor: this.getBackgroundColor(type),
                    flexWrap: 'nowrap'
                }}
                message={
                    <span className={classes.content}>
                        <SnackIcon />
                        {props.message}
                    </span>
                }
            />
        </Snackbar>;
    }

}

export default connect(stateToProps, dispatchToProps)(withStyles(style)(SnackbarSpawnerComponent));
