import createStyles from '@material-ui/styles/createStyles'
import { Theme } from 'Theme'
import { colors } from 'Theme';

export default ({ spacing, palette }: typeof Theme) => createStyles({
    nomCommentaire : {
        fontWeight : 800,
        fontSize : 14,
        color : 'black'
    },
    texteCommentaire : {
        fontWeight : 400,
        fontSize : 14,
        textAlign : 'left',
        wordBreak : 'break-word'
    },
    dateCommentaire : {
        color : colors.grey.main,
        fontSize : 12
    },
    answerCommentaire : {
        fontSize : 12,
        fontWeight : 700,
        marginRight : spacing(2),
        wordBreak : 'break-word'
    },
    moreIcon : {
        color : colors.grey.dark
        
    },
    actionsBox : {
        alignItems : 'center'
    },
    textFieldComment : {
        backgroundColor : 'white',
        width: '100%',
        marginRight : spacing(1),
        height : 54,
        borderRadius : 8
    },
    formComment : {
        display: 'flex',
        flexDirection : 'row',
        width : '100%',
        justifyContent : 'space-between',
        height : 54,
        marginBottom : spacing(1)
    },
    commentTextField : {
        backgroundColor : 'white',
        width: '100%',
        marginRight : spacing(1),
        height : 54,
        borderRadius : 8
    },
    sendIconButton : {
        width : 54,
        minWidth: 54,
        height : '100%',
        borderRadius : 8
    }
});