import { B64File } from 'classes/B64File.class';
import { PageContenu } from 'classes/contenus/PageContenu.class';
import cloneDeep from 'lodash/cloneDeep';
import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import {
    addFileDocuments,
    loadContenuFromChallengeAsync,
    loadContenuFromPageAsync,
    listPageContenuAsync,
    loadPresentationAsync,
    loadDocuments,
    removeFileDocuments,
    updateFileDocumentIndex,
    setProgressSaveFiles,
    setSelectedPage,
    getPageContenuAsync
} from './actions';
import { ContenuWithEverything, Document } from './types';

const files = createReducer<Document[], RootAction>([])
    .handleAction(setProgressSaveFiles, (_state, action) => {
        let newState = cloneDeep(_state);
        let i = newState.findIndex((elem) => elem.idBloc == action.payload.idBloc);
        newState[i].documents.map(obj => action.payload.b64File.localIdFile == obj.localIdFile ? action.payload.b64File : obj);
        return cloneDeep(newState);
    })
    .handleAction(addFileDocuments.request, (_state, action) => {
        let newState = cloneDeep(_state);
        let i = newState.findIndex((elem) => elem.idBloc == action.payload.idBloc);
        newState[i].documents.push(action.payload.b64File);
        return cloneDeep(newState);
    })
    .handleAction(addFileDocuments.success, (_state, action) => {
        let newState = cloneDeep(_state);
        newState.map((item: Document, index: number) => {
            let i = item.documents.findIndex((elem) => elem.localIdFile == action.payload.b64File.localIdFile);
            item.documents[i] = action.payload.b64File;
        });
        return cloneDeep(newState);
    })
    .handleAction(removeFileDocuments.success, (_state, action) => {
        let newState = cloneDeep(_state);
        newState.map((item: Document, index: number) => {
            newState[index].documents = item.documents.filter(o => o.idFile != action.payload.b64File.idFile);
        });
        return cloneDeep(newState);
    })
    .handleAction(loadDocuments.success, (_state, action) => {
        return [..._state, ...action.payload];
    })
    .handleAction(updateFileDocumentIndex.success, (state, action) => action.payload)
    .handleAction(updateFileDocumentIndex.request, (state, action) => {
        let newState = cloneDeep(state);
        newState.map((item: Document, index: number) => {
            if (action.payload.newIndex >= item.documents.length) {
                var k = action.payload.newIndex - item.documents.length + 1;
                while (k--) {
                    item.documents.push(undefined);
                }
            }
            item.documents.splice(action.payload.newIndex, 0, item.documents.splice(action.payload.oldIndex, 1)[0]);
        });
        return cloneDeep(newState);
    });

const presentation = createReducer<ContenuWithEverything, RootAction>(null)
    .handleAction([loadPresentationAsync.success], (_state, action) => cloneDeep(action.payload))
    .handleAction([loadContenuFromChallengeAsync.success], (_state, action) => cloneDeep(action.payload))
    .handleAction([loadContenuFromPageAsync.success], (_state, action) => cloneDeep(action.payload));

const pages = createReducer<PageContenu[], RootAction>([])
    .handleAction([listPageContenuAsync.success], (_state, action) => cloneDeep(action.payload));

const isLoadingPage = createReducer<boolean, RootAction>(false)
    .handleAction([listPageContenuAsync.request, getPageContenuAsync.request, loadContenuFromPageAsync.request], () => true)
    .handleAction([
        listPageContenuAsync.success, 
        listPageContenuAsync.failure, 
        getPageContenuAsync.success, 
        getPageContenuAsync.failure,
        loadContenuFromPageAsync.success,
        loadContenuFromPageAsync.failure
    ], () => false);

const selectedPage = createReducer<PageContenu | null, RootAction>(null)
    .handleAction(setSelectedPage, (_state, action) => action.payload)
    .handleAction(getPageContenuAsync.success, (_state, action) => action.payload)


export const contenuReducers = combineReducers({
    files,
    presentation,
    pages,
    isLoadingPage,
    selectedPage
});

export default contenuReducers;