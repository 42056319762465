import Axios from 'axios';
import qs from 'qs';
import { Answer } from './Answer.class';
import { Question } from './Question.class';
import { QuestionTypes } from './QuestionTypes.enum';
import quizReducer from 'features/animations/src/quiz/store/reducer';
import RootStore from 'store/rootStore';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

export class ActeurAnswer {
    public question: Question;

    public sequence: number;

    public filled: boolean;

    /**
     * Liste des questions que le joueur a à disposition
     */
    public availableAnswers: Answer[];

    /**
     * Liste des idAnswer que le joueur à répondu
     */
    public playerAnswers: (number | string)[];

    constructor(acteurAnswer?: DeepPartial<DeepRawify<ActeurAnswer>>) {
        if (acteurAnswer) {
            const {
                question, sequence, availableAnswers, playerAnswers, filled
            } = acteurAnswer;

            this.question = new Question(question);
            this.sequence = sequence;
            this.filled = filled;
            this.availableAnswers = availableAnswers?.map((answer) => new Answer(answer));
            this.playerAnswers = playerAnswers || [];
        }
    }

    public getUniqueAnswer(): Answer {
        if (this.question.type !== QuestionTypes.UNIQUE) {
            throw new TypeError('The question type is not unique');
        }

        return this.availableAnswers.find(answer => this.playerAnswers[0] === answer.idAnswer);
    }

    public getMultipleAnswers(): Answer[] {
        if (this.question.type !== QuestionTypes.MULTIPLE) {
            throw new TypeError('The question type is not multiple');
        }

        return this.availableAnswers.filter(answer => this.playerAnswers.includes(answer.idAnswer));
    }

    public getTextAnswer(): string {
        if (this.question.type !== QuestionTypes.TEXT) {
            throw new TypeError('The question type is not multiple');
        }

        return (this.playerAnswers || '') as string;
    }

    public async submitPlayerAnswers(): Promise<void> {

        const idParticipation = RootStore.getState().animations.quiz.participation.idParticipation;

        await Axios.post(
            `index.php?${qs.stringify({
                rub: 21,
                p: 3
            })}`,
            qs.stringify({
                idQuestion: this.question.idQuestion,
                idParticipation,
                playerAnswers: this.playerAnswers,
            }),
        );
    }
}
