import { combineEpics } from "redux-observable";
import CanauxEpics from "../canaux/src/store/epics";
import AdminTypesPostEpics from "../typesPost/store/epics";
import AdminLogsEpics from "../log/store/epics";



const ParametresEpics = combineEpics(
    CanauxEpics,
    AdminTypesPostEpics,
    AdminLogsEpics
);

export default ParametresEpics;
