import { MessagerieService } from 'services';
import { SearchMessageParams } from 'features/messagerie/src/store/types';
import {v4} from 'uuid'
import { MessagePersonne } from './MessagePersonne.class';
import { Type, Transform } from 'class-transformer';
import { B64File } from 'classes/B64File.class';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';
import axios from 'axios';

/**
 * Personne
 * Utilisateur de la messagerie
 */
export class Personne {

    public uid: string = v4();

    public messagerieService = MessagerieService.getInstance();

    /**
     * Dernier message échangé avec la personne, pour l'affichage
     */
    public lastMessages: MessagePersonne[];

    /**
     * Nom
     */
    public nom: string;

    /**
     * Prénom
     */
    public prenom: string;

    /**
     * L'utilisateur courant bloque-t-il cette personne ?
     */
    public estBloquee: boolean;

    /**
     * L'utilisateur courant est-il bloqué par cette personne
     */
    public vousBloque: boolean;

    /**
     * Nombre de messages non lus
     */
    public unreadMessages: number;

    /**
     * Identifiant
     */
    public idActeur: string;

    /**
     * Rôle
     */
    public role: string;

    @Transform(({value, obj}) => {
        if (obj.customAvatar) {
            if (obj.fileAvatar) {
                return obj.fileAvatar.getSrc();
            } else {
                let tmpFileAvatar = B64File.fromDb(value);
                return tmpFileAvatar.getSrc();
            }
        } else {
            return axios.defaults.baseURL + `/dist/assets/avatar/${value}.svg`
        }
    })
    public avatar: string;

    @Type(() => String)
    @Transform(({value, obj}) => {
        if (obj.customAvatar) {
            return B64File.fromDb(value);
        } else {
            return null;
        }
    }, { toClassOnly: true })
    public fileAvatar: B64File = null;

    public customAvatar: boolean;

    /**
     * Constructeur de Personne
     */
    constructor(row?: DeepPartial<DeepRawify<Personne>>) {

        if (row) {
            const { lastMessages, customAvatar, avatar, nom, 
                    prenom, unreadMessages, idActeur, role,
                    estBloquee, vousBloque } = row;

            this.nom = nom;
            this.prenom = prenom;
            this.idActeur = idActeur;
            this.unreadMessages = unreadMessages;
            this.role = role;
            this.estBloquee = estBloquee;
            this.vousBloque = vousBloque;

            this.customAvatar = customAvatar;
            if (customAvatar) {
                //TODO : C'est dégueulasse

                this.fileAvatar = B64File.fromDb(avatar);
                if (this.fileAvatar) {

                    this.avatar = this.fileAvatar.getSrc();
                } else {
                    this.customAvatar = false;
                    this.avatar = axios.defaults.baseURL + `/dist/assets/avatar/${avatar}.svg`;
                }
            } else {
                this.avatar = axios.defaults.baseURL + `/dist/assets/avatar/${avatar}.svg`;
                this.fileAvatar = null;
            }


            this.lastMessages = lastMessages.map(lastMessage => new MessagePersonne(lastMessage));

            this.sortMessages();

        }
    }

    public getAvatarUrl(): string {
        return this.avatar;
    }

    /**
     * Méthode pour charger plus de messages dans la conversation
     */
    public async loadMore() {
        const params = {
            limit: this.lastMessages.length+20,
            offset: this.lastMessages.length,
            recepteur: this.idActeur,
            onlyPerso: true,
            echangeEmetteurRecepteur: true
        }
        const messages = await this.messagerieService.searchMessages(params) as MessagePersonne[]

        for (const message of messages) {
            this.lastMessages.push(message)
        }

        this.sortMessages();
    }

    public sortMessages() {
        this.lastMessages = this.lastMessages.sort((a, b) => 
            b.dateEnvoi - a.dateEnvoi
        )
    }

    /**
     * Supprimer la conversation (tous les messages)
     */
    public delete(): Promise<Boolean> {
        return this.messagerieService.deleteAll(this.idActeur, false);
    }

    public bloquer() {
        this.vousBloque = true;
        return this.messagerieService.creationBlocage(this.idActeur);

    }

    public debloquer() {
        this.vousBloque = false;
        return this.messagerieService.suppressionBlocage(this.idActeur);
    }

}
