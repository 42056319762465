import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Toolbar from '@material-ui/core/Toolbar';
import TuneSVG from '@material-ui/icons/Tune';
import { ThemeProvider } from '@material-ui/styles';
import Axios from 'axios';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import qs from "qs";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store/rootStore';
import { blackTheme, colors } from 'Theme';
import useStyle from './Header.style';
import useTeamUpStyle from 'TeamUp.style';
import MenuIcon from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { navigationSetCurrentModule } from 'store/navigation/navigationActions';
import { push } from 'connected-react-router'
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { Theme } from 'Theme';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import ThemeCustom from 'ThemeCustom';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

interface HeaderProps {
    onClickMenu: () => void;
}

interface Canal {
    libelle: string;
    id_referentiel_metier: number;
}

interface CanalRole {
    libelle : string;
    id_role_crypte : string;
}

const Header: React.FC<HeaderProps> = props => {
    const classes = useStyle();
    const dispatch = useDispatch();

    const { onClickMenu } = props;
    const teamUpClasses = useTeamUpStyle();

    const [filterDisplayed, setFilterDisplayed] = useState(false)

    const [selectedFilter, setSelectedFilter] = useState(0)

    const { pushInstruction } = useMatomo();

    /* Menu pour le choix du référentiel métier */
    const [metierAnchor, setMetierAnchor] = React.useState<null | HTMLElement>(null);
    const metierOpen = Boolean(metierAnchor);

    /* Liste de tous les canaux */
    const [canaux, setCanaux] = useState<Canal[]>([])
    const [currentCanal, setCurrentCanal] = useState<string>("")    

    /* Menu pour le choix du référentiel métier avec un rôle */
    const [metierAnchorRole, setMetierAnchorRole] = React.useState<null | HTMLElement>(null);
    const metierOpenRole = Boolean(metierAnchorRole);

    /* Liste des canaux avec un role */
    const [canauxRole, setCanauxRole] = useState<CanalRole[]>([])

    const [
        header,
        monRole,
        router,
        unreadNotifNb,
        currentActeur
    ] = useSelector((state) => [
        state.header,
        state.acteur.monRole,
        state.router,
        state.notifications.unreadNotifNb,
        state.acteur.current
    ], isEqual);

    useEffect(() => {        
        getCanaux()
        getCanauxRole()
    }, []);

    useEffect(() => {
        if (currentActeur && monRole) {
            if (Capacitor.isNativePlatform()) {
                App.getInfo().then((info) => {
                    pushInstruction('setCustomDimension', 4, info.version)
                });
            }
             
            pushInstruction('setUserId', currentActeur.idActeur)
            pushInstruction('setCustomDimension', 1, monRole.idReferentielMetier)
            pushInstruction('setCustomDimension', 2, monRole.idReferentielNiveau)
            pushInstruction('setCustomDimension', 3, monRole.idReferentielRole)
        }
    }, [currentActeur, monRole])

    const handleMetierOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMetierAnchor(event.currentTarget);
    };

    const handleMetierClose = () => {
        setMetierAnchor(null);
    };    

    async function getCanaux() {
        await Axios.get(
            `index.php?${qs.stringify({
                rub: 400,
                p: 2
            })}`
        ).then(({ data: { content } }) => {
            setCanaux(content.canaux)
            setCurrentCanal(content.current)
        })
            .catch((data) => { });
    }

    async function setCanal(canal: number) {
        handleMetierClose();
        
        await Axios.post(
            `index.php?${qs.stringify({
                rub: 800,
                p: 9
            })}`,
            qs.stringify({
                id: canal
            })
        ).then(({ data: { content } }) => {
            if (content.hasOwnProperty('jwt')) {
                localStorage.setItem('access_token', content.jwt);               
                // Redirection vers le fil d'infos une fois le changement de référentiel métier effectué
                dispatch(push(`/fil-info/`))
                window.location.reload()
            }
        }).catch((data) => { });
    }

    const handleMetierRoleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMetierAnchorRole(event.currentTarget);
    };

    const handleMetierRoleClose = () => {
        setMetierAnchorRole(null);
    };    

    async function getCanauxRole() {
        await Axios.get(
            `index.php?${qs.stringify({
                rub: 400,
                p: 3
            })}`
        ).then(({ data: { content } }) => {            
            setCanauxRole(content.canaux)
        })
            .catch((data) => { });
    }

    async function setCanalRole(canalRole: string) {
        handleMetierRoleClose();        
        
        // Changement en passant l'id role chiffré
        await Axios.post(
            `index.php?${qs.stringify({
                rub: 800,
                p: 8
            })}`,
            qs.stringify({
                id: canalRole
            })
        ).then(({ data: { content } }) => {
            
            if (content.hasOwnProperty('jwt')) {
                localStorage.setItem('access_token', content.jwt);
                // Redirection vers le fil d'infos une fois le changement de référentiel métier effectué
                dispatch(push(`/fil-info/`))
                window.location.reload()
            }
            
        }).catch((data) => { });
        
    }

    useEffect(() => {
        setFilterDisplayed(false)
    }, [router.location.pathname]);

    function redirectToNotifications() {
        dispatch(navigationSetCurrentModule('notification'))
        dispatch(push('/notification/'))
    }

    return (

        <ThemeProvider theme={blackTheme}>
            <AppBar position="fixed" className={clsx(classes.appBar)} elevation={0}>
                <Toolbar disableGutters style={{ minHeight : 56}}>
                    <Box display='flex'>
                        {
                            header.leftComponents.length !== 0 ?
                                <div style={header.leftComponents.length !== 0 ? { display : 'flex', marginLeft : Theme.spacing(1)} : { display: 'none' }}>
                                    {header.leftComponents.map((leftComponent: JSX.Element) => leftComponent)}
                                </div>
                                :
                                <IconButton onClick={() => onClickMenu()} style={{ color: 'white' }}>
                                    <MenuIcon />
                                </IconButton>
                        }


                    </Box>
                    <Typography style={{ flexGrow: 1, textAlign : 'center', color : 'white', fontSize : 20, fontWeight : 700 }}>{header.pageTitle}</Typography>
                    <div style={{ display: 'flex' }}>
                        <ThemeProvider theme={Theme}>
                            <ThemeCustom>
                                <IconButton onClick={() => redirectToNotifications()} style={{ color: 'white' }}>
                                    <Badge invisible={!unreadNotifNb || unreadNotifNb === 0} badgeContent={unreadNotifNb} color='primary'>
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </ThemeCustom>
                        </ThemeProvider>
                        {
                            header.isPageFilter &&
                            <TuneSVG style={{ height: 24, width: 24, color: 'white', marginTop: 'auto', marginBottom: 'auto', marginRight : 10 }} onClick={() => setFilterDisplayed(!filterDisplayed)} />
                        }
                        {
                            header.rightComponents.map((rightComponent: JSX.Element) => rightComponent)
                        }
                    </div>
                    {monRole?.idReferentielRole == 1 && 
                        <>
                            <IconButton
                                aria-label="choix du référentiel métier"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMetierOpen}
                                color="inherit"
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={metierAnchor}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                // Méthode nécessaire pour faire fonctionner le transform/anchorOrigin. Issus connu de MUI
                                getContentAnchorEl={null}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={metierOpen}
                                onClose={handleMetierClose}
                            >
                                {
                                    canaux.map((item) => (
                                        <MenuItem onClick={() => setCanal(item.id_referentiel_metier)} key={item.id_referentiel_metier}>{item.libelle}</MenuItem>
                                    ))
                                }
                            </Menu>
                        </>
                    }
                    {monRole?.idReferentielRole != 1 && 
                        <>
                            <IconButton
                                aria-label="choix du référentiel métier"
                                aria-controls="menu-appbar-role"
                                aria-haspopup="true"
                                onClick={handleMetierRoleOpen}
                                color="inherit"
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="menu-appbar-role"
                                anchorEl={metierAnchorRole}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                // Méthode nécessaire pour faire fonctionner le transform/anchorOrigin. Issus connu de MUI
                                getContentAnchorEl={null}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={metierOpenRole}
                                onClose={handleMetierRoleClose}
                            >
                                {
                                    canauxRole?.map((item) => (
                                        <MenuItem onClick={() => setCanalRole(item.id_role_crypte)} key={item.id_role_crypte}>{item.libelle}</MenuItem>
                                    ))
                                }
                            </Menu>
                        </>
                    }
                    <Collapse style={{ position: 'fixed', top: 56, marginTop: 'env(safe-area-inset-top)', left: 0, width: '100%', zIndex: 10 }} in={filterDisplayed} timeout={500}>
                        <Box padding="0 14px 20px" width='100%' display='flex' bgcolor='black' alignItems='center' overflow='auto'>
                            {
                                header.listFilters.map((listFilter, index) =>
                                    <span onClick={(e) => { setSelectedFilter(selectedFilter == index ? 0 : index); listFilter.callback(); }} key={`filtre-${index}`} className={clsx(classes.listFilter, teamUpClasses.badgeAstuce)} style={selectedFilter == index ? { backgroundColor: listFilter.bgcolor, color: listFilter.color } : {
                                        backgroundColor: colors.grey.dark,
                                        color: colors.lighterGrey.main
                                    }}>{listFilter.title}</span>
                                )
                            }

                            <div style={{ width: 14, display: "list-item", visibility: "hidden" }}></div>
                        </Box>
                    </Collapse>
                </Toolbar>
            </AppBar>
            <Box height={56}></Box>
        </ThemeProvider>
    );
}

export default Header;