import Axios from 'axios';
import { B64File } from 'classes/B64File.class';
import { Notifiable } from 'classes/notifications/Notifiable.class';
import { StatisticsDisplayerBaseProps } from 'components/statisticsDisplayer/StatisticsDisplayer.component';
import moment, { Moment } from 'moment';
import qs from 'qs';
import { DeepPartial } from 'redux';
import { Observable } from 'rxjs/internal/Observable';
import { AnimationsService } from 'services';
import { Constructor, DeepRawify } from 'types';
import { AnimationStatus } from './AnimationStatus.enum';
import { AnimationTypes } from './AnimationTypes.enum';

export abstract class Animation extends Notifiable {
    public static readonly TYPES_ANIMATION: { [key: number]: Constructor<{}, Animation> } = {};

    protected animationService: AnimationsService = AnimationsService.getInstance();

    public idAnimation: number;

    public type: AnimationTypes = null;

    public typeName: string = '';

    public title: string = '';

    public summary: string = '';

    public beginDate: Moment;

    public endDate: Moment;

    public active: boolean = false;

    public frontImage: B64File;

    public descriptionImage: B64File;

    public notificationNewEnvoyee: number;

    constructor(animation?: DeepPartial<DeepRawify<Animation>>) {
        super();
        // IdeaBox;
        // LuckyWheel;
        // MysteryCards;
        // PhotoContest;
        // Quiz;
        // ScratchGame;
        if (animation) {
            const {
                idAnimation, type, typeName, title, summary, beginDate, endDate, active, notificationNewEnvoyee
            } = animation;


            if(animation.descriptionImage) {
                this.descriptionImage = B64File.fromDb(animation.descriptionImage);
            }
            if(animation.frontImage) {
                this.frontImage = B64File.fromDb(animation.frontImage);
            }
            this.idAnimation = idAnimation;
            this.title = title;
            this.type = type;
            this.typeName = typeName;
            this.summary = summary;
            this.beginDate = moment(beginDate, 'X');
            this.endDate = moment(endDate, 'X');
            this.active = active;
            this.notificationNewEnvoyee = notificationNewEnvoyee

        }
    }

    public getStatus(): AnimationStatus {
        const now = moment();

        if (this.beginDate.diff(now) > 0) {
            return AnimationStatus.INCOMING;
        } if (this.endDate.diff(now) > 0) {
            return AnimationStatus.ONGOING;
        }
        return AnimationStatus.FINISHED;
    }

    public getStatusLabel(): string {
        const status = this.getStatus();

        switch (status) {
            case AnimationStatus.INCOMING:
                return 'À venir';

            case AnimationStatus.ONGOING:
                return 'En cours';

            case AnimationStatus.FINISHED:
                return 'Terminé';
        }
    }

    public async setActive(state: boolean): Promise<void> {

        this.active = state;

        await Axios.post(
            `index.php?${qs.stringify({
                rub: 22,
                p: 1
            })}`,
            qs.stringify({
                idAnimation: this.idAnimation,
                active: this.active
            }),
        );
    }

    public toObject(): object {
        return {
            idAnimation: this.idAnimation,
            type: this.type,
            title: this.title,
            summary: this.summary,
            beginDate: this.beginDate.unix(),
            endDate: this.endDate.unix(),
            active: this.active,
            frontImage: this.frontImage,
            descriptionImage: this.descriptionImage,
            notificationNewEnvoyee: this.notificationNewEnvoyee,
        }
    };

    public save(callback: (error: string) => void): { progress: Observable<number>, animation: Observable<Animation> } {
        return this.animationService.save(this, callback);
    }

    public delete(): Promise<void> {
        return this.animationService.delete(this.idAnimation);
    }

    public duplicate(): Promise<void> {
        return this.animationService.duplicate(this.idAnimation);
    }
    public abstract getStatistics(): Promise<StatisticsDisplayerBaseProps>;


    public getID(): number {
        return this.idAnimation;
    }

    public getUrl(): string {
        return "/animation/" + this.idAnimation + '/';
    }
}
