import Axios from "axios";
import qs from 'qs';
import { Metier } from "classes/referentiel/Metier.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import { NiveauInfos, RawDroit } from "features/admin/parametres/src/canaux/src/niveaux/src/store/types";
import { NiveauTypeChamp } from "classes/referentiel/NiveauTypeChamp.class";
import { NiveauChamp } from "classes/referentiel/NiveauChamp.class";
import { Role } from "classes/referentiel/Role.class";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";
import { DeepRawify } from "types";

export class CanauxService {
    private static instance: CanauxService;

    public static getInstance(): CanauxService {
        if (!CanauxService.instance) {
            CanauxService.instance = new CanauxService();
        }

        return CanauxService.instance;
    }

    public loadCanaux(): Promise<Metier[]> {
        const params = {
            rub: 306,
            p: 1,
            sp: 1
        };

        return Axios.get<{ content: DeepRawify<Metier>[] }>(`index.php?${qs.stringify(params)}`)
            .catch(({ response: { data: { message } } }) => {
                throw message;
            })
            .then(({ data: { content: canaux } }) => canaux.map((metier) => new Metier(metier)))
    }

    public loadCanal(idReferentielMetier: number): Promise<Metier> {
        const params = {
            rub: 306,
            p: 1,
            sp: 4,
            idReferentielMetier
        };

        return Axios.get<{ content: DeepRawify<Metier> }>(`index.php?${qs.stringify(params)}`)
            .catch(({ response: { data: { message } } }) => {
                throw message;
            })
            .then(({ data: { content: canal } }) => new Metier(canal))
    }

    public saveMetier(metier: Metier): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 2
            })}`,
            qs.stringify({
                metier: JSON.stringify(metier.toDatabaseObject())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public deleteMetier(idReferentielMetier: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 3
            })}`,
            qs.stringify({ idReferentielMetier })
        )
            .then(() => true)
            .catch(() => false);
    }

    public loadNiveaux(idReferentielMetier: number): Promise<Niveau[]> {
        const params = {
            rub: 306,
            p: 1,
            sp: 5,
            idReferentielMetier
        };

        return Axios.get<{ content: DeepRawify<Niveau>[] }>(`index.php?${qs.stringify(params)}`)
            .catch(({ response: { data: { message } } }) => {
                throw message;
            })
            .then(({ data: { content: niveaux } }) => niveaux.map((niveau) => new Niveau(niveau)))
    }

    public loadCurrentRefNiveaux(): Promise<Niveau[]> {
        const params = {
            rub: 306,
            p: 1,
            sp: 19
        };

        return Axios.get<{ content: DeepRawify<Niveau>[] }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: niveaux } }) => niveaux.map((niveau) => new Niveau(niveau)));
    }

    public loadNiveau(idReferentielNiveau: number): Promise<Niveau> {
        const params = {
            rub: 306,
            p: 1,
            sp: 6,
            idReferentielNiveau
        };

        return Axios.get<{ content: DeepRawify<Niveau> }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: niveau } }) => new Niveau(niveau));
    }

    public saveNiveau(niveau: Niveau): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 7
            })}`,
            qs.stringify({
                niveau: JSON.stringify(niveau.toDatabaseObject())
            })
        )
            .then(() => true)
            .catch(() => false);
    }

    public deleteNiveau(idReferentielNiveau: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 8
            })}`,
            qs.stringify({ idReferentielNiveau })
        )
            .then(() => true)
            .catch(() => false);
    }

    public loadNiveauInfos(idReferentielNiveau: number): Promise<NiveauInfos> {
        const params = {
            rub: 306,
            p: 1,
            sp: 11,
            idReferentielNiveau
        };

        return Axios.get<{ content: DeepRawify<NiveauInfos> }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => ({
                niveau: new Niveau(content.niveau),
                champsInEntite: content.champsInEntite.map((typeChamp) => new NiveauTypeChamp(typeChamp)),
                champsInInfosEntite: content.champsInInfosEntite.map((niveauChamp) => new NiveauChamp(niveauChamp)),
                champsOutEntite: content.champsOutEntite.map((typeChamp) => new NiveauTypeChamp(typeChamp)),
                champsInActeur: content.champsInActeur.map((typeChamp) => new NiveauTypeChamp(typeChamp)),
                champsInInfosActeur: content.champsInInfosActeur.map((niveauChamp) => new NiveauChamp(niveauChamp)),
                champsOutActeur: content.champsOutActeur.map((typeChamp) => new NiveauTypeChamp(typeChamp)),
                roles: content.roles.map((role) => new Role(role)),
                caracteristiques: content.caracteristiques.map((caracteristique) => new NiveauCaracteristique(caracteristique))
            }));
    }

    public saveNiveauChamps(niveauChamps: NiveauChamp[], type: number, idReferentielNiveau: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 12
            })}`,
            qs.stringify({
                niveauChamps: JSON.stringify(niveauChamps),
                idReferentielNiveau,
                type
            })
        )
            .then(() => true)
            .catch(() => false);

    }

    public saveNiveauxCaracteristiques(niveauxCaracteristiques: NiveauCaracteristique[], idReferentielNiveau: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 13
            })}`,
            qs.stringify({
                niveauxCaracteristiques: JSON.stringify(niveauxCaracteristiques),
                idReferentielNiveau
            })
        )
            .then(() => true)
            .catch(() => false);

    }

    public loadRoles(idReferentielNiveau: number): Promise<Role[]> {
        const params = {
            rub: 306,
            p: 1,
            sp: 14,
            idReferentielNiveau
        };

        return Axios.get<{ content: DeepRawify<Role>[] }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: roles } }) => roles.map((role) => new Role(role)));

    }

    public loadRole(idReferentielRole: number): Promise<Role> {
        const params = {
            rub: 306,
            p: 1,
            sp: 15,
            idReferentielRole
        };

        return Axios.get<{ content: DeepRawify<Role> }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content: role } }) => new Role(role));

    }

    public loadRoleListeDroits(idReferentielNiveau: number): Promise<RawDroit[]> {
        const params = {
            rub: 306,
            p: 1,
            sp: 16,
            idReferentielNiveau
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => JSON.parse(content));

    }


    public saveRole(role: Role): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 17
            })}`,
            qs.stringify({
                role: JSON.stringify(role.toDatabaseObject())
            })
        )
            .then(() => true)
            .catch(() => false);

    }


    public deleteRole(idReferentielRole: number): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                p: 1,
                sp: 18
            })}`,
            qs.stringify({ idReferentielRole })
        )
            .then(() => true)
            .catch(() => false);

    }
}
