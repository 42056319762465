import Axios from 'axios';
import { AnimationTypes } from 'classes/animations/AnimationTypes.enum';
import { ConcoursPhoto } from 'classes/animations/concoursPhoto/ConcoursPhoto.class';
import { Post } from 'classes/animations/concoursPhoto/Post.class';
import qs from 'qs';
import { DeepRawify } from 'types';
import { AnimationsService } from './Animations.service';

/**
 * Le service ConcoursPhoto regroupe tous les appels au back-end.
 * Les appels sont fait avec la librairie Axios.
 * 
 * La classe implémente le pattern singleton de manière à ce
 * qu'il n'y ait qu'une seule instance de la classe, que l'on
 * obtient avec getInstance.
 */
export class ConcoursPhotoService {

    /** Unique instance de la classe */
    private static instance: ConcoursPhotoService;

    /**
     * Retourne l'unique instance de la classe, stockée dans 
     * l'attribut statique "instance". Si celui-ci n'est pas
     * initialisé, la fonction l'initialise avant de le renvoyer.
     * @returns instance unique
     */
    public static getInstance(): ConcoursPhotoService {
        if (!ConcoursPhotoService.instance) {
            ConcoursPhotoService.instance = new ConcoursPhotoService();
        }

        return ConcoursPhotoService.instance;
    }

    /**
     * Pages (correspondent avec celles de la rubrique en back)
     */
    private pages = {
        SAVE_CONCOURS_PHOTO: 1,
        DELETE_CONCOURS_PHOTO: 2,
        ADD_LIKE_POST: 3,
        REMOVE_LIKE_POST: 4,
        SAVE_POST: 5,
        DELETE_POST: 6
    };

    /**
     * Pour intéragir avec les Animations on récupère aussi
     * l'instance du service animation.
     */
    public animationsService = AnimationsService.getInstance();

    /**
     * Récupère un concours photo en back
     * @param idConcoursPhoto identifiant du concours photo
     * @returns un concours photo
     */
    public find(idConcoursPhoto: number): Promise<ConcoursPhoto> {
        return this.animationsService.find(idConcoursPhoto, AnimationTypes.PhotoContest);
    }

    /**
     * Enregistre un concours photo dans la base de données
     * @param concours objet ConcoursPhoto à enregistrer en back
     * @returns identifiant du concours photo dans la base de données
     */
    public saveConcoursPhoto(concours: ConcoursPhoto): Promise<number> {
        return Axios.post<{ content: number }>(
            // Argument 1 : URL, contenant des paramètres (après le ? ajouté automatiquement par Axios)
            `index.php?${qs.stringify({
                rub: 292,
                p: 1,
            })}`,

            // Argument 2 (pour des POST uniquement) : Body de la requête
            qs.stringify({
                concours: concours.toObject(),
            })

        )
            .then(({ data: { content } }) => content);
    }

    /**
     * Supprime un concours photo
     * @param idConcoursPhoto identifiant du concours photo
     * @returns succès de l'opération
     */
    public deleteConcoursPhoto(idConcoursPhoto: number): Promise<boolean> {
        return Axios.post<{ content: boolean }>(
            `index.php?${qs.stringify({
                rub: 292,
                p: 2
            })}`,
            qs.stringify({ idConcoursPhoto }),
        )
            .then(({ data: { content } }) => content);
    }

    /**
     * Like un post
     * @param idPost identifiant du post
     * @param errorCallback La méthode a exécuter en cas d'erreur
     * @returns succès de l'opération
     */
    public addLike(idPost: number, errorCallback: ((message: string) => void)): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 292,
                p: 3
            })}`,
            qs.stringify({
                idPost
            }),
        )
            .then(() => true)
            .catch(({ response: { data: { message } } }) => {
                errorCallback(message);

                return false;
            });
    }

    /**
     * Unlike un post
     * @param idPost identifiant du post
     * @param errorCallback La méthode a exécuter en cas d'erreur
     * @returns succès de l'opération
     */
    public removeLike(idPost: number, errorCallback: ((message: string) => void)): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 292,
                p: 4
            })}`,
            qs.stringify({
                idPost
            }),
        )
            .then(() => true)
            .catch(({ response: { data: { message } } }) => {
                errorCallback(message);

                return false;
            });
    }

    /**
     * Enregistre un post dans la base de données
     * @param post objet Post à enregistrer en back
     * @param errorCallback La méthode a exécuter en cas d'erreur
     * @returns identifiant du post dans la base de données
     */
    public savePost(post: Post, errorCallback: ((message: string) => void)): Promise<Post> {
        return Axios.post<{ content: DeepRawify<Post> }>(
            `index.php?${qs.stringify({
                rub: IS_ADMIN ? 293 : 292,
                p: IS_ADMIN ? 2 : 5,
            })}`,
            qs.stringify({
                post: JSON.stringify(post.toObject()),
            })

        )
            .then(({ data: { content } }) => new Post(content))
            .catch(({ response: { data: { message } } }) => {
                errorCallback(message);

                return null;
            });
    }

    /**
     * Supprime un post
     * @param idPost identifiant du post
     * @param errorCallback La méthode a exécuter en cas d'erreur
     * @returns succès de l'opération
     */
    public deletePost(idPost: number, errorCallback: ((message: string) => void)): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: IS_ADMIN ? 293 : 292,
                p: IS_ADMIN ? 3 : 6
            })}`,
            qs.stringify({
                idPost
            }),
        )
            .then(() => true)
            .catch(({ response: { data: { message } } }) => {
                errorCallback(message);

                return false;
            });
    }

    /**
     * Charge les posts dont les ids sont donnés en paramètre
     * @param idAnimation L'identifiant du concours photo
     * @param ids Les identifiants des publications à charger
     * @param errorCallback La méthode a exécuter en cas d'erreur
     * @returns Les posts chargés
     */
    public loadPosts(idAnimation: number, ids: Array<number>, errorCallback: ((message: string) => void)): Promise<Post[]> {
        return Axios.get<{ content: DeepRawify<Post>[] }>(
            `index.php?${qs.stringify({
                rub: 292,
                p: 8,
                idAnimation,
                ids
            })}`,
        )
            .then(({ data: { content } }) => content.map((post) => new Post(post)))
            .catch(({ response: { data: { message } } }) => {
                errorCallback(message);

                return [];
            });

    }

    public adminLoadPosts(idAnimation: number): Promise<Post[]> {
        return Axios.get<{ content: DeepRawify<Post>[] }>(
            `index.php?${qs.stringify({
                rub: 293,
                p: 1,
                idAnimation
            })}`,
        )
            .then(({ data: { content } }) => content.map((post) => new Post(post)));
    }

    /*public async userLikedPost(idPost: number): Promise<boolean> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: 292,
                p: 10,
                idPost
            })}`,
        ).then(({ data: { content } }) => content);
    }*/

    /*public async postComment(idPost: number, text: string): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 292,
                p: 11,
                idPost,
                text
            })}`,
        ).then(({ data: { content } }) => content);
    }*/

}
