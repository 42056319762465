import './team-up/js/Notifications.class'
import 'core-js/stable';
import "reflect-metadata";
import 'regenerator-runtime';
import './react/index'

if (Notifications.isSupported()) {
    console.log('Notifications are supported');
    Notifications.enableNotifications();
} else {
    console.log('Notifications are not supported');
}