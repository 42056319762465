import moment, { Moment } from 'moment';
import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import { FicheProduitCaracteristique } from './FicheProduitCaracteristique.class';

/**
 * Sauvearde les produits d'une fiche  
 */
export class FicheProduit
{
    public uid: string = v4();

    /**
     * L'identifiant de l'association
     * @var int
     */
    public idFicheProduit: number;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idFiche: number;

    /**
     * Lien de la produit
     * @var string
     */
    public libelle: string;

    /**
     * Code EAN
     * @var string
     */
    public codeEan: string;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public dateCreation: Moment;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public dateMiseAJour: Moment;

    /**
     * Liste des caractéristiquesdu produit (champ spécial)
     * @var array
     */
    public listeCara: FicheProduitCaracteristique[];


    private levelUpService = LevelUpService.getInstance();


    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FicheProduit>>) {
        if (row) {
            this.idFicheProduit = row['idFicheProduit'] ?? null;
            this.idFiche = row['idFiche'] ?? null;
            this.libelle = row['libelle'] ?? null;
            this.codeEan = row['codeEan'] ?? null;
            this.dateCreation = row['dateCreation'] ? moment(row['dateCreation'], 'X') : moment();
            this.dateMiseAJour = row['dateMiseAJour'] ? moment(row['dateMiseAJour'], 'X') : moment();
            this.listeCara = row['listeCara'] ? row['listeCara'].map((i) => new FicheProduitCaracteristique(i)) : [];
        } else {
            this.listeCara = [];
        }
    }

    public toRaw()
    {
        return {
            idFicheProduit: this.idFicheProduit,
            idFiche: this.idFiche,
            libelle: this.libelle,
            codeEan: this.codeEan,
            dateCreation: this.dateCreation && this.dateCreation.unix(),
            dateMiseAJour: this.dateMiseAJour && this.dateMiseAJour.unix(),
            listeCara: this.listeCara.map((i) => i.toRaw())
        };
    }

}
