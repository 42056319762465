import { combineEpics } from 'redux-observable';
import { from, of, iif, empty, EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, tap, take, mergeMap } from 'rxjs/operators';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import { loadNiveauxAsync, loadRoleListeDroitsAsync, loadNiveauAsync, saveNiveauChampsAsync, saveNiveauxCaracteristiquesAsync, loadNiveauRolesAsync, loadNiveauRoleAsync, loadNiveauInfosAsync, loadCurrentRefNiveauxAsync } from './actions';
import { push } from 'connected-react-router';

import { newSnackbar, SnackbarType } from "store/snackbars/snackBarsActions";


const loadNiveauxEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadNiveauxAsync.request)),
    switchMap(action => from(canauxService.loadNiveaux(action.payload)).pipe(
        map(loadNiveauxAsync.success),
        catchError((message) => of(loadNiveauxAsync.failure(message))),
    )),
);

const loadCurrentRefNiveauxEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadCurrentRefNiveauxAsync.request)),
    switchMap(action => from(canauxService.loadCurrentRefNiveaux()).pipe(
        map(loadCurrentRefNiveauxAsync.success),
        catchError((message) => of(loadCurrentRefNiveauxAsync.failure(message))),
    )),
);


const loadNiveauEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadNiveauAsync.request)),
    switchMap(action => from(canauxService.loadNiveau(action.payload)).pipe(
        map(loadNiveauAsync.success),
        catchError(message => of(loadNiveauAsync.failure(message))),
    )),
);

const loadNiveauInfosEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadNiveauInfosAsync.request)),
    switchMap(action => from(canauxService.loadNiveauInfos(action.payload)).pipe(
        map(loadNiveauInfosAsync.success),
        catchError((message) => of(loadNiveauInfosAsync.failure(message))),
    )),
);

const saveNiveauxChampsEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(saveNiveauChampsAsync.request)),
    switchMap(action => from(canauxService.saveNiveauChamps(action.payload.niveauChamps, action.payload.type, state$.value.parametres.canaux.niveaux.niveau.idReferentielNiveau)).pipe(
        mergeMap(action => [
            newSnackbar({
                type: SnackbarType.SUCCESS,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Les champs ont bien été enregistrés.`
                }
            }),
            saveNiveauChampsAsync.success(true)]
        ),
        catchError((message) => of(saveNiveauChampsAsync.failure(message))),
    )),
);

const saveNiveauxCaracteristiquesEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(saveNiveauxCaracteristiquesAsync.request)),
    switchMap(action => from(canauxService.saveNiveauxCaracteristiques(action.payload, state$.value.parametres.canaux.niveaux.niveau.idReferentielNiveau)).pipe(
        mergeMap(action => [
            newSnackbar({
                type: SnackbarType.SUCCESS,
                props: {
                    open: true,
                    autoHideDuration: 5000,
                    message: `Les caractéristiques ont bien été enregistrées.`
                }
            }),
            saveNiveauxCaracteristiquesAsync.success(true)]
        ),
        catchError((message) => of(saveNiveauxCaracteristiquesAsync.failure(message))),
    )),
);


const loadRolesEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadNiveauRolesAsync.request)),
    switchMap(action => from(canauxService.loadRoles(action.payload)).pipe(
        map(loadNiveauRolesAsync.success),
        catchError((message) => of(loadNiveauRolesAsync.failure(message))),
    )),
);

const loadRoleEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadNiveauRoleAsync.request)),
    switchMap(action => from(canauxService.loadRole(action.payload)).pipe(
        map(loadNiveauRoleAsync.success),
        catchError(message => of(loadNiveauRoleAsync.failure(message))),
    )),
);

const loadRoleListeDroitsEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
    filter(isActionOf(loadRoleListeDroitsAsync.request)),
    switchMap(action => from(canauxService.loadRoleListeDroits(state$.value.parametres.canaux.niveaux.niveau.idReferentielNiveau)).pipe(
        map(loadRoleListeDroitsAsync.success),
        catchError(message => of(loadRoleListeDroitsAsync.failure(message))),
    )),
);


// const saveRoleAndRightsEpics: RootEpic = (action$, state$, { canauxService }) => action$.pipe(
//     filter(isActionOf(saveRoleWithRightsAsync.request)),
//     switchMap(action => from(canauxService.saveRoleAndRights(action.payload, state$.value.parametres.canaux.niveaux.niveau.idReferentielNiveau)).pipe(
//         mergeMap(action => [
//             newSnackbar({
//                 type: SnackbarType.SUCCESS,
//                 props: {
//                     open: true,
//                     autoHideDuration: 5000,
//                     message: `Le rôle a bien été enregistré.`
//                 }
//             }),
//             saveRoleWithRightsAsync.success(true)]
//         ),
//         catchError((message) => of(saveRoleWithRightsAsync.failure(message))),
//     )),
// );

const NiveauxEpics = combineEpics(
    loadNiveauxEpics,
    loadNiveauEpics,
    loadNiveauInfosEpics,
    saveNiveauxChampsEpics,
    saveNiveauxCaracteristiquesEpics,
    loadRolesEpics,
    loadRoleEpics,
    loadRoleListeDroitsEpics,
    loadCurrentRefNiveauxEpics
);

export default NiveauxEpics;
