import { from, of } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import { loadStatsAsync, loadMursAsync, loadStatsGlobalesAsync } from "./actions";

const loadStatsEpics: RootEpic = (action$, state$, { statistiquesService }) => action$.pipe(
    filter(isActionOf(loadStatsAsync.request)),
    switchMap((action) => from(statistiquesService.loadStats(action.payload)).pipe(
        map(loadStatsAsync.success),
        catchError(message => of(loadStatsAsync.failure(message))),
    )),
);

const loadMursEpic: RootEpic = (action$, state$, { statistiquesService }) => action$.pipe(
    filter(isActionOf(loadMursAsync.request)),
    switchMap((action) => from(statistiquesService.loadMurs()).pipe(
        map(loadMursAsync.success),
        catchError(message => of(loadMursAsync.failure(message))),
    )),
);

const loadStatsGlobalesEpic: RootEpic = (action$, state$, { statistiquesService }) => action$.pipe(
    filter(isActionOf(loadStatsGlobalesAsync.request)),
    switchMap((action) => from(statistiquesService.loadStatsGlobales()).pipe(
        map(loadStatsGlobalesAsync.success),
        catchError(message => of(loadStatsGlobalesAsync.failure(message))),
    )),
);

const StatistiquesEpics = combineEpics(
    loadStatsEpics,
    loadMursEpic,
    loadStatsGlobalesEpic
);

export default StatistiquesEpics;
