import Axios from 'axios';
import qs from 'qs';
import { Contenu } from 'classes/contenus/Contenu.class';
import { ContenuLigne } from 'classes/contenus/ContenuLigne.class';
import { ContenuBloc } from 'classes/contenus/ContenuBloc.class';
import { ContenuBlocBouton } from 'classes/contenus/types_blocs/ContenuBlocBouton.class';
import { ContenuBlocCarte } from 'classes/contenus/types_blocs/ContenuBlocCarte.class';
import { ContenuBlocImage } from 'classes/contenus/types_blocs/ContenuBlocImage.class';
import { ContenuBlocReseaux } from 'classes/contenus/types_blocs/ContenuBlocReseaux.class';
import { ContenuBlocTexte } from 'classes/contenus/types_blocs/ContenuBlocTexte.class';
import { ContenuBlocVideo } from 'classes/contenus/types_blocs/ContenuBlocVideo.class';
import { ContenuWithEverything, TContenuBloc } from 'features/contenus/src/store/types';
import { ContenuBlocSeparateur } from 'classes/contenus/types_blocs/ContenuBlocSeparateur.class';
import { ContenuBlocDotations } from 'classes/contenus/types_blocs/ContenuBlocDotations.class';
import { ContenuBlocDocuments } from 'classes/contenus/types_blocs/ContenuBlocDocuments.class';
import { rxAxios, RxAxios } from 'services/RxAxios';
import { B64File } from 'classes/B64File.class';
import { Document } from 'features/contenus/src/store/types';
import { Challenge } from 'classes/challenges/Challenge.class';
import { ContenuDotation } from 'classes/contenus/ContenuDotation.class';
import { DeepRawify } from 'types';
import { PageContenu } from 'classes/contenus/PageContenu.class';

const rub = IS_ADMIN ? 2910 : 291;

export class ContenusService {
    private static instance: ContenusService;

    public static getInstance(): ContenusService {
        if (!ContenusService.instance) {
            ContenusService.instance = new ContenusService();
        }

        return ContenusService.instance;
    }

    /* Utils */

    /**
     * Transforme l'array row en bloc en utilisant le bon sous type
     * @param row contenu du bloc
     */
    public mapToBloc(row: DeepRawify<TContenuBloc>): ContenuBloc {
        switch (row.idTypeBloc) {
            case 1:
                return new ContenuBlocBouton(row);
            case 2:
                return new ContenuBlocCarte(row);
            case 3:
                return new ContenuBlocImage(row);
            case 4:
                return new ContenuBlocReseaux(row);
            case 5:
                return new ContenuBlocTexte(row);
            case 6:
                return new ContenuBlocVideo(row);
            case 7:
                return new ContenuBlocSeparateur(row);
            case 8:
                return new ContenuBlocDotations(row);
            case 9:
                return new ContenuBlocDocuments(row);
        }
    }

    /**
     * Retoure true si l'utilisateur est connecté
     */
    public isLoggedIn(): boolean {
        return window.localStorage.getItem('access_token') !== null;
    }

    /* Contenus */

    /**
     * Envoie une requête pour supprimer un contenu en BDD
     * @param idContenu identifiant du contenu à supprimer
     */
    public deleteContenu(idContenu: number): Promise<Boolean> {
        const params = {
            rub,
            p: 2,
            idContenu
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    /**
     * Sauvegarde un contenu en base de donnée
     * @param contenu objet Contenu à sauvegarder
     * @param idChallenge éventuel identifiant du challenge associé
     */
    public saveContenu(contenu: Contenu, idChallenge?: number): Promise<number> {
        const params = {
            rub,
            p: 3,
            idChallenge
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ contenu: JSON.stringify(contenu.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }


    /* Lignes */

    public deleteLigne(idLigne: number): Promise<Boolean> {
        const params = {
            rub,
            p: 6,
            idLigne
        };
        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    public saveLigne(ligne: ContenuLigne): Promise<number> {
        const params = {
            rub,
            p: 7,
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ ligne: JSON.stringify(ligne.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }


    /* Blocs */

    public deleteBloc(idBloc: number): Promise<Boolean> {
        const params = {
            rub,
            p: 10,
            idBloc
        };
        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: ok } }) => ok);
    }

    public async saveBloc(bloc: ContenuBloc): Promise<number> {
        const params = {
            rub,
            p: 11,
        };

        return await Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ bloc: JSON.stringify(bloc.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }


    /**
     * Pour charger toutes les infos d'un challenge,
     * y compris ses blocs et lignes, tout en un
     * @param leContenu identifiant du contenu à charger
     */
    public loadContenuWithEverything(leContenu: number): Promise<ContenuWithEverything> {
        const params = {
            rub: this.isLoggedIn() ? rub : (IS_ADMIN ? 10 : 1),
            p: 12,
            leContenu
        };

        return Axios.get<{ content: DeepRawify<ContenuWithEverything> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => ({
                contenu: content.contenu ? new Contenu(content.contenu) : new Contenu(),
                lignes: content.contenu ? content.lignes.map((lignesEtBlocs) => ({
                    ligne: new ContenuLigne(lignesEtBlocs.ligne),
                    blocs: lignesEtBlocs.blocs.map((bloc) => this.mapToBloc(bloc)),
                    blocsSupprimes: []
                })) : [],
                lignesSupprimees: []
            }))
    }

    public loadChallengeFromContenu(idContenu: number): Promise<Challenge> {
        const params = {
            rub,
            p: 14,
            idContenu
        };

        return Axios.get<{ content: DeepRawify<Challenge> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: challenge } }) => new Challenge(challenge));
    }

    public loadContenuFromChallenge(idChallenge: number): Promise<ContenuWithEverything> {
        const params = {
            rub,
            p: 15,
            idChallenge
        };

        return Axios.get<{ content: number }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: id } }) => this.loadContenuWithEverything(id));
    }

    public loadContenuFromPage(urlPage: string): Promise<ContenuWithEverything> {
        const params = {
            rub: this.isLoggedIn() ? rub : (IS_ADMIN ? 10 : 1),
            p: 30,
            urlPage
        };

        return Axios.get<{ content: number }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content: id } }) => this.loadContenuWithEverything(id));
    }

    /** Special bloc documents */

    public loadDocuments(idBloc: number): Promise<Document[]> {
        const params = {
            rub,
            p: 20,
            idBloc: idBloc
        };

        const docs: Document[] = [];

        return Axios.get(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => {
                docs.push({ idBloc, documents: B64File.rowsToArray(content) });
                return docs;
            });
    }

    public addFileDocuments(b64File: B64File, idBloc: number): RxAxios<{ content: B64File }> {
        return rxAxios({
            method: 'post',
            url: `index.php?${qs.stringify({
                rub,
                p: 21

            })}`,
            data: qs.stringify({
                b64File: JSON.stringify(b64File),
                idBloc
            })
        });
    }

    public removeFileDocuments(b64File: B64File, idBloc: number): Promise<{ b64File: B64File, idBloc: number }> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 22
            })}`,
            qs.stringify({
                b64File: JSON.stringify(b64File)
            })
        ).then(({ data: { content } }) => {
            return { idBloc, b64File: B64File.rowsToFile(content) };
        });
    }

    public updateFileDocumentIndex(index: { oldIndex: number, newIndex: number, idBloc: number }): Promise<Document[]> {
        const docs: Document[] = [];

        return Axios.post(
            `index.php?${qs.stringify({
                rub: rub,
                p: 23
            })}`,
            qs.stringify({
                oldIndex: index.oldIndex,
                newIndex: index.newIndex,
                idBloc: index.idBloc
            })
        ).then(({ data: { content } }) => {
            docs.push({ idBloc: index.idBloc, documents: B64File.rowsToArray(content) });
            return docs;
        });
    }

    public deleteDotation(idDotation: number): Promise<Boolean> {
        const params = {
            rub,
            p: 24,
            idDotation
        };

        return Axios.get(`index.php?${qs.stringify(params)}`)
    }

    /**
     * Sauvegarde une dotation en base de donnée
     * @param dotation objet ContenuDotation à sauvegarder
     */
    public saveDotation(dotation: ContenuDotation): Promise<number> {
        const params = {
            rub,
            p: 25,
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ dotation: JSON.stringify(dotation.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }

    /* Pages */

    /**
     * Sauvegarde une page en base de donnée
     * @param page objet PageContenu à sauvegarder
     */
    public savePage(page: PageContenu): Promise<number> {
        const params = {
            rub,
            p: 31,
        };

        return Axios.post<{ content: number }>(`index.php?${qs.stringify(params)}`, qs.stringify({ page: JSON.stringify(page.toRaw()) }))
            .then(({ data: { content: id } }) => id);
    }

    /**
     * Liste des pages
     */
    public listPage(): Promise<PageContenu[]> {
        const params = {
            rub: this.isLoggedIn() ? rub : (IS_ADMIN ? 10 : 1),
            p: 32,
        };

        return Axios.get<{ content: DeepRawify<PageContenu>[] }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content.map((page) => new PageContenu(page)));
    }

    /**
     * Liste des pages
     */
    public getPage(idPage: number): Promise<PageContenu> {
        const params = {
            rub,
            p: 34,
            idPage
        };

        return Axios.get<{ content: DeepRawify<PageContenu> }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => new PageContenu(content));
    }

    /**
     * Supprime une page en base de donnée
     */
    public deletePage(idPage: number): Promise<boolean> {
        const params = {
            rub,
            p: 33,
            idPage
        };

        return Axios.get<{ content: boolean }>(`index.php?${qs.stringify(params)}`)
            .then(({ data: { content } }) => content);
    }

}