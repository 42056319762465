import { from, of } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import { saveTaskAsync, deleteTaskAsync, loadTasksAsync, sortTasksAsync } from "./actions";

const createTaskEpic: RootEpic = (action$, state$, { todoListService }) => action$.pipe(
    filter(isActionOf(saveTaskAsync.request)),
    switchMap((action) => from(todoListService.createTask(action.payload)).pipe(
        map(saveTaskAsync.success),
        catchError(message => of(saveTaskAsync.failure(message))),
    )),
);

const deleteTaskEpic: RootEpic = (action$, state$, { todoListService }) => action$.pipe(
    filter(isActionOf(deleteTaskAsync.request)),
    switchMap((action) => from(todoListService.deleteTask(action.payload)).pipe(
        map(deleteTaskAsync.success),
        catchError(message => of(deleteTaskAsync.failure(message))),
    )),
);

const loadTasksEpic: RootEpic = (action$, state$, { todoListService }) => action$.pipe(
    filter(isActionOf(loadTasksAsync.request)),
    switchMap((action) => from(todoListService.loadTasks()).pipe(
        map(loadTasksAsync.success),
        catchError(message => of(loadTasksAsync.failure(message))),
    )),
);

const sortTasksEpic: RootEpic = (action$, state$, { todoListService }) => action$.pipe(
    filter(isActionOf(sortTasksAsync.request)),
    switchMap((action) => from(todoListService.sortTasks(action.payload)).pipe(
        map(sortTasksAsync.success),
        catchError(message => of(sortTasksAsync.failure(message))),
    )),
);


const TableauBordEpics = combineEpics(
    createTaskEpic,
    deleteTaskEpic,
    loadTasksEpic,
    sortTasksEpic
);

export default TableauBordEpics;
