import { Badge } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { withStyles, WithStyles } from '@material-ui/styles';
import axios from 'axios';
import { Acteur } from 'classes/Acteur.class';
import { CarnetParticipant } from 'classes/carnet_voyage/participants/CarnetParticipant.class';
import classNames from 'classnames';
import React, { Component } from 'react';
import styles from './Avatar.style';

export type AvatarVariant = 'normal' | 'medium' | 'large' | 'front';
export type BorderAvatarVariant = 'none' | 'thin' | 'thick';

interface AvatarOptionnalProps {
    variant?: AvatarVariant;
    border? : BorderAvatarVariant;
}

export interface AvatarProps extends AvatarOptionnalProps, WithStyles<typeof styles> {
    /**
     * Nom de l'image SVG de l'avatar à afficher
     */
    acteur: Acteur | CarnetParticipant;
    /*
     * Affiche un badge (pastille orange)
     */
    badge?: boolean;
}

class AvatarComponent extends Component<AvatarProps> {

    public static defaultProps: AvatarOptionnalProps = {
        variant: 'normal',
        border : 'none'
    };

    render(): JSX.Element {
        const { classes, acteur, variant, border, badge } = this.props;
        const avatarUrl = acteur ? acteur.getAvatarUrl() : axios.defaults.baseURL + '`/dist/assets/avatar/tete01.svg`';
        return (
            <Badge color="primary" variant="dot" invisible={!badge} overlap="circular" anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
                <Avatar src={avatarUrl} className={classNames(classes.root, classes.image, classes[variant], classes[border] )} />
            </Badge>
        );
    } 

}

export default withStyles(styles)(AvatarComponent);
