import { DeepPartial } from "redux";
import { DeepRawify } from "types";

export class Log {

    public idLog: number = null;

    public date: string;

    public message: string = null;

    public message2: string = null;

    /**
     * 
     *  Constructeur d'une ligne de log
     * @param  mixed newLog
     * @return void
     */
    constructor(newLog?: DeepPartial<DeepRawify<Log>>) {

        if (newLog) {
            const {
                idLog,
                date,
                message,
                message2,
            } = newLog;

            this.idLog = idLog
            this.date = date;
            this.message = message
            this.message2 = message2
        }
    }
}