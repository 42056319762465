import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import animationsReducer from '../features/animations/src/store/reducer';
import adminAnimationsReducer from '../features/admin/animations/src/store/reducer';
import challengesReducer from '../features/challenges/src/store/reducer'
import acteurReducer from './acteur/acteurReducer';
import snackbarsReducer from './snackbars/snackbarsReducer';
import referentielMetierReducer from './referentielMetier/referentielMetierReducer';
import mursReducer from 'features/murs/src/store/reducer';
import carnetVoyageReducer from '../features/carnet_voyage/src/store/reducer';
import adminHeaderReducer from './adminHeader/adminHeaderReducer';
import notificationsReducer from './notifications/notificationsReducer'
import rechercheReducer from 'features/admin/recherche/src/store/reducer';
import statistiquesReducer from 'features/admin/statistiques/src/store/reducer';
import signalementsReducer from 'features/admin/signalements/src/store/reducer'
import backParametresReducer from 'features/admin/parametres/src/store/reducer'
import frontParametresReducer from 'features/parametres/src/store/reducer'
import participantsReducer from 'features/admin/participants/src/store/reducer'
import tableauBordReducer from 'features/admin/tableau_bord/src/store/reducer'
import messagerieReducer from 'features/messagerie/src/store/reducer'
import contenusReducer from 'features/contenus/src/store/reducer'
import errorReducer from './error/errorReducer'
import navigationReducer from './navigation/navigationReducer'
import themeReducer from './theme/themeReducer'
import videoReducer from '../features/video/src/store/reducer'
import headerReducer from '../features/header/src/store/reducer'
import todoListReducer from '../features/todolist/src/store/reducer'
import levelUpReducer from '../features/level_up/src/store/reducer'

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    animations: animationsReducer,
    challenges: challengesReducer,
    adminAnimations: adminAnimationsReducer,
    acteur: acteurReducer,
    snackbars: snackbarsReducer,
    murs : mursReducer,
    referentielMetier : referentielMetierReducer,
    carnetVoyage : carnetVoyageReducer,
    adminHeader : adminHeaderReducer,
    notifications : notificationsReducer,
    signalements : signalementsReducer,
    recherche : rechercheReducer,
    statistiques: statistiquesReducer,
    parametres : combineReducers({...frontParametresReducer, ...backParametresReducer}),
    error : errorReducer,
    navigation : navigationReducer,
    participants : participantsReducer,
    video: videoReducer,
    header : headerReducer,
    contenus : contenusReducer,
    messagerie: messagerieReducer,
    tableauBord: tableauBordReducer,
    todoList: todoListReducer,
    levelUp: levelUpReducer,
    theme: themeReducer
});

export default rootReducer;
