import { createAsyncAction, createAction } from "typesafe-actions";
import { Metier } from "classes/referentiel/Metier.class";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";

export const loadCanauxAsync = createAsyncAction("LOAD_CANAUX_REQUEST", "LOAD_CANAUX_SUCCESS", "LOAD_CANAUX_FAILURE")<
    void,
    Metier[],
    string
>();

export const loadCanalAsync = createAsyncAction("LOAD_CANAL_REQUEST", "LOAD_CANAL_SUCCESS", "LOAD_CANAL_FAILURE")<
    number,
    Metier,
    string
>();


export const setCanal = createAction('SET_CANAL')<Metier>();

export * from '../niveaux/src/store/actions'
