import { from, of } from "rxjs";
import { filter, switchMap, catchError, map, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { combineEpics } from "redux-observable";
import { RootEpic } from "store/types";
import { loadTableauBordAsync } from "./actions";

const loadTableauBordEpic: RootEpic = (action$, state$, { tableauBordService }) => action$.pipe(
    filter(isActionOf(loadTableauBordAsync.request)),
    switchMap((action) => from(tableauBordService.loadTableauBord()).pipe(
        map(loadTableauBordAsync.success),
        catchError(message => of(loadTableauBordAsync.failure(message))),
    )),
);

const TableauBordEpics = combineEpics(
    loadTableauBordEpic
);

export default TableauBordEpics;
