import Box from '@material-ui/core/Box'
import { WithStyles, withStyles } from '@material-ui/styles';
import DisplayPosts from 'features/murs/src/DisplayPosts.component'
import CreatePost from 'features/murs/src/posts/CreatePost.hook.component'
import { loadMurInfos, setTypeMur } from 'features/murs/src/store/actions'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { loadNotificationsAsync } from 'store/notifications/notificationsActions'
import { RootState } from 'store/types'
import style from './SouvenirsSettingsCarnet.style'


const stateToProps = ({ notifications, murs, carnetVoyage: { carnet } }: RootState) => ({
    typeMur: murs.typeMur,
    isLoadingMur: murs.isLoading,
    carnet
})

const dispatchToProps = {

    loadMurInfos: loadMurInfos.request,
    setTypeMur: setTypeMur,
    loadNotifications: loadNotificationsAsync.request
}

interface SouvenirsSettingsCarnetLocalProps {
}

interface SouvenirsSettingsCarnetState {
}

type SouvenirsSettingsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & SouvenirsSettingsCarnetLocalProps
    & RouteComponentProps<{ id: string }>;


class SouvenirsSettingsCarnet extends Component<SouvenirsSettingsCarnetProps, SouvenirsSettingsCarnetState> {

    readonly state: SouvenirsSettingsCarnetState = {
        alreadyLoaded: false,
        confirmClearDialogOpen: false
    }

    constructor(props: SouvenirsSettingsCarnetProps) {
        super(props);
        const { typeMur,
            setTypeMur,
            loadMurInfos } = props;
        if (typeMur != 3) {
            setTypeMur(3);
        }
        loadMurInfos();
    }

    render() {
        const { classes, isLoadingMur } = this.props;

        return (
            <Box marginLeft={-2}>
                <DisplayPosts noLeftPanel />
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(SouvenirsSettingsCarnet));