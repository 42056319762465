import { DeepPartial } from 'redux';
import { DeepRawify } from 'types';
import {v4} from 'uuid'
import { ContenuBloc } from '../ContenuBloc.class';


export class ContenuBlocSeparateur extends ContenuBloc {

    public uid: string = v4();

    constructor(row?: DeepPartial<DeepRawify<ContenuBlocSeparateur>>) {
        super(row);
    }

    public toRaw() {
        return {
            idBloc : this.idBloc,
            laLigne : this.laLigne,
            indexBloc : this.indexBloc,
            idTypeBloc : this.idTypeBloc
        };
    }

    public save(): Promise<number> {
        return this.contenusService.saveBloc(this);
    }

    public delete(): Promise<Boolean> {
        return this.contenusService.deleteBloc(this.idBloc);
    }

    public isEmpty() : boolean {
        return false;
    }


}
