import { Acteur } from "classes/Acteur.class";
import { DeepPartial } from "redux";
import { MursService } from "services";
import { SignalementsService } from "services/signalements";
import { DeepRawify } from "types";

export class Commentaire {

    public idMurCommentaire: number;

    public idReferentielActeur: string;

    public acteur: Acteur;

    public idReferentielMetier: number;

    public idMurPost: number;

    public idCommentaireReponse: number;

    public belleDateCreationCommentaire: string;

    public actif: number;

    public commentaire: string;

    public currentIsAuteur: boolean;


    private murService = MursService.getInstance();

    private signalementsService = SignalementsService.getInstance();


    constructor(comment?: DeepPartial<DeepRawify<Commentaire>>) {

        if (comment) {
            const {
                idMurCommentaire,
                idReferentielActeur,
                acteur,
                idReferentielMetier,
                idMurPost,
                idCommentaireReponse,
                belleDateCreationCommentaire,
                actif,
                commentaire,
                currentIsAuteur
            } = comment;

            this.idMurCommentaire = idMurCommentaire;
            this.idReferentielActeur = idReferentielActeur;
            this.acteur = new Acteur(acteur);
            this.idReferentielMetier = idReferentielMetier;
            this.idMurPost = idMurPost;
            this.idCommentaireReponse = idCommentaireReponse;
            this.belleDateCreationCommentaire = belleDateCreationCommentaire;
            this.actif = actif;
            this.commentaire = commentaire;
            this.currentIsAuteur = !!currentIsAuteur;
        }
    }

    public async delete(typeMur: number): Promise<number> {
        return this.murService.deleteCommentaire(typeMur, this.idMurCommentaire);
    }

    public async saveText(typeMur: number): Promise<number> {
        return this.murService.saveCommentaireText(typeMur, this.idMurCommentaire, this.commentaire);
    }

    public async signaler(typeMur: number, raison: number, description: string): Promise<boolean> {
        return this.signalementsService.signalerComment(typeMur, this.idMurCommentaire, raison, description);
    }
}