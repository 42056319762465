import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import Axios from 'axios'
import { ReponseSondage } from "classes/murs/ReponseSondage.class"
import AvatarComponent from "components/avatar/Avatar.component"
import qs from 'qs'
import React, { Component } from "react"
import { DeepRawify } from 'types'
import style from './AVoteSondageDrawer.style'
import { Post } from 'classes/murs/Post.class';

interface BaseProps {
    triggered: boolean;
    onClose: (() => void);
    post: Post;
    idReponse: number;
}

interface AVoteSondageDrawerState {
    open: boolean;
    votants: ReponseSondage[];
    isLoading: boolean;
}

type AVoteSondageDrawerProps = BaseProps & WithStyles<typeof style>;

class AVoteSondageDrawerComponent extends Component<AVoteSondageDrawerProps, AVoteSondageDrawerState> {

    public readonly state: AVoteSondageDrawerState = {
        open: false,
        votants: [],
        isLoading: false
    };


    constructor(props: AVoteSondageDrawerProps) {
        super(props);
        //TODO : Affichage particulier quand aucune réponse au sondage?
    }

    componentDidMount() {
        if (this.props.triggered && !this.state.open) {
            this.setState({ open: true, isLoading: true });
            this.loadVotants();
        }

    }

    async loadVotants() {
        const {post, idReponse } = this.props;
        const params = {
            rub: IS_ADMIN ? 240 : 24,
            typeMur: post.typeMur,
            idMurPost: post.idMurPost,
            idReponse,
            p: 14
        };

        this.setState({
            votants: await Axios.get<{ content: DeepRawify<ReponseSondage>[] }>(`index.php?${qs.stringify(params)}`)
                .then(({ data: { content: reponsesSondages } }) => reponsesSondages.map((reponseSondage) => new ReponseSondage(reponseSondage))),
            isLoading: false
        })
    }

    public componentDidUpdate(prevProps: AVoteSondageDrawerProps): void {
        if (this.props.triggered != prevProps.triggered && this.props.triggered && !this.state.open) {
            this.setState({ open: true, isLoading: true });
            this.loadVotants();
        }

    }

    public closeDrawer() {
        this.props.onClose();
        this.setState({
            open: false
        });
    }


    public render(): JSX.Element {
        const { open, votants, isLoading } = this.state;
        const { classes } = this.props;
        return <Drawer
            anchor="bottom"
            open={open}
            onClose={() => this.closeDrawer()}
            PaperProps={{ className: classes.drawer }}
        >
            {isLoading ?
                <Box display='flex' height={200}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </Box>
                :

                <List>
                    {votants.map((reponseSondage, index) =>
                        <ListItem divider >
                            <ListItemAvatar>
                                <AvatarComponent acteur={reponseSondage.acteur} />
                            </ListItemAvatar>
                            <ListItemText primaryTypographyProps={{ className: classes.textActeur }}>
                                {reponseSondage.acteur.prenom} {reponseSondage.acteur.nom}
                            </ListItemText>
                            <ListItemSecondaryAction className={classes.textDate}>
                                {reponseSondage.courteDateCreationReponse}
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            }
        </Drawer>;
    }
}

export default withStyles(style)(AVoteSondageDrawerComponent);