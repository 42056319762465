import Axios from 'axios';
import { B64File } from 'classes/B64File.class';
import { combineEpics } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { newSnackbar, SnackbarType } from 'store/snackbars/snackBarsActions';
import { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    addFileDocuments,
    loadChallengeFromContenuAsync,
    loadContenuFromChallengeAsync,
    loadContenuFromPageAsync,
    loadPresentationAsync,
    loadDocuments,
    listPageContenuAsync,
    removeFileDocuments,
    setProgressSaveFiles,
    updateFileDocumentIndex,
    getPageContenuAsync
} from './actions';

const loadDocumentsEpics: RootEpic = (action$, state$, { contenusService }) =>
    action$.pipe(
        filter(isActionOf(loadDocuments.request)),
        mergeMap((action) =>
            from(contenusService.loadDocuments(action.payload)).pipe(
                map(loadDocuments.success),
                catchError(message => of(loadDocuments.failure(message)))
            )
        ),
    );

const addFileDocumentsEpics: RootEpic = (action$, state$, { contenusService }) =>
    action$.pipe(
        filter(isActionOf(addFileDocuments.request)),
        map((action) => {
            const rxAxios = contenusService.addFileDocuments(action.payload.b64File, action.payload.idBloc);
            return { action, rxAxios }
        }),
        mergeMap(({ action, rxAxios: { progress, response } }) => merge(
            progress.pipe(
                map((progress) => {
                    const file: B64File = action.payload.b64File;
                    file.progress = progress;
                    return setProgressSaveFiles({ idBloc: action.payload.idBloc, b64File: file });
                }),
            ),
            response.pipe(
                mergeMap(({ content }) => [newSnackbar({
                    type: SnackbarType.SUCCESS,
                    props: {
                        open: true,
                        autoHideDuration: 2000,
                        message: `Le fichier a bien été ajouté.`,
                    }
                }),
                addFileDocuments.success({ idBloc: action.payload.idBloc, b64File: <B64File>content } )]),
            ),
        )),
        catchError((error) => {
            newSnackbar({
                type: SnackbarType.WARNING,
                props: {
                    open: true,
                    autoHideDuration: 2000,
                    message: `Il y a eu un problème dans la sauvegarde des fichiers.`,
                }
            });
            return of(addFileDocuments.failure(error))
        })
    );


const removeFileDocumentsEpics: RootEpic = (action$, state$, { contenusService }) =>
    action$.pipe(
        filter(isActionOf(removeFileDocuments.request)),
        mergeMap(action =>
            from(contenusService.removeFileDocuments(action.payload.b64File, action.payload.idBloc)).pipe(
                mergeMap(action => [
                    newSnackbar({
                        type: SnackbarType.SUCCESS,
                        props: {
                            open: true,
                            autoHideDuration: 2000,
                            message: `Le fichier a bien été supprimé.`
                        }
                    }),
                    removeFileDocuments.success(action)
                ]),
                catchError(message => of(removeFileDocuments.failure(message)))
            )
        )
    );

const updateFileDocumentIndexEpics: RootEpic = (action$, state$, { contenusService }) =>
    action$.pipe(
        filter(isActionOf(updateFileDocumentIndex.request)),
        switchMap((action) =>
            from(contenusService.updateFileDocumentIndex(action.payload)).pipe(
                map(updateFileDocumentIndex.success),
                catchError(message => of(updateFileDocumentIndex.failure(message)))
            )
        ),
    );

const getPresentationEpics: RootEpic = (action$, state$, { contenusService }) => action$.pipe(
    filter(isActionOf(loadPresentationAsync.request)),
    switchMap((action) => from(contenusService.loadContenuWithEverything(action.payload)).pipe(
        map(loadPresentationAsync.success),
        catchError(message => of(loadPresentationAsync.failure(message)))
    ))
);

// loadChallengeFromContenuAsync
const getChallengeFromContenuEpics: RootEpic = (action$, state$, { contenusService }) => action$.pipe(
    filter(isActionOf(loadChallengeFromContenuAsync.request)),
    switchMap((action) => from(contenusService.loadChallengeFromContenu(action.payload)).pipe(
        map(loadChallengeFromContenuAsync.success),
        catchError(message => of(loadChallengeFromContenuAsync.failure(message)))
    ))
);

// loadContenuFromChallengeAsync
const getContenuFromChallengeEpics: RootEpic = (action$, state$, { contenusService }) => action$.pipe(
    filter(isActionOf(loadContenuFromChallengeAsync.request)),
    switchMap((action) => from(contenusService.loadContenuFromChallenge(action.payload)).pipe(
        map(loadContenuFromChallengeAsync.success),
        catchError(message => of(loadContenuFromChallengeAsync.failure(message)))
    ))
);

// loadContenuFromPageAsync
const getContenuFromPageEpics: RootEpic = (action$, state$, { contenusService }) => action$.pipe(
    filter(isActionOf(loadContenuFromPageAsync.request)),
    switchMap((action) => from(contenusService.loadContenuFromPage(action.payload)).pipe(
        map(loadContenuFromPageAsync.success),
        catchError(message => of(loadContenuFromPageAsync.failure(message)))
    ))
);

// listPageContenuAsync
const listPageContenuEpics: RootEpic = (action$, state$, { contenusService }) => action$.pipe(
    filter(isActionOf(listPageContenuAsync.request)),
    switchMap((action) => from(contenusService.listPage()).pipe(
        map(listPageContenuAsync.success),
        catchError(message => of(listPageContenuAsync.failure(message)))
    ))
);

// getPageContenuAsync
const getPageContenuEpics: RootEpic = (action$, state$, { contenusService }) => action$.pipe(
    filter(isActionOf(getPageContenuAsync.request)),
    switchMap((action) => from(contenusService.getPage(action.payload)).pipe(
        map(getPageContenuAsync.success),
        catchError(message => of(getPageContenuAsync.failure(message)))
    ))
);

const ContenusEpics = combineEpics(
    loadDocumentsEpics,
    addFileDocumentsEpics,
    removeFileDocumentsEpics,
    updateFileDocumentIndexEpics,
    getPresentationEpics,
    getChallengeFromContenuEpics,
    getContenuFromChallengeEpics,
    getContenuFromPageEpics,
    listPageContenuEpics,
    getPageContenuEpics
);

export default ContenusEpics;
