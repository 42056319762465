import { ChallengeService } from "services";
import RubriquePresentationSVG from 'features/admin/challenges/assets/rubrique_presentation.svg'
import RubriqueReglementSVG from 'features/admin/challenges/assets/rubrique_reglement.svg'
import RubriqueTelechargementsSVG from 'features/admin/challenges/assets/rubrique_telechargements.svg'
import RubriqueDestinationSVG from 'features/admin/challenges/assets/rubrique_destination.svg'
import RubriqueEditoSVG from 'features/admin/challenges/assets/rubrique_edito.svg'
import RubriqueActualitesSVG from 'features/admin/challenges/assets/rubrique_actualites.svg'
import RubriqueVideosSVG from 'features/admin/challenges/assets/rubrique_videos.svg'
import RubriqueClassementsSVG from 'features/admin/challenges/assets/rubrique_classements.svg'
import RubriqueAnimationsSVG from 'features/admin/challenges/assets/rubrique_animations.svg'
import { DeepRawify } from "types";
import { DeepPartial } from "redux";

const idToSvg: { [key: number]: string } =
{
    1: RubriquePresentationSVG,
    2: RubriqueReglementSVG,
    3: RubriqueTelechargementsSVG,
    4: RubriqueDestinationSVG,
    5: RubriqueEditoSVG,
    6: RubriqueActualitesSVG,
    7: RubriqueVideosSVG,
    8: RubriqueClassementsSVG,
    9: RubriqueAnimationsSVG,
}
export class ChallengeRubriqueInfos {

    public idRubriqueInfos: number = null;

    public titre: string = null;

    public description: string = null;

    public image: string = null;

    private ChallengeService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<ChallengeRubriqueInfos>>) {

        if (row) {

            const {
                idRubriqueInfos,
                titre,
                description,
                image,
            } = row;

            this.idRubriqueInfos = idRubriqueInfos;

            this.titre = titre;

            this.description = description;

            this.image = image;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.ChallengeService.saveChallengeRubriqueInfos(this);
    // }

    public getImage() : string {
        return idToSvg[this.idRubriqueInfos]
    }

    public toDatabaseObject(): object {
        return {
            idRubriqueInfos: this.idRubriqueInfos,
            titre: this.titre,
            description: this.description,
            image: this.image,
        }
    }
}