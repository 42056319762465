import Box from '@material-ui/core/Box';
import { withStyles, WithStyles } from '@material-ui/styles'
import CreatePost from 'features/murs/src/posts/CreatePost.hook.component';
import ListPosts from 'features/murs/src/posts/ListPosts.component';
import ModifyPost from 'features/murs/src/posts/ModifyPost.component';
import ViewPost from 'features/murs/src/posts/ViewPost.component';
import { loadMurInfos, setTypeMur } from 'features/murs/src/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RootState } from 'store/types';
import style from './SouvenirsCarnet.style';


const stateToProps = ({ murs: { isLoading, typeMur } }: RootState) => ({
    typeMur,
    isLoading,
})

const dispatchToProps = {

    loadMurInfos: loadMurInfos.request,
    setTypeMur: setTypeMur,
}

interface SouvenirsCarnetLocalProps {
}

interface SouvenirsCarnetState {
}

type SouvenirsCarnetProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & SouvenirsCarnetLocalProps;

class SouvenirsCarnet extends Component<SouvenirsCarnetProps, SouvenirsCarnetState> {
    componentDidMount() {
        const { typeMur,
            setTypeMur,
            loadMurInfos } = this.props;
        if (typeMur != 3) {
            setTypeMur(3);
        }
        loadMurInfos();
    }

    render() {
        return (
            <Box className='container'>
                <Switch>
                    <Route path="/item/:idItem/" exact component={ListPosts} />
                    <Route path="/item/:idItem/view-post/:id" component={ViewPost} />
                    <Route path="/item/:idItem/create-post/:id" component={CreatePost} />
                    <Route path="/item/:idItem/modify-post/:id" component={ModifyPost} />
                </Switch>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(SouvenirsCarnet));