import { FicheQuestion } from 'classes/level_up/quiz/FicheQuestion.class';
import { FicheQuestionGroupe } from 'classes/level_up/quiz/FicheQuestionGroupe.class';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { QuizInfos, ClassementAnswer, ClassementRequest } from './types';

// +---------------------+
// |       GROUPES       |
// +---------------------+

// Charge la liste des groupes
export const loadGroupesAsync = createAsyncAction(
    'LOAD_GROUPES_REQUEST',
    'LOAD_GROUPES_SUCCESS',
    'LOAD_GROUPES_FAILURE',
)<void, FicheQuestionGroupe[], string>();

// Charge un groupe
export const getGroupeAsync = createAsyncAction(
    'GET_GROUPE_REQUEST',
    'GET_GROUPE_SUCCESS',
    'GET_GROUPE_FAILURE',
)<number, FicheQuestionGroupe, string>();

// Groupe en cours de modification
export const setSelectedGroupe = createAction('SET_SELECTED_GROUPE')<FicheQuestionGroupe>();

// +---------------------+
// |      QUESTIONS      |
// +---------------------+

// Charge la liste des questions
export const loadQuestionsAsync = createAsyncAction(
    'LOAD_QUESTIONS_REQUEST',
    'LOAD_QUESTIONS_SUCCESS',
    'LOAD_QUESTIONS_FAILURE',
)<number, FicheQuestion[], string>();

// Charge une question
export const getQuestionAsync = createAsyncAction(
    'GET_QUESTION_REQUEST',
    'GET_QUESTION_SUCCESS',
    'GET_QUESTION_FAILURE',
)<number, FicheQuestion, string>();

// Question en cours de modification
export const setSelectedQuestion = createAction('SET_SELECTED_QUESTION')<FicheQuestion>();

// +---------------------+
// |        FRONT        |
// +---------------------+

// Charge les infos du quiz d'une fiche
export const loadFicheQuizAsync = createAsyncAction(
    'LOAD_FICHE_QUIZ_REQUEST',
    'LOAD_FICHE_QUIZ_SUCCESS',
    'LOAD_FICHE_QUIZ_FAILURE',
)<number, QuizInfos, string>();

// Sauvegarde la réponse suivante
export const saveNextAnswerAsync = createAsyncAction(
    'SAVE_NEXT_ANSWER_REQUEST',
    'SAVE_NEXT_ANSWER_SUCCESS',
    'SAVE_NEXT_ANSWER_FAILURE',
)<number[], FicheQuestion, string>();

// Charge les infos du quiz d'une fiche
export const loadLastQuizStatsAsync = createAsyncAction(
    'LOAD_FICHE_RECAP_REQUEST',
    'LOAD_FICHE_RECAP_SUCCESS',
    'LOAD_FICHE_RECAP_FAILURE',
)<void, QuizInfos, string>();

// Charge le classement
export const loadClassementAsync = createAsyncAction(
    'LOAD_CLASSEMENT_REQUEST',
    'LOAD_CLASSEMENT_SUCCESS',
    'LOAD_CLASSEMENT_FAILURE',
)<ClassementRequest, ClassementAnswer[], string>();

