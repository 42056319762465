import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadAdminLogsAsync } from "./actions";
import { Log } from "classes/log/Log.class";

// Reducer pour les logs
const logs = createReducer<Log[], RootAction>([])
    .handleAction(loadAdminLogsAsync.success, (_state, action) => action.payload)

const AdminParametresLogsReducer = combineReducers({
    logs
});

export default AdminParametresLogsReducer;


