import { TypePost } from "classes/murs/TypePost.class";
import { combineReducers } from "redux";
import { RootAction } from "store/types";
import { createReducer } from "typesafe-actions";
import { loadAdminParametresTypesPostInfosAsync, loadAdminTypePostAsync } from "./actions";

// Reducer pour les types de murs ayant des types de posts
const typesMurs = createReducer<{[key : number] : string}, RootAction>([])
    .handleAction(loadAdminParametresTypesPostInfosAsync.success, (_state, action) => action.payload.typesMurs)

const typePost = createReducer<TypePost, RootAction>(null)
    .handleAction(loadAdminTypePostAsync.success, (_state, action) => action.payload)

const AdminParametresTypesPostReducer = combineReducers({
    typesMurs,
    typePost
});

export default AdminParametresTypesPostReducer;


