import { DeepPartial } from "redux";
import { CanauxService } from "services";
import { DeepRawify } from "types";

export class NiveauCaracteristiqueChoix {

    public idNiveauCaracteristiqueChoix : number = null;

    public idNiveauCaracteristique : number = null;

    public libelle : string = null;


    private canauxService = CanauxService.getInstance();

    constructor(row? : DeepPartial<DeepRawify<NiveauCaracteristiqueChoix>>) {

        if (row) {

            const {
                idNiveauCaracteristiqueChoix,
                idNiveauCaracteristique,
                libelle,
            } = row;

            this.idNiveauCaracteristiqueChoix = idNiveauCaracteristiqueChoix;

            this.idNiveauCaracteristique = idNiveauCaracteristique;

            this.libelle = libelle;

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.canauxService.saveNiveauCaracteristiqueChoix(this);
    // }

    public toDatabaseObject(): object {
        return {
            idNiveauCaracteristiqueChoix: this.idNiveauCaracteristiqueChoix,
            idNiveauCaracteristique: this.idNiveauCaracteristique,
            libelle: this.libelle,

        }
    }
}