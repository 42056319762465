import { Animation } from '../Animation.class';
import { AnimationTypes } from '../AnimationTypes.enum';
import { StatisticsDisplayerBaseProps } from 'components/statisticsDisplayer/StatisticsDisplayer.component';
import { ConcoursPhotoService } from 'services';
import { Moment } from 'moment';
import moment from 'moment';
import { DeepRawify } from 'types';
import { DeepPartial } from 'redux';

/**
 * Cette classe stocke l'instance d'un concours photo en front
 */
export class ConcoursPhoto extends Animation {
    
    public notifType = 5;

    /**
     * Le service du concours photo
     */
    public serviceConcours = ConcoursPhotoService.getInstance();

    /**
     * Les différents types de likes appliquables sur les publications
     */
    public likeTypes = 0;

    /**
     * Le nombre maximum de likes qu'un utilisateur peut attribuer
     */
    public maxVotes = 1;

    /**
     * La date de début des votes du concours
     */
    public voteStart: Moment;

    /**
     * La date de fin des votes du concours
     */
    public voteEnd: Moment;

    /**
     * Le service du concours photo
     */
    public maxPhotosPerPerson: boolean = false;

    /**
     * Vrai si les validations de post sont activées, faux sinon
     */
    public validationEnabled = false;

    /**
     * La photo de profil de l'auteur
     */
    public imgAuthorDisplayed = false;

    /**
     * Vrai si les descriptions sont activées, faux sinon
     */
    public descriptionsEnabled = false;

    public maxPhotosPerPersonCount: number;

    /**
     * Vrai si les participants peuvent liker leurs propres publications
     */
    public ownVote = false;

    /**
     * Vrai si l'ordre d'affichage des posts est aléatoire, faux sinon
     */
    public displayRandom = false;

    /**
     * Vrai si la pagination est activée, faux si l'utilisateur doit 'scroll' vers le bas pour charger de nouveaux posts
     */
    public paging = false;

    /**
     * L'ordre d'affichage des posts
     */
    public postsOrder: number[];

    /**
     * L'indice de chargement des posts
     */
    public randomOffset: number;

    constructor(concours?: DeepPartial<DeepRawify<ConcoursPhoto>>) {
        super(concours);

        this.type = AnimationTypes.PhotoContest;

        if (concours) {
            this.likeTypes = concours.likeTypes;
            this.maxVotes = concours.maxVotes;
            this.voteStart = moment.unix(concours.voteStart);
            this.voteEnd = moment.unix(concours.voteEnd);
            this.maxPhotosPerPersonCount = Number(concours.maxPhotosPerPerson);
            this.maxPhotosPerPerson = this.maxPhotosPerPersonCount > 0;
            this.validationEnabled = concours.validationEnabled;
            this.imgAuthorDisplayed = concours.imgAuthorDisplayed;
            this.descriptionsEnabled = concours.descriptionsEnabled;
            this.ownVote = concours.ownVote;
            this.displayRandom = concours.displayRandom;
            this.paging = concours.paging;
            this.postsOrder = concours.postsOrder;
            this.randomOffset = concours.randomOffset;
        }
    }

    /**
     * Convertit l'objet ConcoursPhoto en tableau pour le transmettre au back
     * @returns Le tableau représentant l'instance
     */
    public toObject(): object {
        return {
            ...super.toObject(),
            likeTypes: this.likeTypes,
            maxVotes: this.maxVotes,
            voteStart: this.voteStart.unix(),
            voteEnd: this.voteEnd.unix(),
            maxPhotosPerPerson: this.maxPhotosPerPerson ? this.maxPhotosPerPersonCount : -1,
            validationEnabled: this.validationEnabled,
            imgAuthorDisplayed: this.imgAuthorDisplayed,
            descriptionsEnabled: this.descriptionsEnabled,
            ownVote: this.ownVote,
            displayRandom: this.displayRandom,
            paging: this.paging,
            postsOrder: this.postsOrder,
            randomOffset: this.randomOffset
        };
    }

    /**
     * Retourne les statistiques du concours
     * @returns les statistiques du concours
     */
    public async getStatistics(): Promise<StatisticsDisplayerBaseProps> {
        //const stats = await this.animationService.getStatistics(this.idAnimation, AnimationTypes.PhotoContest);
        return {
            statistics: [
                //TODO
            ]
        }
    }
}

ConcoursPhoto.TYPES_ANIMATION[AnimationTypes.PhotoContest] = ConcoursPhoto;