import { B64File } from "classes/B64File.class";
import { DeepPartial } from "redux";
import { DeepRawify } from "types";
import { LevelUpService } from 'services';
import { Complement } from "./Complement.class";
import { AnimationStatus } from "classes/animations/AnimationStatus.enum";
import moment from 'moment';
import { Notifiable } from "classes/notifications/Notifiable.class";

/**
 * Animation Level Up
 */
export class Animation extends Notifiable
{
    public getUrl(): string {
        return "/level-up/animations/" + this.idAnimation + "/";
    }
    public getID(): number {
        return this.idAnimation;
    }
    public notifType = 11;

    /**
     * L'identifiant de l'animation
     */
    public idAnimation: number;

    /**
     * Titre de l'animation
     */
    public titre: string;

    /**
     * Date de début de l'animation
     */
    public dateDebut: number;

    /**
     * Date de fin de l'animation
     */
    public dateFin: number;

    /**
     * Statut d'activation de l'animation
     */
    public enLigne: boolean;

    /**
     * Lien de l'image de présentation
     */
    public image: B64File;

    /**
     * Canal dans lequel l'animation est affichée
     */
    public idReferentielMetier: number;

    /**
     * Règlement de l'animation (html)
     */
    public reglement: string;

    /**
     * Date de mise à jour de l'animation
     */
    public dateMiseAJour: number;

    /**
     * Date de création de l'animation
     */
    public dateCreation: number;

    /**
     * Champ spécial : liste des compléments
     */
    public complements: Complement[];

    // Image par défaut affichée si l'animation n'a pas d'image
	static imageParDefaut = '/dist/assets/challenges/actualites/image_par_defaut.png';

    
    private levelUpService = LevelUpService.getInstance();


    /**
     * Constructeur de l'animation
     */
    constructor(row?: DeepPartial<DeepRawify<Animation>>) {
        super();
        if (row) {
            const { 
                idAnimation,
                titre,
                dateDebut,
                dateFin,
                enLigne,
                image,
                idReferentielMetier,
                reglement,
                dateMiseAJour,
                dateCreation,
                complements
            } = row;

            this.idAnimation = idAnimation;
            this.titre = titre;
            this.dateDebut = dateDebut;
            this.dateFin = dateFin;
            this.enLigne = enLigne;
            this.image = B64File.fromDb(image);
            this.idReferentielMetier = idReferentielMetier;
            this.reglement = reglement;
            this.dateMiseAJour = dateMiseAJour;
            this.dateCreation = dateCreation;
            this.complements = complements ? complements.map((i) => new Complement(i)) : []
        } else {
            this.complements = [];
        }
    }

    public getStatus(): AnimationStatus {
        const now = moment();

        if (moment(this.dateDebut*1000).diff(now) > 0) {
            return AnimationStatus.INCOMING;
        } if (moment(this.dateFin*1000).diff(now) > 0) {
            return AnimationStatus.ONGOING;
        }
        return AnimationStatus.FINISHED;
    }

    public getStatusLabel(): string {
        const status = this.getStatus();

        switch (status) {
            case AnimationStatus.INCOMING:
                return 'À venir';

            case AnimationStatus.ONGOING:
                return 'En cours';

            case AnimationStatus.FINISHED:
                return 'Terminé';
        }
    }

    public toRaw() {
        return {
            idAnimation: this.idAnimation,
            titre: this.titre,
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            enLigne: this.enLigne ? 1 : 0,
            image: this.image,
            idReferentielMetier: this.idReferentielMetier,
            reglement: this.reglement,
            dateMiseAJour: this.dateMiseAJour,
            dateCreation: this.dateCreation,
            complements: this.complements.map((i) => i.toRaw())
        }
    }

    public delete() {
        return this.levelUpService.deleteAnimation(this.idAnimation);
    }

    public save() {
        return this.levelUpService.saveAnimation(this);
    }

}