import { Notification } from 'classes/notifications/Notification.class';
import { Entite } from 'classes/referentiel/Entite.class';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const loadNotificationsAsync = createAsyncAction(
    'LOAD_NOTIFICATIONS_REQUEST',
    'LOAD_NOTIFICATIONS_SUCCESS',
    'LOAD_NOTIFICATIONS_FAILURE',
)<void, Notification[], string>();

export const vueAllNotificationsAsync = createAsyncAction(
    'VUE_ALL_NOTIFICATIONS_REQUEST',
    'VUE_ALL_NOTIFICATIONS_SUCCESS',
    'VUE_ALL_NOTIFICATIONS_FAILURE',
)<void, void, string>();

export const listADAsync = createAsyncAction(
    'LIST_AD_REQUEST',
    'LIST_AD_SUCCESS',
    'LIST_AD_FAILURE',
)<void, Entite[], string>();


export const setIdActeurEnvoyeurNotificationFilter = createAction('SET_IDACTEURENVOYEUR_FILTER')<string>();

export const setDateNotificationNotificationFilter = createAction('SET_DATENOTIFICATION_FILTER')<string>();

export const setIdTypeNotificationFilter = createAction('SET_IDTYPE_FILTER')<number>();

export const setIdTypeMurNotificationFilter = createAction('SET_IDTYPEMUR_FILTER')<number>();

export const setUnreadNotifNb = createAction('SET_UNREAD_NOTIF_NB')<number>();

export const setLimitNotificationFilter = createAction('SET_LIMIT_FILTER')<number>();