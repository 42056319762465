import { createStyles, makeStyles } from '@material-ui/styles';
import { colors, Theme } from 'Theme';

export default makeStyles(({ spacing, palette }: typeof Theme) => createStyles({
    sortableList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        userSelect: 'none'
    },
    sortableItem: {
        height: IS_ADMIN ? 'auto' : 60,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        borderRadius: 5,
        margin: IS_ADMIN ? 0 : spacing(1),
        borderBottomColor: colors.lighterGrey.light,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        justifyContent: "space-between",

        '&:last-child': {
            borderBottomWidth: 0
        }
    },
    dragHandle: {
        cursor: 'row-resize',
        width: '100%'
    }
}));