import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid'
import moment, { Moment } from 'moment';
import { FicheLibreCaracteristique } from './FicheLibreCara.class';

/**
 * Sauvearde les groupes caractéristiques d'une fiche libre
 */
export class FicheLibreGroupeCara
{
    public uid: string = v4();

    /**
     * L'identifiant de l'association
     * @var int
     */
    public idFicheLibreGroupeCaracteristique: number;

    /**
     * L'identifiant de la fiche
     * @var int
     */
    public idFiche: number;

    /**
     * libellé
     * @var string
     */
    public libelle: string;

    /**
     * date de mise à jour
     * @var int
     */
    public dateMiseAJour: Moment;

    /**
     * Ordre d'affichage
     * @var int
     */
    public ordre: number;

    /**
     * Liste des caractéristiques libres
     * @var array
     */
    public listeCara: FicheLibreCaracteristique[];

    private levelUpService = LevelUpService.getInstance();


    /**
     * 
     *  Constructeur de la fiche
     * @param  mixed row
     * @return void
     */
    constructor(row?: DeepPartial<DeepRawify<FicheLibreGroupeCara>>) {
        if (row) {
            this.idFicheLibreGroupeCaracteristique = row['idFicheLibreGroupeCaracteristique'] ?? null;
            this.idFiche = row['idFiche'] ?? null;
            this.libelle = row['libelle'] ?? null;
            this.dateMiseAJour = row['dateMiseAJour'] ? moment(row['dateMiseAJour'], 'X') : moment();
            this.ordre = row['ordre'] ?? null;
            this.listeCara = row['listeCara'] ? row['listeCara'].map((i) => new FicheLibreCaracteristique(i)) : [];
        } else {
            this.listeCara = [];
        }
    }

    public toRaw()
    {
        return {
            idFicheLibreGroupeCaracteristique: this.idFicheLibreGroupeCaracteristique,
            idFiche: this.idFiche,
            libelle: this.libelle,
            dateMiseAJour: this.dateMiseAJour && this.dateMiseAJour.unix(),
            ordre: this.ordre,
            listeCara: this.listeCara.map((i) => i.toRaw())
        };
    }

}
