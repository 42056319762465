import { from, of } from 'rxjs';
import {
    filter, switchMap, catchError, map,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { combineEpics } from 'redux-observable';
import { RootEpic } from 'store/types';
import { loadNotificationsAsync, vueAllNotificationsAsync, listADAsync } from './notificationsActions';

const loadNotificationsEpic: RootEpic = (action$, state$, { notificationsService }) => action$.pipe(
    filter(isActionOf(loadNotificationsAsync.request)),
    switchMap(() => from(notificationsService.loadNotifications(state$.value.notifications.notificationsFilters)).pipe(
        map(loadNotificationsAsync.success),
        catchError(message => of(loadNotificationsAsync.failure(message))),
    )),
);

const vueAllNotificationsEpic: RootEpic = (action$, state$, { notificationsService }) => action$.pipe(
    filter(isActionOf(vueAllNotificationsAsync.request)),
    switchMap(() => from(notificationsService.vueAllNotifications()).pipe(
        map(vueAllNotificationsAsync.success),
        catchError(message => of(vueAllNotificationsAsync.failure(message))),
    )),
);

const listADEpic: RootEpic = (action$, state$, { notificationsService }) => action$.pipe(
    filter(isActionOf(listADAsync.request)),
    switchMap(() => from(notificationsService.listAD()).pipe(
        map(listADAsync.success),
        catchError(message => of(listADAsync.failure(message))),
    )),
);

const NotificationsEpics = combineEpics(loadNotificationsEpic, listADEpic, vueAllNotificationsEpic);

export default NotificationsEpics;
