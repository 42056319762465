import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import type { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    loadStatistiquesAsync,
} from './actions';

const loadStatistiques: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadStatistiquesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadStatistiques(action.payload)).pipe(
                map(loadStatistiquesAsync.success),
                catchError(message => of(loadStatistiquesAsync.failure(message)))
            )
        ),
    );

const LevelUpStatistiquesEpics = combineEpics(
    loadStatistiques
);

export default LevelUpStatistiquesEpics;
