import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/styles'
import { Calendar } from '@material-ui/pickers';
import Avatar from 'components/avatar/Avatar.component';
import StatisticsDisplayerComponent from 'components/statisticsDisplayer/StatisticsDisplayer.component';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadCurrentActeurAsync } from 'store/acteur/acteurActions';
import { RootState } from 'store/types';
import ChallengeSVG from '../../../../assets/visuels/challenge.svg';
import CommentaireSVG from '../../../../assets/visuels/commentaire_new.svg';
import LikeSVG from '../../parametres/assets/monProfil/like.svg';
import PostSVG from '../../parametres/assets/monProfil/post.svg';
import style from './LeftPanelMur.style';

const stateToProps = ({ murs: { statistics, nbChallenges }, acteur }: RootState) => ({
    moi: acteur.current,
    isLoadingActeur: acteur.isLoading,
    statistics,
    nbChallenges
    // isLoadingMur: isLoading
})

const dispatchToProps = {
    loadActeur: loadCurrentActeurAsync.request
}

interface LeftPanelMurLocalProps {

}

interface LeftPanelMurState {
    stateStatistics: Array<{
        icon: string,
        value: string,
        text: string,
        className?: string,
        iconClassName?: string
    }>
}

type LeftPanelMurProps =
    WithStyles<typeof style>
    & ReturnType<typeof stateToProps>
    & typeof dispatchToProps
    & LeftPanelMurLocalProps;

class LeftPanelMur extends Component<LeftPanelMurProps, LeftPanelMurState> {

    readonly state: LeftPanelMurState = {
        stateStatistics: []
    }

    private labelToIcon: { [key: string]: string } =
        {
            'Likes': LikeSVG,
            'Commentaires': CommentaireSVG,
            'Posts': PostSVG
        }

    componentDidMount() {
        const { moi, isLoadingActeur, loadActeur, statistics } = this.props;
        const { stateStatistics } = this.state;
        if (!isLoadingActeur && !moi) {
            loadActeur();
        }
        if (Object.entries(statistics).length != Object.entries(stateStatistics).length) {
            this.populateStatistics();
        }

    }

    componentDidUpdate() {
        const { moi, isLoadingActeur, loadActeur, statistics } = this.props;
        const { stateStatistics } = this.state;
        if (!isLoadingActeur && !moi) {
            loadActeur();
        }
        if (Object.entries(statistics).length != Object.entries(stateStatistics).length) {
            this.populateStatistics();
        }
    }

    private populateStatistics() {
        const { statistics, classes } = this.props;
        const { stateStatistics } = this.state;
        let newStateStatistics = [];
        for (let label in statistics) {
            newStateStatistics.push({ icon: this.labelToIcon[label], text: label, value: statistics[label], iconClassName: classes.iconStatistics });
        }
        this.setState({ stateStatistics: newStateStatistics });
    }


    render() {
        const { classes, moi, nbChallenges } = this.props;
        const { stateStatistics } = this.state;
        if (!moi) {
            return <div />;
        }
        return (
            <Box display='flex' flexDirection='column' width='100%'>
                <Paper className={classes.paperAvatar} elevation={0}>
                    <Box padding={1} style={{ cursor: 'pointer' }} onClick={() => window.location.href = IS_ADMIN ? "#/parametres/mon-profil/" : "#/parametre/mon-profil/A-propos"}>
                        <Avatar acteur={moi} variant='large' border='thick' />
                    </Box>
                    <Typography>
                        <b>{`${moi.prenom} ${moi.nom}`}</b>
                    </Typography>
                    <Typography className={classes.textRole}>
                        {moi.role}
                    </Typography>
                    {
                        Object.entries(stateStatistics).length > 0 &&
                        <StatisticsDisplayerComponent statistics={stateStatistics} className={classes.statistics} />
                    }
                </Paper>

                {
                    nbChallenges !== null &&
                    <Paper className={classes.paperNbChallenges} elevation={0}>
                        <img className={classes.imgChallenge } src={ChallengeSVG} />
                        <Typography> <b>{nbChallenges} challenges</b> en cours</Typography>
                    </Paper>
                }
                <Paper className={classes.calendar} elevation={0}>
                    <Calendar date={moment()} onChange={() => { }} allowKeyboardControl={false}  />
                </Paper>
            </Box>
        );
    }
}

export default withStyles(style)(connect(stateToProps, dispatchToProps)(LeftPanelMur));